import * as FeedbackActions from '../../../actions/feedback';
import * as SnackbarEventAction from '../../../actions/snackbarEvent';
import { Feature, Feedback } from '../../../model';
import { FeedbackActions as FeedbackActionsModel, FeedbackFilter } from '../../../model/feedback';
import { FormattedMessage, useIntl } from 'react-intl';
import { OnSubmitHandler, useInputGroup } from '../../../hooks/useInputGroup';
import { RootState } from '../../../reducers';
import { WikusButton, WikusButtonRow } from '../../../components/core/button';
import { WikusHr } from '../../../components/core/hr';
import { WikusInputRow } from '../../../components/core/input';
import { WikusSection } from '../../../components/core/spacing';
import { WikusSelect } from '../../../components/core/select';
import { includes } from 'lodash';
import { useActions } from '../../../actions';
import { useAutocomplete } from '../../../hooks/useAutocomplete';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export interface FeedbackAccordionContentProps {
  item: Feedback;
}

export const FeedbackAccordionContent = ({ item }: FeedbackAccordionContentProps) => {
  const intl = useIntl();
  const user = useSelector((rootState: RootState) => rootState.user);
  const isAdmin = includes(user.features, Feature.UserAdmin);
  const [isDeleting, setIsDeleting] = useState(false);
  const feedbackActions: typeof FeedbackActions = useActions(
    FeedbackActions
  );
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );

  const { isLoading, createAutocompleteProps } = useAutocomplete<typeof FeedbackActions, FeedbackFilter>(FeedbackActions, 'feedback');
  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const { type } = await feedbackActions.patchFeedback(item.id, value);
    return type === FeedbackActionsModel.PATCH_FEEDBACK_SUCCESS;
  }
  const {
    value,
    setGroup,
    isValid,
    onSubmit,
    createInputProps,
    isSubmitting,
  } = useInputGroup(`feedback-${item.id}`, _onSubmit, 'feedback');

  const onDelete = async () => {
    setIsDeleting(true);
    const { type } = await feedbackActions.deleteFeedback(item.id);
    const success = type === FeedbackActionsModel.DELETE_FEEDBACK_SUCCESS;

    if (success) {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'success.delete-feedback',
        }),
        severity: 'success',
      });
    } else {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'errors.delete-feedback',
        }),
        severity: 'error',
      });
    }
    setIsDeleting(false);
  }

  useEffect(() => {
    if (isAdmin) {
      setGroup({
        value: {
          status: item.status,
        },
      });
    }
  }, []);

  return (
    <>
      {item.description}

      {isAdmin
        ? (
          <>
            <WikusSection spacingDirection="vertical">
              <WikusHr color="grey" />
            </WikusSection>
            <WikusInputRow>
              <WikusSelect
                {...createInputProps('status')}
                {...createAutocompleteProps('status')} />
            </WikusInputRow>
            <WikusButtonRow>
              <WikusButton
                color="primary"
                variant="outlined"
                loading={isDeleting}
                disabled={isSubmitting}
                onClick={onDelete}>
                <FormattedMessage id="delete" />
              </WikusButton>
              <WikusButton
                color="primary"
                loading={isSubmitting}
                disabled={isDeleting}
                onClick={onSubmit}>
                <FormattedMessage id="save" />
              </WikusButton>
            </WikusButtonRow>
          </>
        )
        : undefined
      }
    </>
  )
}