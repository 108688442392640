// prettier-ignore
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { withRoot } from './withRoot';

import { Completed } from './pages/completed';
import { DashboardPage, LoginPage, NewProcessPage } from './pages';
import { EscalationsPage } from './pages/escalations';
import { FeedbackPage } from './pages/feedback';
import { MyDraftsPage, MyProcessesPage } from './pages/my-processes';
import { PrivateRoute } from './routes/private';
import { Routes } from './routes';
import { Snackbar } from './components/Snackbar';
import { UserAdminPage } from './pages/user-admin';
import RollbarProvider from './Rollbar.provider';

function App() {
  return (
    <RollbarProvider>
      <Router>
        <div>
          <Snackbar />
          <div>
            <Switch>
              <Route component={LoginPage} path={Routes.login} />
              <PrivateRoute component={() => <NewProcessPage type="new" />} path={Routes.newProcess} />
              <PrivateRoute component={() => <NewProcessPage type="edit" />} path={Routes.editProcess} />
              <PrivateRoute component={() => <NewProcessPage type="simulation" />} path={Routes.simulation} />
              <PrivateRoute component={() => <Completed type="new" />} path={Routes.newCompleted} />
              <PrivateRoute component={() => <Completed type="edit" />} path={Routes.editCompleted} />
              <PrivateRoute component={FeedbackPage} path={Routes.feedback} />
              <PrivateRoute component={MyProcessesPage} path={Routes.myProcesses} />
              <PrivateRoute component={MyDraftsPage} path={Routes.myDrafts} />
              <PrivateRoute component={EscalationsPage} path={Routes.escalations} />
              <PrivateRoute component={UserAdminPage} path={Routes.userAdmin} />
              <PrivateRoute component={DashboardPage} path={Routes.dashboard} />
            </Switch>
          </div>
        </div>
      </Router>
    </RollbarProvider>
  );
}

export default withRoot(App);
