import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

export type WikusConfirmationProps = Omit<DialogProps, 'buttons' | 'onAbort' | 'classes'> & {
  title: ReactNode;
  children: ReactNode;
  onConfirm?: () => void;
  onAbort?: () => void;
}

const WikusConfirmation = ({ children, title, onConfirm, onAbort, ...props }: WikusConfirmationProps) => {
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      {...props}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onAbort} color="primary">
          <FormattedMessage id="confirmation.abort" />
        </Button>
        <Button onClick={onConfirm} color="primary">
          <FormattedMessage id="confirmation.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
// set for storybook to render html preview correctly
WikusConfirmation.displayName = 'WikusConfirmation';

export {
  WikusConfirmation
};