import { ExistingCustomerSelection } from './existing-customer';
import { FormattedMessage, useIntl } from 'react-intl';
import { NewCustomerSelection } from './new-customer';
import { WikusButton } from '../../../components/core/button';
import { WikusModal, WikusModalProps } from '../../../components/core/modal';
import { useState } from 'react';
export interface CustomerSelectionProps
  extends Omit<WikusModalProps, 'title'> { }

type CustomerSelectionType = 'existing' | 'new';

export const CustomerSelection = ({
  classes,
  ...props
}: CustomerSelectionProps) => {
  const intl = useIntl();
  const [customerSelectionType, setCustomerSelectionType] = useState<CustomerSelectionType>('existing');
  const title = intl.formatMessage({
    id: `customer-selection.${customerSelectionType}.title`,
  });
  const switchButton = customerSelectionType === 'existing'
    ? <WikusButton variant="outlined" color="primary" onClick={() => setCustomerSelectionType('new')}>
      <FormattedMessage id="customer-selection.new.cta" />
    </WikusButton>
    : <WikusButton variant="outlined" color="primary" onClick={() => setCustomerSelectionType('existing')}>
      <FormattedMessage id="customer-selection.existing.cta" />
    </WikusButton>;

  return (
    <WikusModal {...props} title={title} heroChildren={switchButton}>
      {
        customerSelectionType === 'existing'
          ? <ExistingCustomerSelection onCustomerSelected={() => props.onClose?.({}, 'userSelection')} />
          : <NewCustomerSelection onCustomerCreated={() => props.onClose?.({}, 'userSelection')} />
      }
    </WikusModal>
  );
};
