import { Article, ProcessArticle } from '../../model';
import { IntlShape } from 'react-intl';
import { ReactNode } from 'react';
import { WikusArticleCardSize } from '../complex/article-card';
import { WikusCurrencyLabel, WikusPercentLabel } from '../core/formatted-label';
import {
  WikusDetailRowItem,
  WikusDetailRowItemVariant
} from '../complex/detail-block';
import { get, includes } from 'lodash';

export type TArticleDetailBlockItemsSize = WikusArticleCardSize | 'process';

export type ArticleDetailBlockItemsType = {
  [key in TArticleDetailBlockItemsSize]: {
    variant?: WikusDetailRowItemVariant;
    items:
    ((keyof Article | keyof ProcessArticle) | {
      field: keyof Article | keyof ProcessArticle;
      label: string;
      component: (value: any) => ReactNode;
    })[];
  };
};

const ArticleDetailBlockItems: ArticleDetailBlockItemsType = {
  small: {
    variant: undefined,
    items: ['itemGroupName', 'productGroupName', 'shortDescription'],
  },
  large: {
    variant: 'equal',
    items: [
      'name',
      'itemNumber',
      'status',
      'shortDescription',
      'productGroupName',
      'businessAreaName',
      {
        field: 'containerSize',
        label: 'containerSize',
        component: (containerSize: string) => <span>{parseInt(containerSize)}</span>,
      },
      'length',
      'toothPerInch',
      {
        field: 'description',
        label: 'description',
        component: (description: string) => <span style={{ wordBreak: 'break-all' }}>{description}</span>,
      }
    ],
  },
  process: {
    variant: 'equal',
    // oder bestehende labels nehmen? Ich habe sie mal getrennt, falls wir verschiedene Werte nutzen wollen
    items: [
      {
        field: 'unitCount',
        label: 'unitCount',
        component: (unitCount: number) => unitCount,
      },
      {
        field: 'amount',
        label: 'amount',
        component: (amount: number) => <WikusCurrencyLabel amount={amount} />,
      },
      {
        field: 'netPerUnit',
        label: 'netPerUnit',
        component: (amount: number) => <WikusCurrencyLabel amount={amount} />,
      },
      {
        field: 'discount',
        label: 'discount',
        component: (discount: number) => (
          <WikusPercentLabel percentInDecimals={discount} />
        ),
      },
      {
        field: 'deviation',
        label: 'deviation',
        component: (deviation: number) => (
          <WikusPercentLabel percentInDecimals={deviation} />
        ),
      }
    ],
  },
};

export type TGetArticleDetailRowItems = {
  items: WikusDetailRowItem[];
  variant: WikusDetailRowItemVariant;
};

export const getArticleDetailRowItems = (
  intl: IntlShape,
  article: Article | ProcessArticle,
  size: TArticleDetailBlockItemsSize
): TGetArticleDetailRowItems => {
  const { variant, items } = ArticleDetailBlockItems[size];

  const detailRowItems: WikusDetailRowItem[] = items.map((p) => {
    if (typeof p === 'string') {
      return {
        label: intl.formatMessage({
          id: `article.${p}.label`,
        }),
        // @ts-ignore
        value: article[p],
        isFullWidth: includes(['description'], p),
      };
    }

    return {
      label: intl.formatMessage({
        id: `article.${p.label}.label`,
      }),
      value: p.component(get(article, p.field)),
      isFullWidth: includes(['description'], p.field),
    };
  });

  return { variant, items: detailRowItems };
};
