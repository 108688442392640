import * as ProcessActions from '../../actions/process';
import { Process } from '../../model';
import { RootState } from '../../reducers';
import { Routes } from '../../routes';
import { UseFilterActions } from '../../hooks/useFilter';
import { UsePaginateActions } from '../../hooks/usePaginate';
import { createContext } from 'react';

export interface ProcessPageActions {
  patchProcess?: (id: number, value: any) => any;
  editProcess: (process: Process) => any;
}

export interface IProcessPageContext {
  actions: UseFilterActions & UsePaginateActions & ProcessPageActions;
  name: string;
  editRoute: string;
  proccessesSelector: (state: RootState) => Process[];
  countSelector: (state: RootState) => number | undefined;
}

export const ProcessPageContext = createContext<IProcessPageContext>({
  actions: ProcessActions,
  name: 'my-processes',
  editRoute: Routes.editProcess,
  proccessesSelector: (state: RootState) => state.processes,
  countSelector: (state: RootState) => state.processesCount,
});