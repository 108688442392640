import { Grid } from '@material-ui/core';
import { ReactNode, useEffect } from 'react';
import { WikusCard } from '../../components/core/card';
import { WikusUnstyledLink } from '../../components/core/link';
import { useIntl } from 'react-intl';
import React from 'react'
import WikusPageLayout from '../../components/PageLayout';

import * as EscalationActions from '../../actions/escalation';
import * as ProcessActions from '../../actions/process';
import { Feature } from '../../model';
import { RootState } from '../../reducers';
import { Routes } from '../../routes';
import { WikusItem } from '../../components/core/spacing';
import { includes } from 'lodash';
import { useActions } from '../../actions';
import { useSelector } from 'react-redux';

const DashboardRow = ({ children }: any) => {
  return (
    <WikusItem spacingDirection="down">
      <Grid container>
        <Grid container item spacing={2} xs>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            {children.length ? children[0] : children}
          </Grid>
          {children.length > 1 && <Grid item xs={5}>
            {children[1]}
          </Grid>}
        </Grid>
      </Grid>
    </WikusItem>
  )
}

interface DashboardTile {
  url: string;
  name: string;
  external?: boolean;
  notification?: ReactNode;
}

export const DashboardPage = () => {
  const intl = useIntl();
  const rows: DashboardTile[][] = [[{
    url: Routes.newProcess,
    name: 'new-process',
  }, {
    url: Routes.myProcesses,
    name: 'my-processes',
  }], [{
    url: Routes.myDrafts,
    name: 'my-drafts',
  }]];

  const addTile = (tile: DashboardTile) => {
    if (rows[rows.length - 1].length === 2) {
      rows.push([]);
    }

    rows[rows.length - 1]?.push(tile);
  }

  const processActions: typeof ProcessActions = useActions(ProcessActions);
  const escalationActions: typeof EscalationActions = useActions(EscalationActions);

  const user = useSelector((root: RootState) => root.user);
  const escalationsCount = useSelector((root: RootState) => root.escalationsCount);
  const canEscalate = includes(user.features, Feature.Escalation);
  if (canEscalate) {
    addTile({
      name: 'escalations',
      url: Routes.escalations,
      notification: escalationsCount,
    });
  }

  const canSimulate = includes(user.features, Feature.Simulation);
  if (canSimulate) {
    addTile({
      name: 'simulation',
      url: Routes.simulation,
    });
  }

  const userAdmin = includes(user.features, Feature.UserAdmin);
  if (userAdmin) {
    addTile({
      name: 'user-admin',
      url: Routes.userAdmin,
    });
  }

  addTile({
    url: 'https://docs.powerpricer.wikus.com/#layout',
    name: 'tutorial',
    external: true,
  });

  useEffect(() => {
    processActions.clearNewProcess();
    if (canEscalate) {
      escalationActions.fetchInitial({ take: 0, skip: 0, status: 'open' });
    }
  }, []);

  return (
    <WikusPageLayout heroWithImage isCentered>
      {
        rows.map((tiles, index) => (
          <DashboardRow key={index}>
            {
              tiles.map(({ url, name, notification, external }) => (
                <WikusUnstyledLink
                  key={name}
                  external={external}
                  href={external ? url : undefined}
                  to={url}>
                  <WikusCard
                    notification={notification}
                    title={intl.formatMessage({ id: `dashboard.${name}.title` })}>
                    {intl.formatMessage({ id: `dashboard.${name}.description` })}
                  </WikusCard>
                </WikusUnstyledLink>
              ))
            }
          </DashboardRow>
        ))
      }
    </WikusPageLayout>
  );
};
