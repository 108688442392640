import { FormattedMessage, useIntl } from 'react-intl';
import { RootState } from '../../../reducers';
import { WikusInfiniteScroll } from '../../../components/complex/infinite-scroll';
import { WikusItem, WikusSection } from '../../../components/core/spacing';
import {
  WikusModal,
  WikusModalProps,
  WikusModalScrollContainerId
} from '../../../components/core/modal';
import { useSelector } from 'react-redux';

import * as ArticleActions from '../../../actions/article';
import * as SnackbarEventAction from '../../../actions/snackbarEvent';
import { Article, ArticleSelectionType, BusinessArea, Customer } from '../../../model';
import { ArticleBasket } from '../components/article-basket';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { ItemNumber } from '../../../components/utils/validators';
import { WikusArticleCard } from '../../../components/complex/article-card';
import { WikusButton } from '../../../components/core/button';
import { WikusFilter, WikusFilterOption, WikusFilterOptionAutocompleteProps, WikusFilterOptionChangeHandler, WikusFilterOptionType } from '../../../components/complex/filter';
import { filter as _filter, find, startsWith } from 'lodash';
import { useActions } from '../../../actions';
import { useFilter } from '../../../hooks/useFilter';
import { usePaginate } from '../../../hooks/usePaginate';

export interface ArticleSelectionProps extends Omit<WikusModalProps, 'title'> { }

interface ArticleSelectionHeaderProps {
  onChange?: WikusFilterOptionChangeHandler;
  isFamily: boolean;
  businessArea?: BusinessArea;
  customer?: Customer;
}

const ArticleSelectionHeader = ({ onChange, isFamily, businessArea, customer }: ArticleSelectionHeaderProps) => {
  const intl = useIntl();
  const selectedArticles = useSelector((state: RootState) => state.selectedArticles);
  const user = useSelector((state: RootState) => state.user);
  const { isLoading, filter } = useFilter<typeof ArticleActions>(ArticleActions, [{
    type: WikusFilterOptionType.text,
    props: {
      name: 'itemNumber',
      validator: ItemNumber,
    },
  }, ...(user.paramasterUserIds?.length ? [{
    type: WikusFilterOptionType.bool,
    props: {
      name: 'isFavorite',
    },
  }] : []), {
    type: WikusFilterOptionType.text,
    props: {
      name: 'shortDescription',
    },
  }, ...(customer?.customerNumber ? [{
    type: WikusFilterOptionType.bool,
    props: {
      name: 'bought',
      value: customer.customerNumber,
    },
  }] : []), ...(isFamily ? [] : [{
    type: WikusFilterOptionType.text,
    props: {
      name: 'length',
    },
  }]), {
    type: WikusFilterOptionType.text,
    props: {
      name: 'containerSize',
    },
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'toothPerInch',
    },
  }], 'article');

  const filterOptions = useCallback((filterOptions: WikusFilterOption[]) => {
    if (!businessArea) {
      return filterOptions;
    }

    const itemGroupFilter = find(filterOptions, f => f.props.name === 'itemGroup');
    if (!itemGroupFilter) {
      return filterOptions;
    }

    return [
      ..._filter(filterOptions, f => f.props.name !== 'itemGroup'),
      {
        ...itemGroupFilter,
        props: {
          ...itemGroupFilter?.props,
          options: _filter((itemGroupFilter?.props as WikusFilterOptionAutocompleteProps).options, o => startsWith(o.group, businessArea)),
        },
      }
    ]
  }, [businessArea]);

  const filterTitle = intl.formatMessage({
    id: 'article-selection.filter.title',
  });
  const filterDescription = intl.formatMessage({
    id: 'article-selection.filter.description',
  });
  return (
    <Fragment>
      <WikusFilter
        title={filterTitle}
        description={filterDescription}
        isLoading={isLoading}
        options={filterOptions(filter)}
        order={['isFavorite', 'bought', 'productGroup', 'businessArea', 'itemGroup', 'length', 'size', 'toothPerInch', 'containerSize', 'itemNumber', 'shortDescription', 'status']}
        onChange={onChange} />

      {
        !!selectedArticles?.length && (
          <WikusSection spacingDirection="up">
            <ArticleBasket articles={selectedArticles} />
          </WikusSection>
        )
      }
    </Fragment>
  )
}

interface ArticleSelectionContentProps {
  isFamily: boolean;
}

const ArticleSelectionContent = ({ isFamily }: ArticleSelectionContentProps) => {
  const intl = useIntl();
  const selectedCustomer = useSelector((state: RootState) => state.selectedCustomer);
  const defaultFilter = {
    isFamily,
    customerNumber: selectedCustomer?.customerNumber,
  };
  const [filters, setFilters] = useState<any>(defaultFilter);
  const ArticlesPerPage = 10;

  const { fetchMore, isLoading } = usePaginate<typeof ArticleActions>({
    itemsPerPage: ArticlesPerPage,
    actions: ArticleActions,
    filters,
  });
  const articleActions: typeof ArticleActions = useActions(ArticleActions);
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );

  const setFiltersWithParams = (filters: any) => {
    setFilters({
      ...(filters || {}),
      ...defaultFilter,
    });
  }

  const selectedArticles = useSelector((state: RootState) => state.selectedArticles);
  const articles = _filter<Article>(useSelector((state: RootState) => state.articles),
    a => a.isFamily || !find(selectedArticles, sa => sa.id === a.id)
  );
  const articlesCount = useSelector((rootState: RootState) => rootState.articlesCount);

  const selectArticle = (article: Article) => {
    articleActions.selectArticles([
      ...(selectedArticles || []),
      article
    ]);
    snackbarActions.addSnackbarEvent({
      message: intl.formatMessage({
        id: 'success.select-article',
      }),
      severity: 'success',
    });
  };

  useEffect(() => {
    if (filters?.isFamily !== isFamily) {
      setFiltersWithParams(filters);
    }
  }, [isFamily]);

  return (
    <Fragment>
      <WikusSection spacingDirection="down">
        <ArticleSelectionHeader
          isFamily={isFamily}
          businessArea={filters?.businessArea}
          onChange={setFiltersWithParams}
          customer={selectedCustomer} />
      </WikusSection>
      <WikusInfiniteScroll
        scrollableTarget={WikusModalScrollContainerId}
        skeletonHeight={200}
        skeletonCount={ArticlesPerPage}
        dataLength={articles?.length}
        next={fetchMore}
        hasMore={articlesCount > articles?.length}
        isLoading={isLoading}
      >
        {articles?.map((article, index) => (
          <WikusItem spacingDirection="down" key={index}>
            <WikusArticleCard
              article={article}
              customer={selectedCustomer}
              buttons={[
                <WikusButton onClick={() => selectArticle(article)} color="primary" variant="outlined">
                  <FormattedMessage id="choose"></FormattedMessage>
                </WikusButton>
              ]}
              size="large" />
          </WikusItem>
        ))}
      </WikusInfiniteScroll>
    </Fragment>
  );
}

export const ArticleSelection = ({
  classes,
  ...props
}: ArticleSelectionProps) => {
  const intl = useIntl();
  const [type, setType] = useState<ArticleSelectionType>('variant');
  const title = intl.formatMessage({
    id: `article-selection.${type}.title`,
  });

  const heroChildren = type === 'variant'
    ? <WikusButton onClick={() => setType('family')} color="primary" variant="outlined">
      <FormattedMessage id="article-selection.family.cta" />
    </WikusButton>
    : <WikusButton onClick={() => setType('variant')} color="primary" variant="outlined">
      <FormattedMessage id="article-selection.variant.cta" />
    </WikusButton>;

  const selectedArticles = useSelector((state: RootState) => state.selectedArticles);

  return (
    <WikusModal
      {...props}
      title={title}
      heroChildren={heroChildren}
      buttons={[
        <WikusButton onClick={() => props.onClose?.({}, 'userSelection')} color="secondary" disabled={!selectedArticles?.length}>
          <FormattedMessage id="article-selection.finish"></FormattedMessage>
        </WikusButton>
      ]}>
      <ArticleSelectionContent isFamily={type === 'family'} />
    </WikusModal>
  );
};
