import * as CustomerActions from '../../actions/customer';
import { Collapse, Grid, List, ListItem, makeStyles } from '@material-ui/core';
import { Customer, Discount, DiscountGroup, DiscountItem, DiscountType } from '../../model';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootState } from '../../reducers';
import { Skeleton } from '@material-ui/lab';
import { WikusButton } from '../core/button';
import { WikusCustomerCard } from './customer-card';
import {
  WikusDetailBlock
} from './detail-block';
import { WikusModal, WikusModalProps } from '../core/modal';
import { WikusPercentLabel } from '../core/formatted-label';
import { WikusTypography } from '../core/typography';
import { map, some } from 'lodash';
import { useActions } from '../../actions';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';

type TCustomerDetailsDiscountItemProps = {
  item: DiscountItem;
  isSelected: boolean;
};

const CustomerDetailsDiscountItem = ({ item, isSelected }: TCustomerDetailsDiscountItemProps) => {
  const items = [
    item.item,
    ...item.additionals
  ]

  const typoProps: any = {};
  if (isSelected) {
    typoProps.color = 'inherit';
  }

  return (
    <Grid container>
      {
        map(items, i => (
          <>
            <Grid item sm={6}>
              <WikusTypography variant="caption" {...typoProps}>
                {i.articleNumberOrGroup}
              </WikusTypography>
            </Grid>
            <Grid item sm={6} style={{ textAlign: 'right' }}>
              <WikusTypography variant="caption" {...typoProps}>
                {i.discountType === DiscountType.SpecialDiscount && <span>+&nbsp;</span>}
                <WikusPercentLabel percentInDecimals={i.discount} />
              </WikusTypography>
            </Grid>
          </>
        ))
      }
    </Grid>
  );
}

type TCustomerDetailsDiscountRowProps = {
  group: DiscountGroup;
  hasBackground: boolean;
};

const useDiscountStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  hasBackground: {
    backgroundColor: theme.palette.background.default,
  },
  selectedBackground: {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.primary.contrastText} !important`,
  },
}));

const CustomerDetailsDiscountGroup = ({ group, hasBackground }: TCustomerDetailsDiscountRowProps) => {
  const selectedArticles = useSelector((state: RootState) => state.selectedArticles);
  const classes = useDiscountStyles();
  const [open, setOpen] = useState(true);

  const isItemSelected = useCallback((item: Discount) => {
    return some(selectedArticles, a => a.itemNumber === item.articleNumberOrGroup || a.itemGroup === item.articleNumberOrGroup);
  }, [selectedArticles]);

  const isGroupSelected = useCallback((group: DiscountGroup) => {
    return some(group.items, i => isItemSelected(i.item));
  }, [isItemSelected]);

  const getItemClasses = useCallback((item: Discount) => {
    const isSelected = isItemSelected(item);
    if (isSelected) {
      return `${classes.selectedBackground} ${classes.nested}`;
    } else if (hasBackground) {
      return `${classes.hasBackground} ${classes.nested}`;
    }

    return classes.nested;
  }, [isItemSelected]);

  const getGroupClasses = useCallback((group: DiscountGroup) => {
    const isSelected = isGroupSelected(group);
    if (isSelected) {
      return classes.selectedBackground;
    } else if (hasBackground) {
      return classes.hasBackground;
    }

    return ''
  }, [isGroupSelected]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem className={getGroupClasses(group)} onClick={handleClick}>
        <CustomerDetailsDiscountItem
          item={group.group}
          isSelected={isGroupSelected(group)} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            map(group.items, i => (
              <ListItem
                key={i.item.articleNumberOrGroup}
                className={getItemClasses(i.item)}>
                <CustomerDetailsDiscountItem
                  item={i}
                  isSelected={isItemSelected(i.item)} />
              </ListItem>
            ))
          }
        </List>
      </Collapse>
    </>
  );
}

export type TCustomerDetailsSelectionProps = Omit<WikusModalProps, 'title'> & {
  customer: Customer;
};

export const CustomerDetailsSelection = ({
  classes,
  onSelect,
  customer,
  ...props
}: TCustomerDetailsSelectionProps) => {
  const intl = useIntl();
  const [buttons, setButtons] = useState<any>();
  const customerActions: typeof CustomerActions = useActions(CustomerActions);

  useEffect(() => {
    const buttons = [];
    if (onSelect) {
      buttons.push(<WikusButton
        variant="outlined"
        onClick={() => onSelect!(true as any)}
        color="primary"
      >
        <FormattedMessage id="customer-details.change"></FormattedMessage>
      </WikusButton>);
    }

    buttons.push(<WikusButton
      onClick={() => props.onClose?.({}, 'userSelection')}
      color="secondary"
    >
      <FormattedMessage id="finish"></FormattedMessage>
    </WikusButton>);
    setButtons(buttons);
  }, [onSelect]);

  useEffect(() => {
    if (customer.customerNumber) {
      customerActions.fetchDetails(customer.customerNumber);
    }
  }, [])

  return (
    <WikusModal
      {...props}
      title={customer.name}
      buttons={buttons}
    >
      <WikusCustomerCard
        customer={customer}
        size="large"
        showButtons={false}
      >
        <WikusDetailBlock
          title={intl.formatMessage({ id: 'discount-info' })}
          variant="equal">
          {!customer?.discountGroups
            ? <Skeleton variant="rect" height={48} width="100%" />
            : <List>
              {
                map(customer?.discountGroups, (group, index) => (
                  <CustomerDetailsDiscountGroup
                    key={group.group.item.articleNumberOrGroup}
                    hasBackground={index % 2 === 0}
                    group={group} />
                ))
              }

            </List>}
        </WikusDetailBlock>
      </WikusCustomerCard>
    </WikusModal>
  );
};
