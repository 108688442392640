import * as configReducer from './config';
import { History } from 'history';
import { combineReducers } from 'redux';

import * as snackbarReducer from './snackbarEvent';
import { Article, BonusRecipient, Customer, CustomersFilter, Feedback, NewProcess, Process, Role, SnackbarEvent, User } from '../model';

import * as articleReducer from './article';
import * as customerReducer from './customer';
import * as escalationReducer from './escalation';
import * as feedbackReducer from './feedback';
import * as inputReducer from './input';
import * as processDraftReducer from './process-draft';
import * as processReducer from './process';
import * as userReducer from './user';

export interface RootState {
  currentLocale: string;
  snackbarEvents: SnackbarEvent[];
  accessToken: string;
  refreshToken: string;
  user: User;
  users: User[];
  roles: Role[];
  bonusRecipients: BonusRecipient[];
  inputGroup: any;
  customers: Customer[];
  customersCount: number;
  customersFilter: CustomersFilter | null;
  articles: Article[];
  articlesCount: number;
  selectedArticles: Article[];
  selectedCustomer: Customer;
  newProcess: NewProcess;
  completedProcess: Process;
  processes: Process[];
  processDrafts: Process[];
  processDraftsCount?: number;
  processesCount?: number;
  escalations: Process[];
  escalationsCount?: number;
  feedback: Feedback[];
  feedbackCount: number;
}

export default (history: History) =>
  combineReducers({
    ...configReducer,
    ...snackbarReducer,
    ...userReducer,
    ...inputReducer,
    ...customerReducer,
    ...articleReducer,
    ...processReducer,
    ...processDraftReducer,
    ...escalationReducer,
    ...feedbackReducer,
  });
