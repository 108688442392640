import { FeedbackAction, FeedbackActions, FeedbackQueryParams, PostFeedbackPayload } from '../model';

export function fetchInitial(params?: FeedbackQueryParams): FeedbackAction {
  return {
    type: FeedbackActions.FETCH_FEEDBACK,
    meta: {
      debounce: {
        time: 300,
      },
    },
    payload: {
      request: {
        url: '/feedback',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMore(params?: FeedbackQueryParams): FeedbackAction {
  return {
    type: FeedbackActions.FETCH_MORE_FEEDBACK,
    payload: {
      request: {
        url: '/feedback',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchFilter() {
  return {
    type: FeedbackActions.FETCH_FILTER,
    payload: {
      request: {
        url: '/feedback/filter',
        method: 'GET',
      },
    },
  }
}

export function createFeedback(feedback: PostFeedbackPayload): FeedbackAction {
  return {
    type: FeedbackActions.CREATE_FEEDBACK,
    payload: {
      request: {
        url: '/feedback',
        method: 'POST',
        data: feedback,
      },
    },
  };
}

export function patchFeedback(id: number, data: any): FeedbackAction {
  return {
    type: FeedbackActions.PATCH_FEEDBACK,
    payload: {
      request: {
        url: `/feedback/${id}`,
        method: 'PATCH',
        data,
      },
    },
  };
}

export function deleteFeedback(id: number): FeedbackAction {
  return {
    type: FeedbackActions.DELETE_FEEDBACK,
    payload: {
      request: {
        url: `/feedback/${id}`,
        method: 'DELETE',
      },
    },
  };
}

export function toggleUpvote(id: number): FeedbackAction {
  return {
    type: FeedbackActions.TOGGLE_UPVOTE,
    payload: {
      request: {
        url: `/feedback/vote/${id}`,
        method: 'PATCH',
      },
    },
  };
}