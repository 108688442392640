import { Article, ArticleAction, ArticleActions, Customer, CustomerAction, CustomerActions, FetchSuccessProcessesPayload, NewProcess, NewProcessArticle, NewProcessArticleType, NewProcessPriceState, NewProcessRecipientsPayload, NewProcessSetDeviationPayload, NewProcessSetHintCountPayload, NewProcessSetLengthInMetersPayload, NewProcessSetNoteCountPayload, NewProcessSetPricePayload, NewProcessSetUnitCountPayload, NewProcessSetWithWeldPayload, Process, ProcessAction, ProcessActions, ProcessArticle } from '../model';
import { ProcessDraftAction, ProcessDraftActions } from '../model/process-draft';
import { ResponsePayload } from '../model/request';
import { convertCustomLanguage } from '../components/utils/i18n';
import { createOrUpdateDraft } from '../actions/process-draft';
import { filter, find, findIndex, isUndefined, map, some, take, takeRight } from 'lodash';
import { makeId } from '../utils';
import createReducer from './createReducer';

const isUnitCountDefined = (unitCount?: number) => !isUndefined(unitCount);

const calculateAmount = (article: NewProcessArticle) => {
  if (!isUnitCountDefined(article.unitCount)) {
    return undefined;
  }

  return (article.unitCount as number) * article.netPerUnit;
}

const getState = (netPerUnit: number, escalationThresholdInCent: number, aboveTargetThresholdInCent: number, targetNetPerUnit: number) => {
  let stateOfPrice: NewProcessPriceState = 'escalation';

  if (netPerUnit >= escalationThresholdInCent) {
    stateOfPrice = 'goal';
  }

  if (netPerUnit > aboveTargetThresholdInCent) {
    stateOfPrice = 'above_goal';
  }

  if (netPerUnit < targetNetPerUnit && netPerUnit >= escalationThresholdInCent) {
    stateOfPrice = 'below_goal';
  }

  return stateOfPrice;
}

const editArticle = (article: ProcessArticle, customer: Customer) => {
  const newArticle = createArticle(article.article, customer, article.unitCount);
  const weldPrice = article.withWeld ? article.article.weldPrice : 0;
  const grossPerUnitAndMeter = newArticle.grossPerUnitAndMeter + weldPrice;
  const grossPerUnit = newArticle.grossPerUnit + weldPrice;
  const targetNetPerUnit = newArticle.targetNetPerUnit + weldPrice;
  const escalationThreshold = newArticle.escalationThreshold;
  const escalationThresholdDiff = targetNetPerUnit * escalationThreshold;
  const escalationThresholdInCent = targetNetPerUnit - escalationThresholdDiff;
  const aboveTargetThresholdInCent = targetNetPerUnit + escalationThresholdDiff;
  let type: NewProcessArticleType = 'variant';

  if (article.article.isFamily) {
    type = article.withWeld ? 'family-with-weld' : 'family-without-weld';
  }

  const editArticle: NewProcessArticle = {
    ...newArticle,
    ...article,
    grossPerUnitAndMeter,
    grossPerUnit,
    targetNetPerUnit,
    escalationThreshold,
    escalationThresholdInCent,
    aboveTargetThresholdInCent,
    type,
    isValid: true,
  };

  return {
    ...editArticle,
    state: getState(editArticle.netPerUnit, editArticle.escalationThresholdInCent, editArticle.aboveTargetThresholdInCent, editArticle.targetNetPerUnit),
  };
}

const setPrice = (article: NewProcessArticle, price: number) => {
  const weldPrice = article.withWeld ? article.article.weldPrice : 0;
  const netPerUnit = price;
  const discount = 1.0 - ((netPerUnit - weldPrice) / (article.grossPerUnit - weldPrice));
  const priceDiff = article.targetNetPerUnit - netPerUnit;
  const deviation = priceDiff / article.targetNetPerUnit;
  return {
    ...article,
    discount,
    deviation,
    netPerUnit,
    state: getState(netPerUnit, article.escalationThresholdInCent, article.aboveTargetThresholdInCent, article.targetNetPerUnit),
    isEscalation: deviation > article.escalationThreshold,
    amount: calculateAmount({
      ...article,
      netPerUnit,
    }),
  } as NewProcessArticle;
}

const createArticle = (articleDetails: Article, customer?: Customer, unitCount?: number) => {
  const sale = find(articleDetails.sales, s => s.customerNumber === customer?.customerNumber);
  const discount = (customer?.discount || 0) + articleDetails.discount;
  const grossPerUnitAndMeter = articleDetails.price;
  const targetNetPerUnit = grossPerUnitAndMeter * (1.0 - (discount));
  const netPerUnit = sale?.netPrice ? sale.netPrice : targetNetPerUnit;
  const escalationThreshold = customer?.escalationThreshold || articleDetails.escalationThreshold;
  const escalationThresholdDiff = targetNetPerUnit * escalationThreshold;
  const escalationThresholdInCent = targetNetPerUnit - escalationThresholdDiff;
  const aboveTargetThresholdInCent = targetNetPerUnit + escalationThresholdDiff;
  const article: NewProcessArticle = {
    lastNetPrice: sale?.netPrice,
    withWeld: false,
    isValid: false,
    state: getState(netPerUnit, escalationThresholdInCent, aboveTargetThresholdInCent, targetNetPerUnit),
    type: articleDetails.isFamily ? 'family-without-weld' : 'variant',
    article: articleDetails,
    targetDiscount: discount,
    discount,
    deviation: 0.0,
    isEscalation: false,
    grossPerUnitAndMeter,
    grossPerUnit: grossPerUnitAndMeter,
    lengthInMeters: 1,
    netPerUnit,
    targetNetPerUnit,
    unitCount,
    escalationThreshold,
    escalationThresholdInCent,
    aboveTargetThresholdInCent,
    minimumPerUnit: 0,
  };
  return {
    ...article,
    ...setPrice(article, netPerUnit),
  };
}

const setDeviation = (article: NewProcessArticle, deviation: number) => {
  const netPerUnit = article.targetNetPerUnit - (article.targetNetPerUnit * deviation);
  const discount = 1.0 - (netPerUnit / (article.grossPerUnitAndMeter * article.lengthInMeters));
  return {
    ...article,
    discount,
    deviation,
    netPerUnit,
    state: getState(netPerUnit, article.escalationThresholdInCent, article.aboveTargetThresholdInCent, article.targetNetPerUnit),
    isEscalation: deviation > article.escalationThreshold,
    amount: calculateAmount({
      ...article,
      netPerUnit,
    }),
  } as NewProcessArticle;
}

const setLengthInMeters = (article: NewProcessArticle, lengthInMeters: number) => {
  const netPerUnit = (article.netPerUnit / article.lengthInMeters) * lengthInMeters;
  const targetNetPerUnit = (article.targetNetPerUnit / article.lengthInMeters) * lengthInMeters;
  const grossPerUnit = (article.grossPerUnit / article.lengthInMeters) * lengthInMeters;
  const escalationThresholdInCent = (article.escalationThresholdInCent / article.lengthInMeters) * lengthInMeters;
  const aboveTargetThresholdInCent = (article.aboveTargetThresholdInCent / article.lengthInMeters) * lengthInMeters;
  return {
    ...article,
    lengthInMeters,
    netPerUnit,
    targetNetPerUnit,
    grossPerUnit,
    escalationThresholdInCent,
    aboveTargetThresholdInCent,
    state: getState(netPerUnit, escalationThresholdInCent, aboveTargetThresholdInCent, targetNetPerUnit),
    amount: calculateAmount({
      ...article,
      netPerUnit,
    }),
  } as NewProcessArticle;
}

const setWithWeld = (article: NewProcessArticle, withWeld: boolean, customer?: Customer) => {
  const weldPrice = withWeld ? article.article.weldPrice : -article.article.weldPrice;
  const grossPerUnitAndMeter = article.grossPerUnitAndMeter + weldPrice
  const grossPerUnit = article.grossPerUnit + weldPrice
  const netPerUnit = article.netPerUnit + weldPrice;
  const targetNetPerUnit = article.targetNetPerUnit + weldPrice;
  const escalationThreshold = article.escalationThreshold;
  const escalationThresholdDiff = targetNetPerUnit * escalationThreshold;
  const escalationThresholdInCent = targetNetPerUnit - escalationThresholdDiff;
  const aboveTargetThresholdInCent = targetNetPerUnit + escalationThresholdDiff;
  const newLengthInMeter = withWeld ? article.lengthInMeters : 1;
  const newArticle = {
    ...article,
    grossPerUnitAndMeter,
    grossPerUnit,
    netPerUnit,
    targetNetPerUnit,
    escalationThresholdInCent,
    aboveTargetThresholdInCent,
    withWeld,
    type: withWeld ? 'family-with-weld' : 'family-without-weld',
    amount: calculateAmount(article),
  } as NewProcessArticle;

  return setLengthInMeters(newArticle, newLengthInMeter);
}

const setUnitCount = (article: NewProcessArticle, unitCount: number) => {
  return {
    ...article,
    unitCount,
    isValid: unitCount > 0,
    amount: calculateAmount({
      ...article,
      unitCount,
    }),
  } as NewProcessArticle;
}

const generateName = () => {
  const key = makeId(5);
  return `#${key}`;
}

export const newProcess = createReducer<NewProcess | null>(null, {
  [ArticleActions.SELECT_ARTICLES](state: NewProcess, action: ArticleAction) {
    const articles = action.payload as Article[];
    const name = state ? state.name : generateName();

    if (!state) {
      state = {
        userSelectionComplete: false,
        articles: [],
        recipients: [],
        name,
      };
    }

    const newArticles = filter(articles, a => !some(state.articles, sa => sa.article.id === a.id));
    const processArticles = [
      ...filter(state.articles, a => !some(newArticles, na => na.id === a.article.id) && some(articles, ca => ca.id === a.article.id)),
      ...map(newArticles, a => createArticle(a, state.customer))
    ];
    const result = {
      ...state,
      articles: processArticles,
      userSelectionComplete: !!state.customer,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessDraftActions.CREATE_OR_UPDATE_DRAFT_SUCCESS](state: NewProcess, action: ProcessDraftAction) {
    const { id } = (action.payload as ResponsePayload<Process>).data;
    if (!state) {
      return {
        draftId: id,
      };
    }

    return {
      ...state,
      draftId: id,
    }
  },
  [CustomerActions.SELECT_CUSTOMER](state: NewProcess, action: CustomerAction) {
    const customer = action.payload as Customer;
    const name = state ? state.name : generateName();

    if (!state) {
      state = {
        userSelectionComplete: false,
        articles: [],
        recipients: [],
        name,
      };
    }

    const result = {
      ...state,
      articles: map(state.articles, a => createArticle(a.article, customer)),
      customer,
      userSelectionComplete: !!state.articles?.length,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_PRICE](state: NewProcess, action: ProcessAction) {
    const { price, articleId } = action.payload as NewProcessSetPricePayload;
    const articles = map(state.articles, a => {
      if (a.article.id === articleId) {
        return setPrice(a, price);
      }

      return a;
    });
    const result = {
      ...state,
      articles,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_DEVIATION](state: NewProcess, action: ProcessAction) {
    const { deviation, articleId } = action.payload as NewProcessSetDeviationPayload;
    const articles = map(state.articles, a => {
      if (a.article.id === articleId) {
        return setDeviation(a, deviation);
      }

      return a;
    });
    const result = {
      ...state,
      articles,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_LENGTH_IN_METERS](state: NewProcess, action: ProcessAction) {
    const { lengthInMeters, articleId } = action.payload as NewProcessSetLengthInMetersPayload;
    const articles = map(state.articles, a => {
      if (a.article.id === articleId) {
        return setLengthInMeters(a, lengthInMeters);
      }

      return a;
    });
    const result = {
      ...state,
      articles,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_UNIT_COUNT](state: NewProcess, action: ProcessAction) {
    const { unitCount, articleId } = action.payload as NewProcessSetUnitCountPayload;
    const articles = map(state.articles, a => {
      if (a.article.id === articleId) {
        return setUnitCount(a, unitCount);
      }

      return a;
    });
    const result = {
      ...state,
      articles,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_WITH_WELD](state: NewProcess, action: ProcessAction) {
    const { withWeld, articleId } = action.payload as NewProcessSetWithWeldPayload;
    const articles = map(state.articles, a => {
      if (a.article.id === articleId) {
        return setWithWeld(a, withWeld, state.customer);
      }

      return a;
    });
    const result = {
      ...state,
      articles,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_HINT](state: NewProcess, action: ProcessAction) {
    const { hint, articleId } = action.payload as NewProcessSetHintCountPayload;
    const articles = map(state.articles, a => {
      if (a.article.id === articleId) {
        return {
          ...a,
          hint,
        }
      }

      return a;
    });
    const result = {
      ...state,
      articles,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_NOTE](state: NewProcess, action: ProcessAction) {
    const { note, articleId } = action.payload as NewProcessSetNoteCountPayload;
    const articles = map(state.articles, a => {
      if (a.article.id === articleId) {
        return {
          ...a,
          note,
        }
      }

      return a;
    });
    const result = {
      ...state,
      articles,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_RECIPIENTS](state: NewProcess, action: ProcessAction) {
    let { recipients } = action.payload as NewProcessRecipientsPayload;

    recipients = map(recipients, ({ recipients: recipientsUsers, ...rest }) => ({
      ...rest,
      recipients: map(recipientsUsers, ({ language, ...uRest }) => ({
        ...uRest,
        language: language || convertCustomLanguage(state.customer?.addressLanguage || state.customer?.languageId),
      })),
    }));

    const result = {
      ...state,
      recipients,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_MESSAGE](state: NewProcess, action: ProcessAction) {
    const message = action.payload as string;
    const result = {
      ...state,
      message,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.SET_NAME](state: NewProcess, action: ProcessAction) {
    const name = action.payload as string;

    if (!state) {
      return {
        userSelectionComplete: false,
        articles: [],
        recipients: [],
        name,
      };
    }

    const result = {
      ...state,
      name,
    };

    if (!state?.id) {
      action.asyncDispatch(createOrUpdateDraft({
        ...result,
        id: state?.draftId,
      }));
    }

    return result;
  },
  [ProcessActions.CLEAR_NEW_PROCESS](state: NewProcess, action: ProcessAction) {
    return null;
  },
  [ProcessActions.EDIT_PROCESS](state: NewProcess, action: ProcessAction) {
    const process = action.payload as Process;
    return {
      ...process,
      articles: map(process.articles, a => editArticle(a, process.customer)),
      userSelectionComplete: process.articles.length && process.customer,
      recipients: map(process.recipients, r => ({
        type: r.type,
        recipients: map(r.recipientUsers, u => u.email),
      })),
    };
  },
});

export const completedProcess = createReducer<Process | null>(null, {
  [ProcessActions.CREATE_PROCESS_SUCCESS](state: Process | null, action: ProcessAction) {
    return (action.payload as ResponsePayload<Process>).data;
  },
  [ProcessActions.UPDATE_PROCESS_SUCCESS](state: Process | null, action: ProcessAction) {
    return (action.payload as ResponsePayload<Process>).data;
  },
  [ProcessActions.CLEAR_NEW_PROCESS](state: NewProcess, action: ProcessAction) {
    return null;
  },
});

export const processes = createReducer<Process[] | null>([], {
  [ProcessActions.FETCH_PROCESSES_SUCCESS](state: Process[], action: ProcessAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.items;
  },
  [ProcessActions.FETCH_MORE_PROCESSES_SUCCESS](state: Process[] | null, action: ProcessAction) {
    return [
      ...(state || []),
      ...(action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.items
    ];
  },
  [ProcessActions.PATCH_PROCESS_SUCCESS](state: Process[], action: ProcessAction) {
    const process = (action.payload as ResponsePayload<Process>).data;
    const index = findIndex(state, p => p.id === process.id);

    return [
      ...take(state, index),
      process,
      ...takeRight(state, state.length - (index + 1))
    ];
  },
});

export const processesCount = createReducer<number | undefined>(0, {
  [ProcessActions.FETCH_PROCESSES_SUCCESS](state: number | undefined, action: ProcessAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.count;
  },
  [ProcessActions.FETCH_MORE_PROCESSES_SUCCESS](state: number | undefined, action: ProcessAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.count;
  },
});
