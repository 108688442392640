import * as FeedbackActions from '../../../actions/feedback';
import { FeedbackStatus } from '../../../model';
import { WikusFilter, WikusFilterOptionChangeHandler, WikusFilterOptionType } from '../../../components/complex/filter';
import { useFilter } from '../../../hooks/useFilter';
import { useIntl } from 'react-intl';

export interface FeedbackFilterProps {
  onChange?: WikusFilterOptionChangeHandler;
}

export const FeedbackFilter = ({ onChange }: FeedbackFilterProps) => {
  const intl = useIntl();
  const { isLoading, filter } = useFilter<typeof FeedbackActions>(FeedbackActions, [{
    type: WikusFilterOptionType.text,
    props: {
      name: 'createdAt',
      type: 'date',
    } as any,
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'title',
    },
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'description',
    },
  }], 'feedback');

  const filterTitle = intl.formatMessage({
    id: 'feedback.filter.title',
  });
  const filterDescription = intl.formatMessage({
    id: 'feedback.filter.description',
  });

  return (
    <WikusFilter
      title={filterTitle}
      description={filterDescription}
      isLoading={isLoading}
      initialFilter={{
        status: FeedbackStatus.Open,
      }}
      options={filter}
      onChange={onChange} />
  );
}