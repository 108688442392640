import { Customer, Process, ProcessArticle, ProcessEscalation, ProcessRecipient } from '../../model';
import { CustomerDetailsSelection } from './customer-details-section';
import { IconButton } from '@material-ui/core';
import { InfoIcon } from '../core/icons';
import { IntlShape, useIntl } from 'react-intl';
import { ReactNode, useState } from 'react';
import { WikusArticleCard } from './article-card';
import { WikusCurrencyLabel, WikusDateLabel, WikusTranslateLabel } from '../core/formatted-label';
import { WikusCustomerCard } from './customer-card';
import { WikusDetailBlock, WikusDetailRowItem } from './detail-block';
import { WikusHr } from '../core/hr';
import { WikusItem } from '../core/spacing';
import { find, get, map, some } from 'lodash';
import { getArticleDetailRowItems } from '../utils/article-details';

export interface WikusCustomerSectionProps {
  customer: Customer;
  children?: ReactNode;
}

export const WikusCustomerSection = ({ customer, children }: WikusCustomerSectionProps) => {
  const intl = useIntl();
  const [showCustomerDetailsSection, setShowCustomerDetailsSection] = useState(
    false
  );
  const customerTitle = intl.formatMessage({
    id: 'process.customer.title',
  });

  return (
    <>
      <WikusItem spacingDirection="down">
        <WikusDetailBlock title={customerTitle}>
          <WikusCustomerCard
            size="small"
            elevation={0}
            customer={customer}
            actionItem={
              <IconButton onClick={() => setShowCustomerDetailsSection(true)}>
                <InfoIcon />
              </IconButton>
            } />
          {children}
        </WikusDetailBlock>
      </WikusItem>
      <CustomerDetailsSelection
        customer={customer}
        open={showCustomerDetailsSection}
        onClose={() => setShowCustomerDetailsSection(false)}
      ></CustomerDetailsSelection>
    </>
  );
};

const ProcessDetails = [
  {
    name: 'number',
    field: 'number',
    component: (value: any) => value,
  },
  {
    name: 'customerName',
    field: 'customer.name',
    component: (value: any) => value,
  },
  {
    name: 'date',
    field: 'date',
    component: (value: string) => <WikusDateLabel date={value} />,
  },
  {
    name: 'articleCount',
    field: 'articles',
    component: (articles: ProcessArticle[]) => articles.length,
  },
  {
    name: 'isEscalation',
    field: 'articles',
    component: (articles: ProcessArticle[]) => (
      <WikusTranslateLabel value={some(articles, (a) => a.isEscalation)} />
    ),
  },
  {
    name: 'amount',
    field: 'amount',
    component: (amount: number) => <WikusCurrencyLabel amount={amount} />,
  },
  {
    name: 'status',
    field: 'status',
    component: (status: string) => (
      <WikusTranslateLabel value={`process.status.item.${status}`} />
    ),
  },
  {
    name: 'recipients',
    field: 'recipients',
    component: (recipients: ProcessRecipient[]) => {
      const emailRecipients = find(recipients, (r) => r.type === 'eMail');
      if (!emailRecipients || !emailRecipients.recipientUsers?.length) {
        return '-';
      }

      return map(emailRecipients.recipientUsers, (u) => u.email).join(', ');
    },
  }
];

export interface ProcessContentProps {
  process: Process;
}

export const WikusProcessSection = ({ process }: ProcessContentProps) => {
  const intl = useIntl();
  const processTitle = intl.formatMessage({
    id: 'process.process.title',
  });
  const detailRowItems: WikusDetailRowItem[] = map(ProcessDetails, (p) => ({
    label: intl.formatMessage({
      id: `process.${p.name}.label`,
    }),
    value: p.component(get(process, p.field)),
  })) as WikusDetailRowItem[];

  return (
    <WikusItem spacingDirection="down">
      <WikusDetailBlock
        title={processTitle}
        items={detailRowItems}
        variant="equal-centered"
      />
    </WikusItem>
  );
};

const getAdditionalItems = (
  article: ProcessArticle,
  intl: IntlShape,
  isMyEscalationsView = false
) => {
  let additionalItems = [];

  if (article.hint && article.isEscalation) {
    additionalItems.push({
      label: intl.formatMessage({ id: 'process-content.hint' }),
      value: article.hint,
      isFullWidth: true,
    });
  }

  if (article.note) {
    additionalItems.push({
      label: intl.formatMessage({ id: 'process-content.note' }),
      value: article.note,
      isFullWidth: true,
    });
  }

  if (isMyEscalationsView) {
    const { items } = getArticleDetailRowItems(intl, article, 'process');
    additionalItems.push(items);
  }

  return additionalItems.flat();
};

export interface WikusArticleSectionProps {
  articles: ProcessArticle[];
  size?: 'large' | 'small';
  isMyEscalationsView?: boolean;
}

export const WikusArticlesSection = ({
  articles,
  size = 'small',
  isMyEscalationsView = false,
}: WikusArticleSectionProps) => {
  const intl = useIntl();
  const articleTitle = intl.formatMessage({
    id: 'process.article.title',
  });

  return (
    <WikusItem spacingDirection="down">
      <WikusDetailBlock title={articleTitle}>
        {articles.map((article, index) => (
          <div key={article.article.id}>
            {index > 0 && <WikusHr color="grey" />}
            <WikusArticleCard
              key={article.article.id}
              size={size}
              elevation={0}
              article={article.article}
              isEscalation={article.isEscalation}
              items={getAdditionalItems(article, intl, isMyEscalationsView)}
            />
          </div>
        ))}
      </WikusDetailBlock>
    </WikusItem>
  );
};

const UserDetails = [
  {
    name: 'employee',
    field: 'user.name',
    component: (value: any) => value,
  },
  {
    name: 'role',
    field: 'user.role',
    component: (value: any) => value,
  },
  {
    name: 'message',
    field: 'message',
    component: (value: any) => value || '-',
  }
];
export const WikusUserSection = ({ process }: ProcessContentProps) => {
  const intl = useIntl();
  const processTitle = intl.formatMessage({
    id: 'process.user.title',
  });
  const detailRowItems: WikusDetailRowItem[] = map(UserDetails, (p) => ({
    label: intl.formatMessage({
      id: `process.${p.name}.label`,
    }),
    value: p.component(get(process, p.field)),
  })) as WikusDetailRowItem[];

  return (
    <WikusItem spacingDirection="down">
      <WikusDetailBlock
        title={processTitle}
        items={detailRowItems}
        variant="equal-centered"
      />
    </WikusItem>
  );
};

export interface WikusEscalationSectionProps {
  escalation: ProcessEscalation;
}

const EscalationDetails = [
  {
    name: 'escalateur',
    field: 'editor.name',
    component: (value: any) => value,
  },
  {
    name: 'date',
    field: 'editedAt',
    component: (value: any) => <WikusDateLabel date={value} />,
  },
  {
    name: 'message',
    field: 'message',
    component: (value: any) => value || '-',
  }
];
export const WikusEscalationSection = ({
  escalation,
}: WikusEscalationSectionProps) => {
  const intl = useIntl();
  const processTitle = intl.formatMessage({
    id: 'process.escalation.title',
  });
  const detailRowItems: WikusDetailRowItem[] = map(EscalationDetails, (p) => ({
    label: intl.formatMessage({
      id: `process.${p.name}.label`,
    }),
    value: p.component(get(escalation, p.field)),
  })) as WikusDetailRowItem[];

  return (
    <WikusItem spacingDirection="down">
      <WikusDetailBlock
        title={processTitle}
        items={detailRowItems}
        variant="equal-centered"
      />
    </WikusItem>
  );
};
