import * as ArticleActions from '../../../actions/article';
import { Article } from '../../../model';
import { DeleteAction } from '../../../components/core/icons';
import { IconButton } from '@material-ui/core';
import { WikusArticleCard } from '../../../components/complex/article-card';
import { WikusHr } from '../../../components/core/hr';
import { WikusPanel } from '../../../components/core/card';
import { filter } from 'lodash';
import { useActions } from '../../../actions';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export interface ArticleBasketProps {
  articles: Article[];
}

export const ArticleBasket = ({ articles }: ArticleBasketProps) => {
  const intl = useIntl();
  const articleActions: typeof ArticleActions = useActions(ArticleActions);

  const title = intl.formatMessage({
    id: 'article-basket.title',
  });

  const description = intl.formatMessage({
    id: 'article-basket.description',
  });

  const removeArticle = useCallback(
    (article: Article) => {
      articleActions.selectArticles(
        filter(articles, (a) => a.id !== article.id)
      );
    },
    [articleActions, articles]
  );

  return (
    <WikusPanel title={title} description={description}>
      {articles.map((article, index) => (
        <div key={article.id}>
          {index > 0 && <WikusHr color="paper" />}
          <WikusArticleCard
            article={article}
            size="small"
            elevation={0}
            status="success"
            actionItem={
              <IconButton onClick={() => removeArticle(article)}>
                <DeleteAction />
              </IconButton>
            }
          />
        </div>
      ))}
    </WikusPanel>
  );
};
