import * as UserActions from '../../../actions/user';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootState } from '../../../reducers';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { UserDetailsSection } from '../../../components/complex/user-details-section';
import { WikusButton } from '../../../components/core/button';
import { WikusPanel } from '../../../components/core/card';
import { WikusSwitch } from '../../../components/core/switch';
import { filter } from 'lodash';
import { useActions } from '../../../actions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const UserTablePanel = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'user-table.title' });
  const users = useSelector((rootState: RootState) => rootState.users);
  const user = useSelector((rootState: RootState) => rootState.user);
  const withoutMe = filter(users, u => u.id !== user.id);
  const [editUserId, setEditUserId] = useState<number>();

  const userActions: typeof UserActions = useActions(
    UserActions
  );

  const translate = (id: string) => intl.formatMessage({ id });

  const toggleUserActive = (id: number) => {
    userActions.toggleUserAcive(id);
  }

  useEffect(() => {
    userActions.fetchUsers();
  }, []);

  return (
    <>
      <WikusPanel title={title}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('email')}</TableCell>
              <TableCell align="right">{translate('name')}</TableCell>
              <TableCell align="right">{translate('role')}</TableCell>
              <TableCell align="right">{translate('status')}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {withoutMe.map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.email}
                </TableCell>
                <TableCell align="right">{user.name}</TableCell>
                <TableCell align="right">{user.role}</TableCell>
                <TableCell align="right">
                  <WikusSwitch
                    name={`active-${user.id}`}
                    checked={user.isActive}
                    onChange={() => toggleUserActive(user.id)} />
                </TableCell>
                <TableCell align="right">
                  <WikusButton
                    variant="text"
                    onClick={() => setEditUserId(user.id)}>
                    <FormattedMessage id="edit" />
                  </WikusButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </WikusPanel>

      <UserDetailsSection userId={editUserId} open={!!editUserId} onClose={() => setEditUserId(undefined)} />
    </>
  )
}