import * as EscalationActions from '../../../actions/escalation';
import * as SnackbarEventAction from '../../../actions/snackbarEvent';
import { AccordionClose, AccordionOpen } from '../../../components/core/icons';
import { EscalationContent } from './escalation-content';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton } from '@material-ui/core';
import { Process, ProcessActions } from '../../../model';
import { WikusAccordion } from '../../../components/complex/accordion';
import { WikusButton } from '../../../components/core/button';
import { WikusItem } from '../../../components/core/spacing';
import { WikusProcessCard } from '../../../components/complex/process-card';
import { WikusTypography } from '../../../components/core/typography';
import { formatDate } from '../../../utils';
import { groupBy, map, some } from 'lodash';
import { useAccordion } from '../../../hooks/useAccordion';
import { useActions } from '../../../actions';
import { useCallback } from 'react';
import { useState } from 'react';

export interface EscalationAcordionProps {
  escalations: Process[];
}

export const EscalationAccordion = ({ escalations }: EscalationAcordionProps) => {
  const intl = useIntl();
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const idFn = useCallback((escalation: Process) => escalation.id, []);
  const {
    toggle,
    isSelected,
    createItemContainer,
  } = useAccordion<Process>(escalations, idFn, {
    openFirst: false,
    canScroll: true,
  });
  const escalationActions: typeof EscalationActions = useActions(EscalationActions);
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );

  const handlePatchResponse = (response: any) => {
    const { type } = response;
    if (type === ProcessActions.PATCH_ESCALATION_SUCCESS) {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'success.patch-escalation',
        }),
        severity: 'success',
      });
    } else {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'errors.patch-escalation',
        }),
        severity: 'error',
      });
    }
  }

  const rejectEscalation = async (escalation: Process) => {
    setRejectLoading(true);
    const resp = await escalationActions.patchEscalation(escalation.id, {
      status: 'rejected',
      escalation: {
        message: escalation.escalationMessage,
      },
    });
    setRejectLoading(false);
    handlePatchResponse(resp);
    toggle(escalation);
  }

  const approveEscalation = async (escalation: Process) => {
    setApproveLoading(true);
    const resp = await escalationActions.patchEscalation(escalation.id, {
      status: 'approved',
      escalation: {
        message: escalation.escalationMessage,
      },
    });
    setApproveLoading(false);
    handlePatchResponse(resp);
    toggle(escalation);
  }

  const getButtons = (escalation: Process) => {
    const buttons = [];

    if (escalation.status === 'open') {
      buttons.push(<WikusButton
        color="primary"
        variant="outlined"
        disabled={rejectLoading || approveLoading}
        loading={rejectLoading}
        onClick={() => rejectEscalation(escalation)}
      >
        <FormattedMessage id="reject" />
      </WikusButton>);
      buttons.push(<WikusButton
        color="secondary"
        disabled={rejectLoading || approveLoading}
        loading={approveLoading}
        onClick={() => approveEscalation(escalation)}
      >
        <FormattedMessage id="approve" />
      </WikusButton>);
    }

    return buttons;
  }

  const getStatus = (escalation: Process) => {
    if (escalation.status === 'approved') {
      return 'success';
    }

    if (escalation.status === 'rejected') {
      return 'error';
    }
  }

  const escalationGroups = groupBy(escalations, p => formatDate(intl, p.date));

  return (
    <>
      {map(escalationGroups, (currentEscalations, date) => (
        <div key={date}>
          <WikusTypography gutterBottom variant="h3">
            {date}
          </WikusTypography>
          {currentEscalations?.map((escalation, index) => (
            <WikusItem {...createItemContainer(escalation)} spacingDirection="down" key={index}>
              <WikusAccordion
                expanded={isSelected(escalation)}
                onChange={() => toggle(escalation)}
                buttons={getButtons(escalation)}
                summary={
                  <WikusProcessCard
                    process={escalation}
                    isEscalation={some(escalation.articles, a => a.isEscalation)}
                    status={getStatus(escalation)}
                    actionItem={
                      <IconButton>
                        {isSelected(escalation) ? (
                          <AccordionClose />
                        ) : (
                          <AccordionOpen />
                        )}
                      </IconButton>
                    }
                    size="small"
                    elevation={0}
                  />
                }
              >
                <EscalationContent escalation={escalation} />
              </WikusAccordion>
            </WikusItem>
          ))}
        </div>
      ))}
    </>
  )
}