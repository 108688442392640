import { Article, Customer } from '../../model';
import { MouseEventHandler } from 'react';
import { TWikusModelCardProps, WikusModelCard } from './model-card';
import { WikusAvatar } from '../core/avatar';
import { WikusDetailRowItem } from './detail-block';
import { WikusTag } from '../core/tag';
import { getArticleDetailRowItems } from '../utils/article-details';
import { getInitials } from '../../utils';
import { some } from 'lodash';
import { useIntl } from 'react-intl';

export type WikusArticleCardSize = 'small' | 'large';

export interface WikusArticleCardProps
  extends Omit<
  TWikusModelCardProps,
  'avatar' | 'title' | 'details' | 'detailsVariant' | 'tags' | 'size'
  > {
  article: Article;
  isEscalation?: boolean;
  size: WikusArticleCardSize;
  onSelect?: MouseEventHandler;
  items?: WikusDetailRowItem[];
  customer?: Customer;
}

const WikusArticleCard = ({
  article,
  isEscalation,
  items: additionalItems,
  size = 'small',
  customer,
  ...props
}: WikusArticleCardProps) => {
  const intl = useIntl();

  const {
    variant: detailRowVariant,
    items: detailRowItems,
  } = getArticleDetailRowItems(intl, article, size);

  const avatarShort = getInitials(article.name);
  const tags = article.isFamily
    ? [
      <WikusTag
        color="primary"
        label={intl.formatMessage({
          id: 'article.is-family',
        })}
      ></WikusTag>
    ]
    : [];

  const isBought = some(article.sales, s => s.customerNumber === customer?.customerNumber);
  if (isBought) {
    tags.push(<WikusTag
      color="primary"
      label={intl.formatMessage({
        id: 'article.is-bought',
      })}
    ></WikusTag>)
  }

  return (
    <WikusModelCard
      {...props}
      title={article.itemGroupName || article.name}
      avatar={
        <WikusAvatar
          status={isEscalation ? 'warning' : undefined}
          size={size}
          src={article.imageUrl}
        >
          {avatarShort}
        </WikusAvatar>
      }
      details={[...detailRowItems, ...(additionalItems || [])]}
      detailsVariant={detailRowVariant}
      tags={tags}
      size={size}
    />
  );
};
WikusArticleCard.displayName = 'WikusArticleCard';

export { WikusArticleCard };
