import { Box, Grid } from '@material-ui/core';
import { IOnValidatedPayload } from '../core/input';
import { IWikusAutocompleteItem, WikusAutocomplete, WikusAutocompleteProps } from '../core/autocomlete';
import { ReactNode, useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { WikusButton } from '../core/button';
import { WikusPanel } from '../core/card';
import { WikusTag } from '../core/tag';
import { WikusTextField, WikusTextFieldProps } from '../core/input';
import { filter, find, indexOf, omit, orderBy } from 'lodash';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'

export interface WikusFilterProps {
  title: string;
  description: string;
  options: WikusFilterOption[];
  onChange?: WikusFilterOptionChangeHandler;
  isLoading?: boolean;
  order?: string[];
  initialFilter?: WikusFilterValue;
}

export interface WikusFilterOptionBoolProps {
  name: string;
  label: string;
  value?: string;
}

export interface WikusFilterOptionAutocompleteProps extends WikusAutocompleteProps {
  label: string;
}

export interface WikusFilterOptionTextFieldProps extends WikusTextFieldProps {
  label: string;
}

export type WikusFilterOptionProps = WikusFilterOptionTextFieldProps | WikusFilterOptionAutocompleteProps | WikusFilterOptionBoolProps;

export type WikusFilterValue = { [key: string]: any };
export type WikusFilterOptionChangeHandler = (value: WikusFilterValue) => void;

export interface WikusFilterOption {
  type: WikusFilterOptionType;
  props: WikusFilterOptionProps;
}

export enum WikusFilterOptionType {
  bool,
  text,
  option
}

export type WikusFilterItemHandlingType = {
  [key in WikusFilterOptionType]?: (props: WikusFilterOptionProps, onChange: (value: unknown) => void) => ReactNode | void;
};

interface WikusFilterTextFieldProps extends Omit<WikusTextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
};

const WikusFilterText = ({ onChange, validator, ...props }: WikusFilterTextFieldProps) => {
  const [currentValue, setValue] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(validator ? false : true);

  const onSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmitted(true);
    if (isValid) {
      onChange(currentValue);
    }

    return false;
  }

  const onValidated = ({ isValid }: IOnValidatedPayload) => {
    setIsValid(isValid);
  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid container>
        <Grid item xs>
          <WikusTextField {...(props)} value={currentValue} onChange={({ target: { value } }) => setValue(value)} validator={validator} onValidated={onValidated} submitted={submitted}></WikusTextField>
        </Grid>
        <Grid item>
          <WikusButton type="submit" color="secondary">ok</WikusButton>
        </Grid>
      </Grid>
    </form>
  );
}

interface WikusFilterAutocompleteFieldProps extends Omit<WikusAutocompleteProps, 'onChange'> {
  onChange: (value: IWikusAutocompleteItem) => void;
};

const WikusFilterAutocomplete = ({ onChange, ...props }: WikusFilterAutocompleteFieldProps) => {
  return (
    <WikusAutocomplete {...props} onChange={(_, value) => onChange(value as any)}></WikusAutocomplete>
  );
}

export const WikusFilterItemHandling: WikusFilterItemHandlingType = {
  [WikusFilterOptionType.bool]: (props: WikusFilterOptionProps, onChange: (value: unknown) => void) => {
    onChange(props.value || true);
  },
  [WikusFilterOptionType.text]: (props: WikusFilterOptionProps, onChange: (value: unknown) => void): ReactNode => {
    return (
      <WikusFilterText {...(props as WikusTextFieldProps)} onChange={onChange} key={props.name} />
    )
  },
  [WikusFilterOptionType.option]: (props: WikusFilterOptionProps, onChange: (value: unknown) => void): ReactNode => {
    return (
      <WikusFilterAutocomplete {...(props as WikusAutocompleteProps)} onChange={(event) => {
        if (event?.id) {
          onChange(event?.id)
        }
      }} />
    )
  },
}

const WikusFilter = ({ title, description, options, isLoading, onChange, order, initialFilter = {} }: WikusFilterProps) => {
  const [filters, setFilters] = useState<WikusFilterValue>(initialFilter);
  const [currentInput, setCurrentInput] = useState<ReactNode | void>();
  const [currentName, setCurrentName] = useState<string | undefined>();

  const selectFilter = ({ props, type }: WikusFilterOption) => {
    const onFieldChange = (value: unknown) => {
      const newFilter = {
        ...filters,
        [props.name]: value,
      };
      setFilters(newFilter);
      onChange && onChange(newFilter);
      setCurrentInput(null);
      setCurrentName(undefined);
    }

    const handler = WikusFilterItemHandling[type];
    if (!handler) {
      return;
    }

    const filterInput = handler(props, onFieldChange);
    setCurrentInput(filterInput);

    if (type !== WikusFilterOptionType.bool) {
      setCurrentName(props.name);
    }
  }

  const deleteFilter = (key: string) => {
    const newFilter = omit(filters, key);
    setFilters(newFilter);
    onChange && onChange(newFilter);
  }

  const sortOptions = (options: WikusFilterOption[]) => orderBy(options, o => {
    const index = indexOf(order, o.props.name);
    if (index < 0) {
      return 99;
    }

    return index;
  });
  const filteredOptions = sortOptions(filter(options, o => !!filters[o.props.name]));
  const unfilteredOptions = sortOptions(filter(options, o => !filters[o.props.name]));

  const getName = (props: WikusFilterOptionProps, id: string): string => {
    if ((props as WikusFilterAutocompleteFieldProps).options) {
      return find((props as WikusFilterAutocompleteFieldProps).options, o => o.id === id)?.name || id;
    }

    return id;
  }

  useEffect(() => {
    if (initialFilter) {
      onChange?.(initialFilter);
    }
  }, []);

  return (
    <WikusPanel size="small" title={title} description={description}>
      <ScrollContainer style={{ display: 'flex', marginLeft: -8, marginTop: -8 }}>
        {
          isLoading ? Array.from({ length: 10 }).map((_, index) => (
            <Grid item key={index} style={{ margin: 8 }}>
              <Skeleton variant="rect" height={48} width={120} />
            </Grid>
          )) : unfilteredOptions.map(({ props, type }: WikusFilterOption) => (
            <Grid item key={props.name} style={{ margin: 8 }}>
              <WikusButton color="primary" variant={props.name === currentName ? 'contained' : 'outlined'} onClick={() => selectFilter({ props, type })}>
                {props.label}
              </WikusButton>
            </Grid>
          ))
        }
      </ScrollContainer>
      {
        !!filteredOptions.length && (
          <Box mt={1}>
            <Grid container spacing={2}>
              {
                filteredOptions.map(({ type, props }: WikusFilterOption) => (
                  <Grid item key={props.name}>
                    <WikusTag
                      color="primary"
                      caption={filters[props.name] === true ? undefined : getName(props, filters[props.name])}
                      label={props.label}
                      onDelete={() => deleteFilter(props.name)}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        )
      }
      {
        currentInput && (
          <Box mt={3}>
            {currentInput}
          </Box>
        )
      }
    </WikusPanel >
  );
};
WikusFilter.displayName = 'WikusFilter';

export {
  WikusFilter
};