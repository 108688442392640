import { FilterItem } from './misc';
import { RequestPayload, ResponsePayload } from './request';

export enum ArticleActions {
  FETCH_ARTICLES = 'FETCH_ARTICLES',
  FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS',
  FETCH_MORE_ARTICLES = 'FETCH_MORE_ARTICLES',
  FETCH_MORE_ARTICLES_SUCCESS = 'FETCH_MORE_ARTICLES_SUCCESS',
  FETCH_FILTER = 'ARTICLE:FETCH_FILTER',
  FETCH_FILTER_SUCCESS = 'ARTICLE:FETCH_FILTER_SUCCESS',
  SELECT_ARTICLES = 'SELECT_ARTICLES'
}

interface ArticleActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export interface ArticleQueryParams {
  take?: number;
  skip?: number;
}

export type ArticleSelectionType = 'variant' | 'family';

export type BusinessArea = 'Band' | 'Rolle';

export interface Sale {
  netPrice?: number;
  customerNumber: string;
  itemNumber: string;
}

export interface Article {
  id: number;
  name: string;
  itemNumber: string;
  isFamily: boolean;
  status?: number;
  description?: string;
  shortDescription?: string;
  itemGroupName?: string;
  itemGroup?: string;
  productGroupName?: string;
  businessAreaName: BusinessArea;
  containerSize?: number;
  length?: number;
  size?: string;
  discount: number;
  toothPerInch?: string;

  price: number;
  weldPrice: number;
  escalationThreshold: number;

  imageUrl?: string;
  sales?: Sale[];
}

export interface FetchSuccessArticlesPayload {
  count: number;
  items: Article[];
}

export interface ArticlesFilter {
  itemGroup: FilterItem[],
  productGroup: FilterItem[],
  businessArea: FilterItem[],
  size: FilterItem[],
  status: string[]
}

export type ArticleAction =
  | ArticleActionType<typeof ArticleActions.FETCH_ARTICLES, RequestPayload>
  | ArticleActionType<typeof ArticleActions.FETCH_ARTICLES_SUCCESS, ResponsePayload<FetchSuccessArticlesPayload>>
  | ArticleActionType<typeof ArticleActions.FETCH_MORE_ARTICLES, RequestPayload>
  | ArticleActionType<typeof ArticleActions.FETCH_MORE_ARTICLES_SUCCESS, ResponsePayload<FetchSuccessArticlesPayload>>
  | ArticleActionType<typeof ArticleActions.FETCH_FILTER, RequestPayload>
  | ArticleActionType<typeof ArticleActions.FETCH_FILTER_SUCCESS, ResponsePayload<ArticlesFilter>>
  | ArticleActionType<typeof ArticleActions.SELECT_ARTICLES, Article[]>