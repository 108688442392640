import * as ProcessActions from '../../actions/process';
import { ArticleAccordion } from './components/article-accordion';
import { ArticleSelection } from './article-selection';
import { Confirmation } from './confirmation';
import { CustomerDetailsSelection } from '../../components/complex/customer-details-section';
import { CustomerSelection } from './customer-selection';
import { EmptyCard } from './components/empty-card';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton } from '@material-ui/core';
import { InfoIcon } from '../../components/core/icons';
import { RootState } from '../../reducers';
import { SimulationCard } from './components/simulation.card';
import { WikusButton, WikusButtonRow } from '../../components/core/button';
import { WikusCustomerCard } from '../../components/complex/customer-card';
import { WikusItem, WikusSection } from '../../components/core/spacing';
import { WikusTextField } from '../../components/core/input';
import { useActions } from '../../actions';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import WikusPageLayout from '../../components/PageLayout';

export type NewProcessPageType = 'new' | 'edit' | 'simulation';

export interface NewProcessPageProps {
  type: NewProcessPageType
}

export const NewProcessPage = ({ type }: NewProcessPageProps) => {
  const [showCustomerSelection, setShowCustomerSelection] = useState(false);
  const [showArticleSelection, setShowArticleSelection] = useState(false);
  const [showCustomerDetailsSection, setShowCustomerDetailsSection] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const customer = useSelector((state: RootState) => state.selectedCustomer);
  const newProcess = useSelector((state: RootState) => state.newProcess);
  const processActions: typeof ProcessActions = useActions(ProcessActions);
  const intl = useIntl();

  const isEdit = type === 'edit';
  const isSimulation = type === 'simulation';
  const title = intl.formatMessage({ id: `new-process.${type}.title` });

  const selectCustomerSelection = () => {
    setShowCustomerSelection(true);
    setTimeout(() => {
      setShowCustomerDetailsSection(false);
    }, 500);
  };

  const closeCustomerSelection = () => {
    setShowCustomerSelection(false);
  };

  const selectArticleSelection = () => {
    setShowArticleSelection(true);
  };

  const closeArticleSelection = () => {
    setShowArticleSelection(false);
  };

  const selectCustomerDetailsSection = () => {
    setShowCustomerDetailsSection(true);
  };

  const closeCustomerDetailsSection = () => {
    setShowCustomerDetailsSection(false);
  };

  const closeConfirmation = () => {
    setShowConfirmation(false);
  }

  const customerContent = customer ? (
    <WikusCustomerCard
      customer={customer}
      size="small"
      actionItem={
        <IconButton onClick={selectCustomerDetailsSection}>
          <InfoIcon />
        </IconButton>
      }
      showButtons={false}
    />
  ) : (
    <EmptyCard
      name="customer"
      onClick={selectCustomerSelection}
    ></EmptyCard>
  );
  const simulationContent = <SimulationCard />

  return (
    <WikusPageLayout
      title={title}
      heroChildren={<WikusTextField
        name="name"
        label={intl.formatMessage({ id: 'process.name.title' })}
        value={newProcess?.name}
        onChange={({ target: { value } }) => processActions.setName(value)}
      />
      }>
      <WikusSection spacingDirection="down">
        {
          isSimulation
            ? simulationContent
            : customerContent
        }

        {newProcess?.articles?.length ? (
          <WikusSection spacingDirection="up">
            <ArticleAccordion
              canShowContent={!!newProcess?.customer}
              articles={newProcess?.articles}
              customer={customer} />
          </WikusSection>
        ) : (
          <WikusItem spacingDirection="up">
            <EmptyCard name="article" onClick={selectArticleSelection} />
          </WikusItem>
        )}
      </WikusSection>

      {!isSimulation && <WikusButtonRow>
        <WikusButton
          onClick={selectArticleSelection}
          color="primary"
          variant="outlined"
        >
          <FormattedMessage id="new-process.add-articles" />
        </WikusButton>
        <WikusButton
          onClick={() => setShowConfirmation(true)}
          disabled={!newProcess?.articles?.length || newProcess.articles?.some(a => !a.isValid) || !newProcess.userSelectionComplete}
          color="secondary">
          <FormattedMessage id="finish" />
        </WikusButton>
      </WikusButtonRow>}

      <CustomerSelection
        open={showCustomerSelection}
        onClose={closeCustomerSelection}
      ></CustomerSelection>
      <ArticleSelection
        open={showArticleSelection}
        onClose={closeArticleSelection}
      ></ArticleSelection>
      {customer && <CustomerDetailsSelection
        customer={customer}
        open={showCustomerDetailsSection}
        onClose={closeCustomerDetailsSection}
        onSelect={isEdit ? undefined : selectCustomerSelection}
      ></CustomerDetailsSelection>}
      <Confirmation type={type} open={showConfirmation} onClose={closeConfirmation} />
    </WikusPageLayout>
  );
};
