import { ProcessPageContext } from '../context';
import { WikusFilter, WikusFilterOptionChangeHandler, WikusFilterOptionType } from '../../../components/complex/filter';
import { useContext } from 'react';
import { useFilter } from '../../../hooks/useFilter';
import { useIntl } from 'react-intl';

export interface ProcessFilterProps {
  onChange?: WikusFilterOptionChangeHandler;
}

export const ProcessFilter = ({ onChange }: ProcessFilterProps) => {
  const intl = useIntl();
  const { actions, name } = useContext(ProcessPageContext);
  const { isLoading, filter } = useFilter<typeof actions>(actions, [{
    type: WikusFilterOptionType.text,
    props: {
      name: 'customerNumber',
    },
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'customerName',
    },
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'date',
      type: 'date',
      inputProps: {
        min: '2021-01-01',
      },
    } as any,
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'number',
    },
  }, {
    type: WikusFilterOptionType.bool,
    props: {
      name: 'isEscalation',
    },
  }], 'process');

  const filterTitle = intl.formatMessage({
    id: `${name}.filter.title`,
  });
  const filterDescription = intl.formatMessage({
    id: `${name}.filter.description`,
  });

  return (
    <WikusFilter
      title={filterTitle}
      description={filterDescription}
      isLoading={isLoading}
      options={filter}
      order={['customerName', 'customerNumber', 'date', 'isEscalation', 'status', 'number', 'customerGroup', 'salesManagerId']}
      onChange={onChange} />
  );
}