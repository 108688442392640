import * as ProcessActions from '../../../../actions/process';
import { TArticleContentProps } from './';
import { WikusDetailBlock } from '../../../../components/complex/detail-block';
import { WikusInputRow } from '../../../../components/core/input';
import { WikusItem } from '../../../../components/core/spacing';
import { WikusMaskedNumberTextField } from '../../../../components/core/masked-input';
import { WikusSwitch } from '../../../../components/core/switch';
import { getArticleDetailRowItems } from '../../../../components/utils/article-details';
import { useActions } from '../../../../actions';
import { useIntl } from 'react-intl';

export const ArticleContentDetails = ({ article }: TArticleContentProps) => {
  const intl = useIntl();
  const processActions: typeof ProcessActions = useActions(ProcessActions);
  const title = intl.formatMessage({
    id: 'article.productGroupInformation.label',
  });
  const lengthInMMLabel = intl.formatMessage({
    id: 'article.lengthInMM.label',
  });
  const withWeldLabel = intl.formatMessage({
    id: 'article.withWeld.label',
  });

  const setWithWeld = (_: any, withWeld: boolean) => {
    processActions.setWithWeld(article.article.id, withWeld);
  }

  const setLength = (length: any) => {
    processActions.setLengthInMeters(article.article.id, length / 1000);
  }

  const { items } = getArticleDetailRowItems(intl, article.article, 'large');
  const isFamily = article.type !== 'variant';

  return (
    <>
      <WikusDetailBlock title={title} items={items} variant="equal-centered" />
      {
        isFamily && <WikusItem spacingDirection="up">
          <WikusInputRow>
            <WikusMaskedNumberTextField
              runWithValue={setLength}
              value={article.lengthInMeters * 1000}
              name={`${article.article.id}-length`}
              label={lengthInMMLabel}
              xs={6}
              disabled={article.type === 'family-without-weld'} />
            <WikusSwitch
              onChange={setWithWeld}
              checked={article.withWeld}
              name={`${article.article.id}-withWeld`}
              label={withWeldLabel}
              color="primary"
              xs={6} />
          </WikusInputRow>
        </WikusItem>
      }
    </>
  );
};
