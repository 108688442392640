import { Box, Theme } from '@material-ui/core';
import { WikusButtonRow } from '../core/button';
import { WikusItem, WikusSection } from '../core/spacing';
import { withStyles } from '@material-ui/styles';

import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import MuiAccordionActions from '@material-ui/core/AccordionActions';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

const Accordion = withStyles((theme: Theme) => ({
  root: {
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    marginTop: '0 !important',
  },
}))(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  content: {
    margin: 0,
  },
  expanded: {
    margin: '0 0 !important',
    minHeight: '0 !important',
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'block',
  },
}))(MuiAccordionDetails);

const AccordionActions = withStyles((theme) => ({
  root: {},
}))(MuiAccordionActions);

export type TWikusAccordionProps = AccordionProps & {
  children: React.ReactNode;
  buttons?: React.ReactNode[];
  summary: React.ReactNode;
};

export const WikusAccordion = ({ summary, buttons, children, ...props }: TWikusAccordionProps) => {
  return (
    <Accordion
      {...props}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary>
        <Box width="100%">
          {summary}
        </Box>
      </AccordionSummary>
      <WikusSection spacingDirection="horizontal">
        <WikusSection spacingDirection="down">
          <WikusItem spacingDirection="up">
            <AccordionDetails>{children}</AccordionDetails>
            {buttons && (
              <WikusSection spacingDirection="up">
                <WikusButtonRow>
                  {buttons.map((button, index) => (
                    <Box key={index}>{button}</Box>
                  ))}
                </WikusButtonRow>
              </WikusSection>
            )}
          </WikusItem>
        </WikusSection>
      </WikusSection>
    </Accordion>
  );
};
