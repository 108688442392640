import * as ProcessActions from '../../actions/process';
import * as ProcessDraftActions from '../../actions/process-draft';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProcessAccordion } from './components/process-accordion';
import { ProcessFilter } from './components/process-filter';
import { ProcessPageContext } from './context';
import { RootState } from '../../reducers';
import { Routes } from '../../routes';
import { WikusButton } from '../../components/core/button';
import { WikusInfiniteScroll } from '../../components/complex/infinite-scroll';
import { WikusSection } from '../../components/core/spacing';
import { WikusUnstyledLink } from '../../components/core/link';
import { useActions } from '../../actions';
import { useContext, useEffect, useState } from 'react';
import { usePaginate } from '../../hooks/usePaginate';
import { useQuery } from '../../hooks/useQueryParams';
import { useSelector } from 'react-redux';
import WikusPageLayout from '../../components/PageLayout';

export const MyProcessesPage = ({ ...props }: any) => {
  const query = useQuery();
  const ProcessesPerPage = 20;
  const intl = useIntl();
  const { actions, name, proccessesSelector, countSelector } = useContext(ProcessPageContext);
  const processes = useSelector(proccessesSelector);
  const processesCount = useSelector(countSelector);
  const filterParams = query.get('id') ? { id: query.get('id') } : {};
  const [filters, setFilters] = useState(filterParams);

  const { fetchMore, isLoading: isPaginationLoading } = usePaginate<typeof actions>({
    itemsPerPage: ProcessesPerPage,
    actions,
    filters,
  });

  const processActions: typeof ProcessActions = useActions(ProcessActions);
  useEffect(() => {
    processActions.clearNewProcess();
  }, []);

  return (
    <WikusPageLayout
      title={intl.formatMessage({ id: `${name}.title` })}
      heroChildren={<WikusUnstyledLink to={Routes.newProcess}>
        <WikusButton variant="outlined" color="primary">
          <FormattedMessage id={`${name}.new`} />
        </WikusButton>
      </WikusUnstyledLink>}>
      <WikusSection spacingDirection="down">
        <ProcessFilter onChange={setFilters} />
      </WikusSection>
      <WikusInfiniteScroll
        skeletonHeight={100}
        skeletonCount={ProcessesPerPage}
        dataLength={processes?.length}
        next={fetchMore}
        hasMore={!processesCount || processesCount > processes?.length}
        isLoading={isPaginationLoading}
      >
        <ProcessAccordion processes={processes} />
      </WikusInfiniteScroll>
    </WikusPageLayout>
  );
};

export const MyDraftsPage = ({ ...props }: any) => {
  return <ProcessPageContext.Provider value={{
    name: 'my-drafts',
    actions: ProcessDraftActions,
    editRoute: Routes.newProcess,
    proccessesSelector: (state: RootState) => state.processDrafts,
    countSelector: (state: RootState) => state.processDraftsCount,
  }}>
    <MyProcessesPage {...props} />
  </ProcessPageContext.Provider>
}