import * as CustomerActions from '../../actions/customer';
import { CustomersFilter } from '../../model';
import { FilterItemObject } from '../../model/misc';
import { InputDefaultGroup } from '../../model/input';
import { RequiredAutocomplete } from '../utils/validators';
import { RootState } from '../../reducers';
import { Skeleton } from '@material-ui/lab';
import { WikusAutocomplete } from '../core/autocomlete';
import { WikusInputRow } from '../core/input';
import { WikusItem } from '../core/spacing';
import { find, isString, pick, some } from 'lodash';
import { useActions } from '../../actions';
import { useAutocomplete } from '../../hooks/useAutocomplete';
import { useCallback, useEffect, useState } from 'react';
import { useInputGroup } from '../../hooks/useInputGroup';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export type OnPricingInputGroupChangedHandler = (event: {
  priceRegion?: string;
  country?: string;
  areaId?: string;
  category?: string;
  classification?: string;
  discount?: number;
}) => void;

export interface PricingInputGroupProps {
  group?: string;
  submitted?: boolean;
  useCountry?: boolean;
  countryCode?: string;
  autofill?: boolean;
  onChange?: OnPricingInputGroupChangedHandler;
}

const WikusPricingInputGroup = ({ group, submitted, countryCode, autofill, useCountry, onChange }: PricingInputGroupProps) => {
  const intl = useIntl();
  const [isCountryMissing, setIsCountryMissing] = useState(autofill && !countryCode);
  const [isPriceRegionLocked, setIsPriceRegionLocked] = useState(false);
  const [customClassificationOptions, setCustomClassificationOptions] = useState<FilterItemObject[] | undefined>(undefined);
  const [isCategoryMissing, setIsCategoryMissing] = useState(!!autofill);
  const customerFilter = useSelector((rootState: RootState) => rootState.customersFilter);
  const { value, stateChange, getValue } = useInputGroup(group || InputDefaultGroup);
  const customerActions: typeof CustomerActions = useActions(CustomerActions);
  const { isLoading, createAutocompleteProps } = useAutocomplete<typeof CustomerActions, CustomersFilter>(CustomerActions, 'customer');

  const createProps = useCallback((name: keyof CustomersFilter, optionsFilter?) => ({
    ...createAutocompleteProps(name, RequiredAutocomplete, optionsFilter),
    value: getValue(name),
  }), [createAutocompleteProps, getValue]);

  useEffect(() => {
    let discount = useCountry
      ? (value?.country?.decimal || 0)
      : (value?.priceRegion?.decimal || 0);
    const categoryId = value?.category?.id;
    const classificationId = value?.classification?.id;

    if (categoryId && classificationId) {
      const discountItem = find(customerFilter?.categoryClassification, cc => {
        if (!isString(cc)) {
          return cc.id === `${categoryId || ''}_${classificationId || ''}`;
        }

        return false;
      });
      discount += (discountItem as FilterItemObject)?.decimal || 0;
    }

    stateChange('discount', discount, true);
    onChange && onChange({
      discount,
      ...pick(value, ['priceRegion', 'category', 'classification', 'country']),
    });
  }, [
    value?.priceRegion,
    value?.category,
    value?.classification,
    value?.country
  ])

  useEffect(() => {
    setIsCountryMissing(autofill && !countryCode);
    async function fetchPriceRegion() {
      const { payload }: any = await customerActions.findPriceRegionByCountry(countryCode as string);
      const data: FilterItemObject | undefined = payload?.data;

      if (data) {
        stateChange('priceRegion', data, true);
        setIsPriceRegionLocked(true);
      }
    }

    if (autofill && countryCode) {
      fetchPriceRegion();
    }
  }, [autofill, countryCode])

  useEffect(() => {
    setIsCategoryMissing(!!autofill && !value?.category);
    async function fetchClassifications() {
      const { payload }: any = await customerActions.findClassificationsByCategory(value.category.id as string);
      const data: FilterItemObject[] | undefined = payload?.data;
      setCustomClassificationOptions(data);
      setIsCategoryMissing(false);
    }

    if (autofill && value?.category?.id) {
      fetchClassifications()
    }
  }, [autofill, value?.category?.id])

  const classificationOptionsFilter = useCallback((option) => {
    if (!customClassificationOptions) {
      return true;
    }

    return some(customClassificationOptions, co => co.id === option.id);
  }, [customClassificationOptions])

  if (isLoading) {
    return (
      <WikusItem spacingDirection="down">
        <Skeleton variant="rect" height={48} width="100%" />
      </WikusItem>
    );
  }

  return (
    <WikusInputRow>
      {useCountry
        ? <WikusAutocomplete
          group={group}
          submitted={submitted}
          xs={4}
          {...createProps('country')} />
        : <WikusAutocomplete
          group={group}
          submitted={submitted}
          helperText={(isCountryMissing && intl.formatMessage({
            id: 'helper.is-country-missing',
          })) || undefined}
          disabled={isCountryMissing || isPriceRegionLocked}
          xs={4}
          {...createProps('priceRegion')} />
      }
      <WikusAutocomplete
        group={group}
        submitted={submitted}
        xs={4}
        {...createProps('category')} />
      <WikusAutocomplete
        group={group}
        submitted={submitted}
        helperText={(isCategoryMissing && intl.formatMessage({
          id: 'helper.is-category-missing',
        })) || undefined}
        disabled={isCategoryMissing}
        xs={4}
        {...createProps('classification', classificationOptionsFilter)} />
    </WikusInputRow>
  );
}
WikusPricingInputGroup.displayName = 'WikusPricingInputGroup';

export {
  WikusPricingInputGroup
};