import * as UserActions from '../../actions/user';
import { RootState } from '../../reducers';
import { Skeleton } from '@material-ui/lab';
import { WikusAutocomplete, WikusAutocompleteProps } from '../core/autocomlete';
import { useActions } from '../../actions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const RoleAutocomplete = (props: Omit<WikusAutocompleteProps, 'options'>) => {
  const roles = useSelector((rootState: RootState) => rootState.roles);

  const userActions: typeof UserActions = useActions(
    UserActions
  );

  useEffect(() => {
    userActions.fetchRoles();
  }, []);

  if (!roles) {
    return (
      <Skeleton variant="rect" height={48} width="100%" />
    );
  }

  return (
    <WikusAutocomplete {...props} options={roles} />
  )
}