import * as FeedbackActions from '../../actions/feedback';
import { Feedback } from '../../model';
import { FeedbackAccordion } from './components/feedback-accordion';
import { FeedbackActions as FeedbackActionsModel } from '../../model/feedback';
import { FeedbackFilter } from './components/feedback-filter';
import { OnSubmitHandler, useInputGroup } from '../../hooks/useInputGroup';
import { Required } from '../../components/utils/validators';
import { RootState } from '../../reducers';
import { WikusButton, WikusButtonRow } from '../../components/core/button';
import { WikusFilterValue } from '../../components/complex/filter';
import { WikusInfiniteScroll } from '../../components/complex/infinite-scroll';
import { WikusInputRow, WikusTextField } from '../../components/core/input';
import { WikusPanel } from '../../components/core/card';
import { WikusSection } from '../../components/core/spacing';
import { useActions } from '../../actions';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePaginate } from '../../hooks/usePaginate';
import { useSelector } from 'react-redux';
import WikusPageLayout from '../../components/PageLayout';

export interface FilterListProps {
  filters?: WikusFilterValue;
}

export const FeedbackList = ({ filters = {} }: FilterListProps) => {
  const { fetchMore, isLoading } = usePaginate<typeof FeedbackActions>({
    actions: FeedbackActions,
    filters,
  });

  const feedback: Feedback[] = useSelector(
    (state: RootState) => state.feedback
  );
  const feedbackCount: number = useSelector(
    (state: RootState) => state.feedbackCount
  );

  return (
    <WikusInfiniteScroll
      skeletonHeight={114}
      skeletonCount={10}
      dataLength={feedback?.length}
      next={fetchMore}
      hasMore={feedbackCount > feedback?.length}
      isLoading={isLoading}
    >
      <FeedbackAccordion feedback={feedback} />
    </WikusInfiniteScroll>
  );
}

export const FeedbackHeader = () => {
  const FeedbackInputGroup = 'feedback-header';
  const intl = useIntl();
  const feedbackActions: typeof FeedbackActions = useActions(
    FeedbackActions
  );

  const _onSubmit: OnSubmitHandler = async () => {
    const { type } = await feedbackActions.createFeedback(value);
    clear();
    return type === FeedbackActionsModel.CREATE_FEEDBACK_SUCCESS;
  }
  const { value, isValid, getValue, isSubmitting, onSubmit, clear } = useInputGroup(FeedbackInputGroup, _onSubmit);

  const title = intl.formatMessage({
    id: 'feedback-header.title',
  });
  const description = intl.formatMessage({
    id: 'feedback-header.description',
  });

  const createInputProps = useCallback((name: string, validator = Required) => {
    return {
      group: FeedbackInputGroup,
      label: intl.formatMessage({ id: `feedback-header.${name}.label` }),
      name,
      validator,
      value: getValue(name),
    }
  }, [intl, getValue]);

  return (
    <WikusPanel
      title={title}
      description={description}>
      <form onSubmit={onSubmit}>
        <WikusInputRow>
          <WikusTextField {...createInputProps('title')} />
        </WikusInputRow>
        <WikusInputRow>
          <WikusTextField
            {...createInputProps('description')}
            multiline={true}
            minRows={4}
            maxRows={4} />
        </WikusInputRow>
        <WikusButtonRow>
          <WikusButton
            type="submit"
            color="secondary"
            disabled={!isValid || isSubmitting}>
            {intl.formatMessage({
              id: 'feedback-header.submit',
            })}
          </WikusButton>
        </WikusButtonRow>
      </form>
    </WikusPanel>
  )
}

export const FeedbackPage = ({ ...props }) => {
  const intl = useIntl();
  const [filters, setFilters] = useState({});

  return (
    <WikusPageLayout title={intl.formatMessage({ id: 'feedback.title' })}>
      <WikusSection spacingDirection="down">
        <FeedbackHeader />
      </WikusSection>
      <WikusSection spacingDirection="down">
        <FeedbackFilter onChange={setFilters} />
      </WikusSection>
      <FeedbackList filters={filters} />
    </WikusPageLayout>
  );
}