import { ButtonProps, CircularProgress, Grid, GridProps } from '@material-ui/core';
import { ReactNode } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = (theme: any) => ({
  outlined: {
    borderRadius: 0,
    borderWidth: '3px',
    '&$outlinedPrimary': {
      borderWidth: '3px',
    },
    '&$outlinedSecondary': {
      borderWidth: '3px',
    },
  },
  outlinedPrimary: {
    '&$outlinedPrimary': {
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedSecondary: {
    '&$outlinedSecondary': {
      borderColor: theme.palette.secondary.main,
    },
  },
});

export interface WikusButtonProps extends ButtonProps {
  loading?: boolean;
}

const WikusButton = withStyles(styles)(({ classes, loading, ...props }: WikusButtonProps) => (<Button
  variant="contained"
  classes={{
    outlined: classes?.outlined,
    outlinedPrimary: classes?.outlinedPrimary,
    outlinedSecondary: classes?.outlinedSecondary,
  }}
  {...props}
  endIcon={loading && <CircularProgress style={{ width: '25px', height: '25px' }} color="inherit" />}
  disabled={props.disabled || loading} />));
// set for storybook to render html preview correctly
WikusButton.displayName = 'WikusButton';

export interface ButtonWithIconProps extends ButtonProps {
  icon: any
}

const WikusButtonWithIcon = withStyles(styles)(({ classes, icon, ...props }: ButtonWithIconProps) => {
  const ButtonIcon: any = icon;
  return (<WikusButton
    {...props}
    variant="outlined">
    {<ButtonIcon style={{ marginRight: 5, marginTop: -2 }} color={props.color} fontSize="small" />}
    {props.children}
  </WikusButton>);
});
// set for storybook to render html preview correctly
WikusButtonWithIcon.displayName = 'WikusButtonWithIcon';

export interface WikusButtonRowProps extends GridProps {
  children: ReactNode | ReactNode[]
};

const WikusButtonRow = ({ children, ...props }: WikusButtonRowProps) => (
  <Grid
    {...props}
    container
    direction="row"
    justifyContent="flex-end"
    wrap="nowrap"
    spacing={2}>
    {Array.isArray(children)
      ? children.map((child, index) => (
        <Grid key={index} item>
          {child}
        </Grid>
      ))
      : (children && <Grid item>
        {children}
      </Grid>)
    }
  </Grid>
);
WikusButtonRow.displayName = 'WikusButtonRow';

export {
  WikusButton,
  WikusButtonWithIcon,
  WikusButtonRow
};