import { isUndefined } from 'lodash';
import { useActions } from '../../actions';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues
} from 'react-number-format';

import * as InputActions from '../../actions/input';
import { Theme, makeStyles } from '@material-ui/core';
import { WikusTextField, WikusTextFieldProps } from './input';

type TFloatValueFormatter = (value: number) => number;
type TRunWithValue = (value: string | number) => void;

export type TWikusMaskedTextFieldProps = Omit<WikusTextFieldProps, 'group'> &
  NumberFormatProps & {
    group?: string;
    floatValueFormatter?: TFloatValueFormatter;
    runWithValue?: TRunWithValue;
    maxValue?: number;
    onPlaceholderResult?: () => void;
  };

const useTextFieldStyles = makeStyles((theme: Theme) => ({
  helperText: {
    backgroundColor: theme.palette.grey[200],
    margin: 0,
    paddingTop: theme.spacing(1),
  },
}));

const WikusMaskedTextField = ({
  floatValueFormatter = (value: number) => value,
  runWithValue,
  onPlaceholderResult,
  maxValue,
  ...props
}: TWikusMaskedTextFieldProps) => {
  const classes = useTextFieldStyles();

  const [values, setValues] = useState<NumberFormatValues>();
  const inputActions: typeof InputActions = useActions(InputActions);

  const onBlur = useCallback(() => {
    if (!values) {
      return;
    }

    let preparedValue = !isUndefined(values?.floatValue)
      ? floatValueFormatter(values.floatValue)
      : 'placeholder';

    if (maxValue && preparedValue > maxValue) {
      preparedValue = maxValue;
    }

    if (preparedValue === 'placeholder' && onPlaceholderResult) {
      onPlaceholderResult();
    }

    inputActions.stateChange(props.name, preparedValue, true, props.group);

    // run only if we have valid values, might want to change if we want to update ui
    if (!isUndefined(values?.floatValue)) {
      runWithValue && runWithValue(preparedValue);
    }
  }, [values, runWithValue, floatValueFormatter, inputActions, props.name, props.group]);

  const onValueChange = (values: NumberFormatValues) => {
    setValues(values);
    props.onValueChange && props.onValueChange(values);
  }

  useEffect(() => {
    setValues(undefined);
    onBlur();
  }, [props.value]);

  const onKeyDown = (event: any, ...rest: any[]) => {
    if (event.keyCode === 13) {
      onBlur();
    }
    props.onKeyDown && props.onKeyDown(event);
  }

  return (
    // muss ignorieren, react-number-format beachtet bis jetzt noch nicht die Typings von customInput Components, ist aber in der mache
    // @ts-ignore
    <NumberFormat
      {...props}
      customInput={WikusTextField}
      isNumericString
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      value={values ? values.value : props.value}
      onValueChange={onValueChange}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
    />
  );
};

const WikusMaskedPercentageTextField = ({
  value,
  runWithValue,
  ...props
}: TWikusMaskedTextFieldProps) => {
  return (
    // @ts-ignore
    <WikusMaskedTextField
      decimalSeparator=","
      decimalScale={(value as any) >= 100 ? 0 : 2}
      allowLeadingZeros={false}
      allowNegative
      suffix=" %"
      value={(value as any) * 100}
      floatValueFormatter={(value) => value / 100}
      runWithValue={runWithValue}
      {...props}
    />
  );
};

const WikusMaskedCurrencyTextField = ({
  value,
  runWithValue,
  ...props
}: TWikusMaskedTextFieldProps) => {
  const intl = useIntl();

  return (
    // @ts-ignore
    <WikusMaskedTextField
      decimalSeparator=","
      decimalScale={2}
      thousandSeparator="."
      allowLeadingZeros={true}
      suffix={' ' + intl.formatMessage({ id: 'currency.symbol' })}
      value={((value as any) / 100).toFixed(2)}
      floatValueFormatter={(value) => value * 100}
      runWithValue={runWithValue}
      {...props}
    />
  );
};

const WikusMaskedNumberTextField = ({
  value,
  runWithValue,
  ...props
}: TWikusMaskedTextFieldProps) => {
  // or make options props?
  return (
    // @ts-ignore
    <WikusMaskedTextField
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={0}
      allowLeadingZeros={false}
      value={value as any}
      runWithValue={runWithValue}
      {...props}
    />
  );
};

export {
  WikusMaskedTextField,
  WikusMaskedPercentageTextField,
  WikusMaskedCurrencyTextField,
  WikusMaskedNumberTextField
};
