import * as SnackbarEventAction from '../../../actions/snackbarEvent';
import { AccordionClose, AccordionOpen } from '../../../components/core/icons';
import { FormEventHandler, useCallback, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton } from '@material-ui/core';
import { Process } from '../../../model';
import { ProcessActions as ProcessActionsModel } from '../../../model/process';
import { ProcessContent } from './process-content';
import { ProcessPageContext } from '../context';
import { WikusAccordion } from '../../../components/complex/accordion';
import { WikusButton } from '../../../components/core/button';
import { WikusItem } from '../../../components/core/spacing';
import { WikusProcessCard } from '../../../components/complex/process-card';
import { WikusTypography } from '../../../components/core/typography';
import { formatDate } from '../../../utils';
import { groupBy, map, some } from 'lodash';
import { useAccordion } from '../../../hooks/useAccordion';
import { useActions } from '../../../actions';
import { useHistory } from 'react-router-dom';
import { useProcessesInputGroup } from '../../../hooks/useInputGroup';

export interface ProcessAcordionProps {
  processes: Process[];
}

export const ProcessAccordion = ({ processes }: ProcessAcordionProps) => {
  const intl = useIntl();
  const history = useHistory();
  const [loadingId, setLoadingId] = useState<number>();
  const idFn = useCallback((process: Process) => process.id, []);
  const {
    toggle,
    isSelected,
    createItemContainer,
  } = useAccordion<Process>(processes, idFn, {
    openFirst: processes.length === 1,
    canScroll: true,
  });
  const { actions, editRoute } = useContext(ProcessPageContext);
  const processActions = useActions(actions);
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );
  const { getGroup, setSubmitted } = useProcessesInputGroup();

  const editProcess = async (process: Process) => {
    await processActions.editProcess(process);
    history.push(editRoute);
  }

  const onSubmit: (process: Process) => FormEventHandler<HTMLFormElement> = (process: Process) => async (event) => {
    event.preventDefault();

    if (!processActions.patchProcess) {
      return;
    }

    const { value, isValid } = getGroup(process);
    setSubmitted(process, true);

    if (isValid) {
      setLoadingId(process.id);
      const { type } = await processActions.patchProcess(process.id, value);
      setLoadingId(undefined);

      if (type === ProcessActionsModel.PATCH_PROCESS_FAIL) {
        snackbarActions.addSnackbarEvent({
          message: intl.formatMessage({
            id: 'errors.patch-process',
          }),
          severity: 'error',
        });
      } else {
        snackbarActions.addSnackbarEvent({
          message: intl.formatMessage({
            id: 'success.patch-process',
          }),
          severity: 'success',
        });
      }
    }
  }

  const getButtons = (process: Process) => {
    const buttons = [];

    if (process.status === 'open') {
      buttons.push(<WikusButton
        color="primary"
        variant="outlined"
        onClick={() => editProcess(process)}
      >
        <FormattedMessage id="edit" />
      </WikusButton>);
    }

    if (!process.customer?.customerNumber && !!processActions.patchProcess) {
      buttons.push(
        <WikusButton
          type="submit"
          color="secondary"
          loading={process.id === loadingId}>
          <FormattedMessage id="save" />
        </WikusButton>
      )
    }

    return buttons;
  }

  const getStatus = (process: Process) => {
    if (process.status === 'approved') {
      return 'success';
    }

    if (process.status === 'rejected') {
      return 'error';
    }
  }

  const processGroups = groupBy(processes, p => formatDate(intl, p.date));

  return (
    <>
      {map(processGroups, (currentProcesses, date) => (
        <div key={date}>
          <WikusTypography gutterBottom variant="h3">
            {date}
          </WikusTypography>
          {currentProcesses?.map((process, index) => (
            <WikusItem {...createItemContainer(process)} spacingDirection="down" key={index}>
              <form onSubmit={onSubmit(process)}>
                <WikusAccordion
                  expanded={isSelected(process)}
                  onChange={() => toggle(process)}
                  buttons={getButtons(process)}
                  summary={
                    <WikusProcessCard
                      process={process}
                      isEscalation={some(process.articles, a => a.isEscalation)}
                      status={getStatus(process)}
                      actionItem={
                        <IconButton>
                          {isSelected(process) ? (
                            <AccordionClose />
                          ) : (
                            <AccordionOpen />
                          )}
                        </IconButton>
                      }
                      size="small"
                      elevation={0}
                    />
                  }
                >
                  <ProcessContent process={process} />
                </WikusAccordion>
              </form>
            </WikusItem>
          ))}
        </div>
      ))}
    </>
  )
}