import { RequestPayload, ResponsePayload } from './request';
import { User } from './user';

export enum FeedbackActions {
  FETCH_FEEDBACK = 'FETCH_FEEDBACK',
  FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS',
  FETCH_FILTER = 'FETCH_FILTER',
  FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS',
  FETCH_MORE_FEEDBACK = 'FETCH_MORE_FEEDBACK',
  FETCH_MORE_FEEDBACK_SUCCESS = 'FETCH_MORE_FEEDBACK_SUCCESS',
  CREATE_FEEDBACK = 'CREATE_FEEDBACK',
  CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS',
  CREATE_FEEDBACK_ERROR = 'CREATE_FEEDBACK_ERROR',
  PATCH_FEEDBACK = 'PATCH_FEEDBACK',
  PATCH_FEEDBACK_SUCCESS = 'PATCH_FEEDBACK_SUCCESS',
  DELETE_FEEDBACK = 'DELETE_FEEDBACK',
  DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS',
  TOGGLE_UPVOTE = 'TOGGLE_UPVOTE',
  TOGGLE_UPVOTE_SUCCESS = 'TOGGLE_UPVOTE_SUCCESS'
}

interface FeedbackActionType<T, P> {
  type: T;
  payload: P;
  meta?: any;
}

export interface FeedbackUpvote {
  createdAt: string;
  user?: User;
}

export enum FeedbackStatus {
  Open = 'open',
  InProgress = 'in-progress',
  Done = 'done',
  Declined = 'declined'
}

export interface Feedback {
  id: number;
  title: string;
  description: string;
  upvotes: FeedbackUpvote[];
  user?: User;
  createdAt: string;
  status: FeedbackStatus;
}

export interface FeedbackQueryParams {
  take?: number;
  skip?: number;
  status?: string;
  createdAt?: string;
}

export interface FetchSuccessFeedbackPayload {
  items: Feedback[];
  count: number;
}

export interface DeleteSuccessFeedbackPayload {
  id: number;
}

export interface PostFeedbackPayload {
  title: string;
  description: string;
}

export interface FeedbackFilter {
  status: string[]
}

export type FeedbackAction =
  | FeedbackActionType<typeof FeedbackActions.FETCH_FEEDBACK, RequestPayload>
  | FeedbackActionType<typeof FeedbackActions.FETCH_FEEDBACK_SUCCESS, ResponsePayload<FetchSuccessFeedbackPayload>>
  | FeedbackActionType<typeof FeedbackActions.FETCH_FILTER, RequestPayload>
  | FeedbackActionType<typeof FeedbackActions.FETCH_FILTER_SUCCESS, ResponsePayload<FeedbackFilter>>
  | FeedbackActionType<typeof FeedbackActions.FETCH_MORE_FEEDBACK, RequestPayload>
  | FeedbackActionType<typeof FeedbackActions.FETCH_MORE_FEEDBACK_SUCCESS, ResponsePayload<FetchSuccessFeedbackPayload>>
  | FeedbackActionType<typeof FeedbackActions.CREATE_FEEDBACK, RequestPayload>
  | FeedbackActionType<typeof FeedbackActions.CREATE_FEEDBACK_SUCCESS, ResponsePayload<Feedback>>
  | FeedbackActionType<typeof FeedbackActions.PATCH_FEEDBACK, RequestPayload>
  | FeedbackActionType<typeof FeedbackActions.PATCH_FEEDBACK_SUCCESS, ResponsePayload<Feedback>>
  | FeedbackActionType<typeof FeedbackActions.DELETE_FEEDBACK, RequestPayload>
  | FeedbackActionType<typeof FeedbackActions.DELETE_FEEDBACK_SUCCESS, ResponsePayload<DeleteSuccessFeedbackPayload>>
  | FeedbackActionType<typeof FeedbackActions.TOGGLE_UPVOTE, RequestPayload>
  | FeedbackActionType<typeof FeedbackActions.TOGGLE_UPVOTE_SUCCESS, ResponsePayload<Feedback>>