import { FilterItemObject } from './misc';
import { RequestPayload, ResponsePayload } from './request';

export enum UserActions {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
  FETCH_USERS = 'FETCH_USERS',
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  LOGOUT = 'LOGOUT',
  CREATE_USER = 'CREATE_USER',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD',
  UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  FETCH_ROLES = 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS',
  FETCH_BONUS_RECIPIENTS = 'FETCH_BONUS_RECIPIENTS',
  FETCH_BONUS_RECIPIENTS_SUCCESS = 'FETCH_BONUS_RECIPIENTS_SUCCESS',
  TOGGLE_USER_ACTIVE = 'TOGGLE_USER_ACTIVE',
  TOGGLE_USER_ACTIVE_SUCCESS = 'TOGGLE_USER_ACTIVE_SUCCESS',
  FETCH_FILTER = 'USER:FETCH_FILTER',
  FETCH_FILTER_SUCCESS = 'USER:FETCH_FILTER_SUCCESS'
}

interface UserActionType<T, P> {
  type: T;
  payload: P;
}

export interface BonusRecipient extends FilterItemObject { }
export interface Role extends FilterItemObject { }
export interface User {
  id: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  role: string;
  isActive: boolean;
  bonusRecipient: BonusRecipient;
  features: Feature[];
  paramasterUserIds: string[];
}

export interface EditUser {
  firstName?: string;
  lastName?: string;
  paramasterUserIds: FilterItemObject[];
  bonusRecipientId?: string;
  role?: string;
}

export interface CreateUser extends Omit<User, 'id' | 'bonusRecipient' | 'features' | 'name' | 'isActive'> {
  password: string;
  bonusRecipientId?: string;
}

export interface UserLoginPayload {
  refreshToken: string;
  accessToken: string;
  user: any;
}

export interface FetchUsersResponsePayload {
  items: User[];
  count: number;
}

export interface UsersFilter {
  paramasterUsers: FilterItemObject[]
}

export type UserAction =
  | UserActionType<typeof UserActions.LOGIN, RequestPayload>
  | UserActionType<typeof UserActions.LOGIN_SUCCESS, ResponsePayload<UserLoginPayload>>
  | UserActionType<typeof UserActions.REFRESH_TOKEN, RequestPayload>
  | UserActionType<typeof UserActions.REFRESH_TOKEN_SUCCESS, ResponsePayload<UserLoginPayload>>
  | UserActionType<typeof UserActions.LOGOUT, null>
  | UserActionType<typeof UserActions.LOGIN_FAIL, null>
  | UserActionType<typeof UserActions.FETCH_USERS, RequestPayload>
  | UserActionType<typeof UserActions.FETCH_USERS_SUCCESS, ResponsePayload<FetchUsersResponsePayload>>
  | UserActionType<typeof UserActions.CREATE_USER, RequestPayload>
  | UserActionType<typeof UserActions.CREATE_USER_SUCCESS, ResponsePayload<User>>
  | UserActionType<typeof UserActions.UPDATE_USER, RequestPayload>
  | UserActionType<typeof UserActions.UPDATE_USER_SUCCESS, ResponsePayload<User>>
  | UserActionType<typeof UserActions.UPDATE_USER_PASSWORD, RequestPayload>
  | UserActionType<typeof UserActions.UPDATE_USER_PASSWORD_SUCCESS, ResponsePayload<User>>
  | UserActionType<typeof UserActions.DELETE_USER, RequestPayload>
  | UserActionType<typeof UserActions.DELETE_USER_SUCCESS, ResponsePayload<{ id: string }>>
  | UserActionType<typeof UserActions.FETCH_ROLES, RequestPayload>
  | UserActionType<typeof UserActions.FETCH_ROLES_SUCCESS, ResponsePayload<Role[]>>
  | UserActionType<typeof UserActions.FETCH_BONUS_RECIPIENTS, RequestPayload>
  | UserActionType<typeof UserActions.FETCH_BONUS_RECIPIENTS_SUCCESS, ResponsePayload<BonusRecipient[]>>
  | UserActionType<typeof UserActions.TOGGLE_USER_ACTIVE, RequestPayload>
  | UserActionType<typeof UserActions.TOGGLE_USER_ACTIVE_SUCCESS, ResponsePayload<User>>
  | UserActionType<typeof UserActions.FETCH_FILTER, RequestPayload>
  | UserActionType<typeof UserActions.FETCH_FILTER_SUCCESS, ResponsePayload<UsersFilter>>

export enum Feature {
  Sales = 'Sales',
  Escalation = 'Escalation',
  Simulation = 'Simulation',
  UserAdmin = 'UserAdmin'
}
