import * as EscalationActions from '../../../actions/escalation';
import { Process } from '../../../model';
import { WikusArticlesSection, WikusCustomerSection, WikusProcessSection, WikusUserSection } from '../../../components/complex/process-content';
import { WikusTextField } from '../../../components/core/input';
import { last } from 'lodash';
import { useActions } from '../../../actions';
import { useIntl } from 'react-intl';

export interface EscalationContentProps {
  escalation: Process;
}

export const EscalationContent = ({ escalation }: EscalationContentProps) => {
  const intl = useIntl();
  const escalationActions: typeof EscalationActions = useActions(EscalationActions);
  const lastEscalation = last(escalation.escalations);
  const escalationMessage = escalation.escalationMessage || lastEscalation?.message;
  const showMessage = escalation.status === 'open';
  const label = intl.formatMessage({
    id: 'process.escalationMessage.label',
  });

  const onChangeMessage = (message: string) => {
    escalationActions.setEscalation({
      id: escalation.id,
      escalationMessage: message,
    })
  }

  return (
    <>
      <WikusCustomerSection customer={escalation.customer} />

      <WikusProcessSection process={escalation} />

      <WikusArticlesSection size="large" articles={escalation.articles} isMyEscalationsView />

      <WikusUserSection process={escalation} />

      {showMessage && <WikusTextField
        name="message"
        multiline={true}
        minRows={4}
        maxRows={4}
        label={label}
        value={escalationMessage}
        onChange={({ target: { value } }) => onChangeMessage(value)} />}
    </>
  );
}