import * as CustomerActions from '../../../actions/customer';
import { OnPricingInputGroupChangedHandler, WikusPricingInputGroup } from '../../../components/complex/pricing-input-group';
import { WikusPanel } from '../../../components/core/card';
import { useActions } from '../../../actions';
import { useIntl } from 'react-intl';

export const SimulationCard = () => {
  const intl = useIntl();
  const customerActions: typeof CustomerActions = useActions(CustomerActions);
  const title = intl.formatMessage({
    id: 'simulation-card.title',
  })
  const description = intl.formatMessage({
    id: 'simulation-card.description',
  })

  const onInputGroupChanged: OnPricingInputGroupChangedHandler = (event) => {
    customerActions.selectCustomer(event as any);
  }

  return (
    <WikusPanel title={title} description={description}>
      <WikusPricingInputGroup useCountry={true} onChange={onInputGroupChanged} />
    </WikusPanel>
  )
}