import { Logo } from './components/core/assets';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './configureStore';

const { persistor, store } = configureStore();

const SplashScreen = () => <>
  <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', background: '#EDEFF5' }}></div>;
  <Logo alt="logo" style={{ position: 'absolute', zIndex: 1, animation: 'splashLogo 0.5s forwards' }} />
</>;

export function ReduxRoot() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<SplashScreen />}
        persistor={persistor}
      >
        <App />
      </PersistGate>
    </Provider>
  );
}
