import { CreateUser, EditUser, UserAction, UserActions } from '../model';

export function login(email: string, password: string): UserAction {
  return {
    type: UserActions.LOGIN,
    payload: {
      request: {
        url: '/auth/login',
        method: 'POST',
        data: {
          email, password,
        },
      },
    },
  };
}

export const RefreshUrl = '/auth/refresh';

export function refresh(email: string, refreshToken: string): UserAction {
  return {
    type: UserActions.REFRESH_TOKEN,
    payload: {
      request: {
        url: RefreshUrl,
        method: 'POST',
        data: {
          refreshToken,
          email,
        },
      },
    },
  };
}

export function logout(): UserAction {
  return {
    type: UserActions.LOGOUT,
    payload: null,
  };
}

export function fetchUsers(): UserAction {
  return {
    type: UserActions.FETCH_USERS,
    payload: {
      request: {
        url: '/users',
        method: 'GET',
      },
    },
  }
}

export function fetchFilter() {
  return {
    type: UserActions.FETCH_FILTER,
    payload: {
      request: {
        url: '/users/filter',
        method: 'GET',
      },
    },
  }
}

export function createUser(user: CreateUser): UserAction {
  return {
    type: UserActions.CREATE_USER,
    payload: {
      request: {
        url: '/users',
        method: 'POST',
        data: user,
      },
    },
  }
}

export function updateUser(user: EditUser, id?: string | number): UserAction {
  const urlSuffix = id ? `/${id}` : '';
  return {
    type: UserActions.UPDATE_USER,
    payload: {
      request: {
        url: `/users${urlSuffix}`,
        method: 'PATCH',
        data: user,
      },
    },
  }
}

export function updateUserPassword(password: string, id?: string | number): UserAction {
  const urlSuffix = id ? `/${id}` : '';
  return {
    type: UserActions.UPDATE_USER_PASSWORD,
    payload: {
      request: {
        url: `/users${urlSuffix}/password`,
        method: 'PATCH',
        data: { password },
      },
    },
  }
}

export function deleteUser(id: string | number): UserAction {
  return {
    type: UserActions.DELETE_USER,
    payload: {
      request: {
        url: `/users/${id}`,
        method: 'DELETE',
      },
    },
  }
}

export function toggleUserAcive(id: number): UserAction {
  return {
    type: UserActions.TOGGLE_USER_ACTIVE,
    payload: {
      request: {
        url: `/users/${id}/active`,
        method: 'PATCH',
      },
    },
  }
}

export function fetchRoles(): UserAction {
  return {
    type: UserActions.FETCH_ROLES,
    payload: {
      request: {
        url: '/users/roles',
        method: 'GET',
      },
    },
  }
}

export function fetchBonusRecipients(): UserAction {
  return {
    type: UserActions.FETCH_BONUS_RECIPIENTS,
    payload: {
      request: {
        url: '/users/bonus-recipients',
        method: 'GET',
      },
    },
  }
}
