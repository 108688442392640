import * as CustomerActions from '../../actions/customer';
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab';
import { CustomersFilter } from '../../model/customer';
import { GridSize, Popper, Theme } from '@material-ui/core';
import { InputValidator, WikusTextField } from './input';
import { RequiredAutocomplete } from '../utils/validators';
import { Skeleton } from '@material-ui/lab';
import { countries } from '../utils/i18n';
import { filter, map, uniq } from 'lodash';
import { useAutocomplete } from '../../hooks/useAutocomplete';
import { useCallback, useEffect, useState } from 'react';
import { useInputGroup } from '../../hooks/useInputGroup';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  input: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  tag: {
    height: 28,
  },
});

export interface IWikusAutocompleteItem {
  id: string;
  name: string;
  group?: string;
  decimal?: number;
}

export interface WikusAutocompleteProps extends Omit<AutocompleteProps<IWikusAutocompleteItem, true, true, true>, 'renderInput'> {
  name: string;
  label?: string;
  group?: string;
  submitted?: boolean;
  validator?: InputValidator;
  helperText?: string;
  xs?: boolean | GridSize;
}

export const WikusAutocomplete = withStyles(styles)(({ classes, name, label, group, submitted, validator, helperText, xs, ...props }: WikusAutocompleteProps) => {
  const intl = useIntl();
  const [key, setKey] = useState(name);
  const [validationMessage, setValidationMessage] = useState<string | undefined>();
  const { valueChanged } = useInputGroup(group);

  const updatePosition = useCallback(() => {
    let i = 0;
    const interval = setInterval(() => {
      setKey(name + i);
      if (i === 5) {
        clearInterval(interval);
        return;
      }

      i++;
    }, 100);
  }, [name]);

  const update = useCallback((value: unknown) => {
    const message = valueChanged(name, value, validator);
    const validationMessage = message
      ? intl.formatMessage({
        id: message,
      })
      : undefined;
    setValidationMessage(validationMessage);
  }, [valueChanged, name, intl, validator]);

  const onChange = useCallback((event, value, reason, details) => {
    update(value);
    props.onChange && props.onChange(event, value, reason, details);
  }, [props.onChange]);

  const uniqueGroups = filter(uniq(map(props.options, o => o.group)));
  const groupBy = uniqueGroups.length > 1
    ? (option: IWikusAutocompleteItem) => option.group as string
    : undefined;

  useEffect(() => {
    update(props.value || []);
  }, []);

  useEffect(() => {
    update(props.value);
  }, [props.value, update]);

  const hasError = !!(submitted && validationMessage);
  const autoHelperText =
    (hasError &&
      intl.formatMessage({
        id: validationMessage,
      })) || helperText;

  return (
    <Autocomplete
      {...props}
      value={props.value || []}
      onChange={onChange}
      // ref={anchorRef}
      classes={{
        input: classes?.input,
        tag: classes?.tag,
      }}
      PopperComponent={({ ...props }) => (
        <Popper
          {...props}
          key={key}
        />
      )}
      // Set menu max height (optional)
      // ListboxProps={{ style: { maxHeight: '30vh' } }}
      // PopperComponent={(pp) => <Popper key={name} {...pp} />}
      getOptionLabel={(option) => option?.name || ''}
      groupBy={groupBy}

      renderInput={(params: AutocompleteRenderInputParams) => <WikusTextField onFocus={updatePosition} {...params} name={name} label={label} group={group} xs={xs} submitted={submitted} helperText={autoHelperText} error={hasError} noStateChange={true} />}
    />
  );
});

export const WikusCountryAutocomplete = ({ ...props }: Omit<WikusAutocompleteProps, 'options'>) => {
  const intl = useIntl();
  const translatedCountries = countries.getNames(intl.locale);
  const options = map(translatedCountries, (name, id) => ({
    id,
    name,
  }))

  return (
    <WikusAutocomplete options={options} {...props} />
  )
}

export const WikusLanguageAutocomplete = ({ ...props }: Omit<WikusAutocompleteProps, 'options'>) => {
  const { isLoading, createAutocompleteProps } = useAutocomplete<typeof CustomerActions, CustomersFilter>(CustomerActions, 'customer');

  if (isLoading) {
    return (
      <Skeleton variant="rect" height={48} width="100%" />
    )
  }

  return (
    <WikusAutocomplete {...createAutocompleteProps('languageId')} {...props} />
  )
}

interface WikusStateAutocompleteProps extends WikusAutocompleteProps {
  countryCode?: string;
}

export const WikusStateAutocomplete = ({ countryCode, ...props }: Omit<WikusStateAutocompleteProps, 'options'>) => {
  const stateFilter = useCallback((option) => {
    if (!countryCode) {
      return true;
    }

    return option.group === countryCode;
  }, [countryCode]);
  const { isLoading, createAutocompleteProps } = useAutocomplete<typeof CustomerActions, CustomersFilter>(CustomerActions, 'customer');

  if (isLoading) {
    return (
      <Skeleton variant="rect" height={48} width="100%" />
    )
  }

  return (
    <WikusAutocomplete {...createAutocompleteProps('areaId', RequiredAutocomplete, stateFilter)} {...props} />
  )
}