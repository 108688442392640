import { FetchSuccessProcessesPayload, Process, ProcessesFilter } from './process';
import { RequestPayload, ResponsePayload } from './request';

export enum ProcessDraftActions {
  CREATE_OR_UPDATE_DRAFT = 'CREATE_OR_UPDATE_DRAFT',
  CREATE_OR_UPDATE_DRAFT_SUCCESS = 'CREATE_OR_UPDATE_DRAFT_SUCCESS',
  FETCH_DRAFTS = 'FETCH_DRAFTS',
  FETCH_DRAFTS_SUCCESS = 'FETCH_DRAFTS_SUCCESS',
  FETCH_MORE_DRAFTS = 'FETCH_MORE_DRAFTS',
  FETCH_MORE_DRAFTS_SUCCESS = 'FETCH_MORE_DRAFTS_SUCCESS',
  FETCH_FILTER = 'FETCH_FILTER',
  FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS',
  EDIT_DRAFT = 'EDIT_DRAFT'
}

interface ProcessDraftActionType<T, P> {
  type: T;
  payload: P;
  meta?: any;
}

export type ProcessDraftAction =
  | ProcessDraftActionType<typeof ProcessDraftActions.CREATE_OR_UPDATE_DRAFT, RequestPayload>
  | ProcessDraftActionType<typeof ProcessDraftActions.CREATE_OR_UPDATE_DRAFT_SUCCESS, ResponsePayload<Process>>
  | ProcessDraftActionType<typeof ProcessDraftActions.FETCH_DRAFTS, RequestPayload>
  | ProcessDraftActionType<typeof ProcessDraftActions.FETCH_DRAFTS_SUCCESS, ResponsePayload<FetchSuccessProcessesPayload>>
  | ProcessDraftActionType<typeof ProcessDraftActions.FETCH_MORE_DRAFTS, RequestPayload>
  | ProcessDraftActionType<typeof ProcessDraftActions.FETCH_MORE_DRAFTS_SUCCESS, ResponsePayload<FetchSuccessProcessesPayload>>
  | ProcessDraftActionType<typeof ProcessDraftActions.FETCH_FILTER, RequestPayload>
  | ProcessDraftActionType<typeof ProcessDraftActions.FETCH_FILTER_SUCCESS, ResponsePayload<ProcessesFilter>>
  | ProcessDraftActionType<typeof ProcessDraftActions.EDIT_DRAFT, Process>;