import { ArticleContentDetails } from './details';
import { ArticleContentPriceInfo } from './price-info';
import { ArticleContentSummary } from './summary';
import { NewProcessArticle } from '../../../../model';
import { WikusItem } from '../../../../components/core/spacing';

export type TArticleContentProps = {
  article: NewProcessArticle;
  inputGroupName?: string;
  classes?: any;
  isOpen?: boolean;
};

export const ArticleContent = ({ article, isOpen }: TArticleContentProps) => {
  const articleInputGroupName = `newProcessArticleContent-${article.article.id}`;

  return (
    <>
      <WikusItem spacingDirection="down">
        <ArticleContentDetails article={article} />
      </WikusItem>
      <WikusItem spacingDirection="down">
        <ArticleContentPriceInfo
          article={article}
          inputGroupName={articleInputGroupName}
          isOpen={isOpen}
        />
      </WikusItem>
      <ArticleContentSummary
        article={article}
        inputGroupName={articleInputGroupName}
      />
      {/* <WikusItem spacingDirection="down">
        <ArticleContentGoal article={article} />
      </WikusItem> */}
    </>
  );
};
