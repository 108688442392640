import { Article } from './article'
import { Customer } from './customer';
import { FilterItem } from './misc';
import { RequestPayload, ResponsePayload } from './request';
import { User } from './user';

export enum ProcessActions {
  SET_PRICE = 'SET_PRICE',
  SET_DEVIATION = 'SET_DEVIATION',
  SET_LENGTH_IN_METERS = 'SET_LENGTH_IN_METERS',
  SET_UNIT_COUNT = 'SET_UNIT_COUNT',
  SET_HINT = 'SET_HINT',
  SET_NOTE = 'SET_NOTE',
  SET_RECIPIENTS = 'SET_RECIPIENTS',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_NAME = 'SET_NAME',
  SET_WITH_WELD = 'SET_WITH_WELD',
  CREATE_PROCESS = 'CREATE_PROCESS',
  CREATE_PROCESS_SUCCESS = 'CREATE_PROCESS_SUCCESS',
  FETCH_PROCESSES = 'FETCH_PROCESSES',
  FETCH_PROCESSES_SUCCESS = 'FETCH_PROCESSES_SUCCESS',
  FETCH_MORE_PROCESSES = 'FETCH_MORE_PROCESSES',
  FETCH_MORE_PROCESSES_SUCCESS = 'FETCH_MORE_PROCESSES_SUCCESS',
  FETCH_FILTER = 'FETCH_FILTER',
  FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS',
  EDIT_PROCESS = 'EDIT_PROCESS',
  UPDATE_PROCESS = 'UPDATE_PROCESS',
  UPDATE_PROCESS_SUCCESS = 'UPDATE_PROCESS_SUCCESS',
  PATCH_PROCESS = 'PATCH_PROCESS',
  PATCH_PROCESS_SUCCESS = 'PATCH_PROCESS_SUCCESS',
  PATCH_PROCESS_FAIL = 'PATCH_PROCESS_FAIL',
  CLEAR_NEW_PROCESS = 'CLEAR_NEW_PROCESS',
  FETCH_ESCALATIONS = 'FETCH_ESCALATIONS',
  FETCH_ESCALATIONS_SUCCESS = 'FETCH_ESCALATIONS_SUCCESS',
  FETCH_MORE_ESCALATIONS = 'FETCH_MORE_ESCALATIONS',
  FETCH_MORE_ESCALATIONS_SUCCESS = 'FETCH_MORE_ESCALATIONS_SUCCESS',
  FETCH_ESCALATION_FILTER = 'FETCH_ESCALATION_FILTER',
  FETCH_ESCALATION_FILTER_SUCCESS = 'FETCH_ESCALATION_FILTER_SUCCESS',
  PATCH_ESCALATION = 'PATCH_ESCALATION',
  PATCH_ESCALATION_SUCCESS = 'PATCH_ESCALATION_SUCCESS',
  SET_ESCALATION = 'SET_ESCALATION'
}

interface ProcessActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export interface ProcessQueryParams {
  take?: number;
  skip?: number;
  status?: ProcessStatus;
}

export interface ProcessArticle {
  article: Article;
  amount?: number; // Gesamtbetrag
  netPerUnit: number; // Verhandelter Preis
  discount: number; // Effektiver Rabatt (in Prozent)
  deviation: number; // Abweichung (in Prozent)
  isEscalation: boolean;
  unitCount?: number; // Stückzahl/Anzahl Gebinde
  withWeld: boolean;
  lengthInMeters: number;
  hint?: string;
  note?: string;
}

export interface ProcessRecipientUser {
  email: string;
  language?: string;
  sentStatus?: string;
  sentAt?: string;
}

export interface ProcessRecipient {
  type: ProcessRecipientType;
  recipientUsers?: ProcessRecipientUser[];
}

export type ProcessStatus = 'open' | 'approved' | 'rejected';

export interface ProcessEscalation {
  editor: User;
  editedAt: string;
  message?: string;
}

export interface Process {
  id: number;
  customer: Customer;
  number?: string;
  name: string;
  date: string;
  customerNumber?: string;
  status: ProcessStatus;
  amount: number;
  articles: ProcessArticle[];
  recipients: ProcessRecipient[];
  message?: string;
  escalations?: ProcessEscalation[];
  escalationMessage?: string;
}

export interface NewProcess {
  id?: number;
  customer?: Customer;
  articles: NewProcessArticle[];
  recipients: NewProcessRecipient[];
  message?: string;
  name: string;
  userSelectionComplete: boolean;
  draftId?: number;
}

export type NewProcessArticleType = 'variant' | 'family-with-weld' | 'family-without-weld';

export type NewProcessPriceState = 'escalation' | 'goal' | 'above_goal' | 'below_goal';
export interface NewProcessArticle extends ProcessArticle {
  lastNetPrice?: number;
  targetNetPerUnit: number; // Mittelwert im Slider (in Cent)
  grossPerUnitAndMeter: number;
  grossPerUnit: number; // Rechter Wert des Sliders (in Cent)
  targetDiscount: number;
  hint?: string;
  note?: string;
  escalationThreshold: number;
  escalationThresholdInCent: number; // Eskalationsgrenze im Slider (in Cent)
  aboveTargetThresholdInCent: number; // Über dem Ziel Grenze im Slider (in Cent)
  minimumPerUnit: number; // Linker Wert des Sliders (in Cent),
  type: NewProcessArticleType;
  isValid?: boolean;
  state: NewProcessPriceState;
}

export enum ProcessRecipientType {
  eMail = 'eMail',
  excel = 'excel',
  bmeCAT = 'bmeCAT'
}

export interface NewProcessSetPricePayload {
  price: number;
  articleId: number;
}

export interface NewProcessSetDeviationPayload {
  deviation: number;
  articleId: number;
}

export interface NewProcessSetLengthInMetersPayload {
  lengthInMeters: number;
  articleId: number;
}

export interface NewProcessSetUnitCountPayload {
  unitCount: number;
  articleId: number;
}

export interface NewProcessSetHintCountPayload {
  hint?: string;
  articleId: number;
}

export interface NewProcessSetNoteCountPayload {
  note?: string;
  articleId: number;
}

export interface NewProcessSetWithWeldPayload {
  withWeld: boolean;
  articleId: number;
}

export interface NewProcessRecipientsPayload {
  recipients: NewProcessRecipient[]
}

export interface ProcessesFilter {
  customerGroup: FilterItem[],
  salesManagerId: FilterItem[],
  status: string[]
}

export interface FetchSuccessProcessesPayload {
  count: number;
  items: Process[];
}

export interface NewProcessRecipient {
  type: ProcessRecipientType;
  recipients: ProcessRecipientUser[];
}

export interface PatchEscalationRequestBodyEscalation {
  message?: string;
}

export interface PatchEscalationRequestBody {
  status: ProcessStatus;
  escalation?: PatchEscalationRequestBodyEscalation;
}

export interface PatchEscalationResponse {
  status: ProcessStatus;
  id: number;
}

export interface SetEscalation {
  id: number;
  escalationMessage?: string;
}

export type ProcessAction =
  | ProcessActionType<typeof ProcessActions.SET_PRICE, NewProcessSetPricePayload>
  | ProcessActionType<typeof ProcessActions.SET_DEVIATION, NewProcessSetDeviationPayload>
  | ProcessActionType<typeof ProcessActions.SET_LENGTH_IN_METERS, NewProcessSetLengthInMetersPayload>
  | ProcessActionType<typeof ProcessActions.SET_UNIT_COUNT, NewProcessSetUnitCountPayload>
  | ProcessActionType<typeof ProcessActions.SET_HINT, NewProcessSetHintCountPayload>
  | ProcessActionType<typeof ProcessActions.SET_NOTE, NewProcessSetNoteCountPayload>
  | ProcessActionType<typeof ProcessActions.SET_RECIPIENTS, NewProcessRecipientsPayload>
  | ProcessActionType<typeof ProcessActions.SET_MESSAGE, string>
  | ProcessActionType<typeof ProcessActions.SET_NAME, string>
  | ProcessActionType<typeof ProcessActions.SET_WITH_WELD, NewProcessSetWithWeldPayload>
  | ProcessActionType<typeof ProcessActions.CREATE_PROCESS, RequestPayload>
  | ProcessActionType<typeof ProcessActions.CREATE_PROCESS_SUCCESS, ResponsePayload<Process>>
  | ProcessActionType<typeof ProcessActions.FETCH_PROCESSES, RequestPayload>
  | ProcessActionType<typeof ProcessActions.FETCH_PROCESSES_SUCCESS, ResponsePayload<FetchSuccessProcessesPayload>>
  | ProcessActionType<typeof ProcessActions.FETCH_MORE_PROCESSES, RequestPayload>
  | ProcessActionType<typeof ProcessActions.FETCH_MORE_PROCESSES_SUCCESS, ResponsePayload<FetchSuccessProcessesPayload>>
  | ProcessActionType<typeof ProcessActions.FETCH_FILTER, RequestPayload>
  | ProcessActionType<typeof ProcessActions.FETCH_FILTER_SUCCESS, ResponsePayload<ProcessesFilter>>
  | ProcessActionType<typeof ProcessActions.EDIT_PROCESS, Process>
  | ProcessActionType<typeof ProcessActions.UPDATE_PROCESS, RequestPayload>
  | ProcessActionType<typeof ProcessActions.UPDATE_PROCESS_SUCCESS, ResponsePayload<Process>>
  | ProcessActionType<typeof ProcessActions.PATCH_PROCESS, RequestPayload>
  | ProcessActionType<typeof ProcessActions.PATCH_PROCESS_SUCCESS, ResponsePayload<Process>>
  | ProcessActionType<typeof ProcessActions.CLEAR_NEW_PROCESS, null>
  | ProcessActionType<typeof ProcessActions.FETCH_ESCALATIONS, RequestPayload>
  | ProcessActionType<typeof ProcessActions.FETCH_ESCALATIONS_SUCCESS, ResponsePayload<FetchSuccessProcessesPayload>>
  | ProcessActionType<typeof ProcessActions.FETCH_MORE_ESCALATIONS, RequestPayload>
  | ProcessActionType<typeof ProcessActions.FETCH_MORE_ESCALATIONS_SUCCESS, ResponsePayload<FetchSuccessProcessesPayload>>
  | ProcessActionType<typeof ProcessActions.FETCH_ESCALATION_FILTER, RequestPayload>
  | ProcessActionType<typeof ProcessActions.FETCH_ESCALATION_FILTER_SUCCESS, ResponsePayload<ProcessesFilter>>
  | ProcessActionType<typeof ProcessActions.PATCH_ESCALATION, RequestPayload>
  | ProcessActionType<typeof ProcessActions.PATCH_ESCALATION_SUCCESS, ResponsePayload<PatchEscalationResponse>>
  | ProcessActionType<typeof ProcessActions.SET_ESCALATION, SetEscalation>