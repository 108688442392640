import { FetchSuccessProcessesPayload, PatchEscalationResponse, Process, ProcessAction, ProcessActions, SetEscalation } from '../model';
import { ResponsePayload } from '../model/request';
import { findIndex, take, takeRight } from 'lodash';
import createReducer from './createReducer';

export const escalations = createReducer<Process[] | null>(null, {
  [ProcessActions.FETCH_ESCALATIONS_SUCCESS](state: Process[] | null, action: ProcessAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.items;
  },
  [ProcessActions.FETCH_MORE_ESCALATIONS_SUCCESS](state: Process[] | null, action: ProcessAction) {
    return [
      ...(state || []),
      ...(action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.items
    ];
  },
  [ProcessActions.PATCH_ESCALATION_SUCCESS](state: Process[] | null, action: ProcessAction) {
    const { id, status } = (action.payload as ResponsePayload<PatchEscalationResponse>).data;
    const index = findIndex(state, p => p.id === id);
    if (index < 0 || !state) {
      return state;
    }

    return [
      ...take(state, index),
      {
        ...state[index],
        status,
      },
      ...takeRight(state, state.length - (index + 1))
    ];
  },
  [ProcessActions.SET_ESCALATION](state: Process[] | null, action: ProcessAction) {
    const { id, escalationMessage } = (action.payload as SetEscalation);
    const index = findIndex(state, p => p.id === id);
    if (index < 0 || !state) {
      return state;
    }

    return [
      ...take(state, index),
      {
        ...state[index],
        escalationMessage,
      },
      ...takeRight(state, state.length - (index + 1))
    ];
  },
});

export const escalationsCount = createReducer<Process[] | null>(null, {
  [ProcessActions.FETCH_ESCALATIONS_SUCCESS](state: Process[] | null, action: ProcessAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.count;
  },
  [ProcessActions.FETCH_MORE_ESCALATIONS_SUCCESS](state: Process[] | null, action: ProcessAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.count;
  },
});