import { IWikusAutocompleteItem } from '../components/core/autocomlete';
import { InputValidator } from '../components/core/input';
import { RequiredAutocomplete } from '../components/utils/validators';
import { filter, isString, map } from 'lodash';
import { useActions } from '../actions';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export interface UseAutocompleteActions {
  fetchFilter: () => any;
}

export interface AutocompleteResult {
  [key: string]: IWikusAutocompleteItem[];
}

export const useAutocomplete = <T extends UseAutocompleteActions, F>(actions: T, translationKey: string) => {
  const filterActions: T = useActions(actions);
  const [filterOptions, setFilterOptions] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setLoading(true);
    async function fetchFilter() {
      const { payload } = await filterActions.fetchFilter();
      const data = payload?.data;
      if (data) {
        setFilterOptions(data);
      }
      setLoading(false);
    }

    fetchFilter();
  }, []);

  const createAutocompleteProps = useCallback((name: keyof F, validator: InputValidator = RequiredAutocomplete, optionFilter = () => true) => {
    return {
      label: intl.formatMessage({ id: `${translationKey}.${name}.label` }),
      name,
      validator,
      options: map(filter(filterOptions?.[name] || [], optionFilter), filterItem => {
        if (isString(filterItem)) {
          return {
            id: filterItem,
            name: intl.formatMessage({
              id: `${translationKey}.${name}.item.${filterItem}`,
            }),
          };
        }

        return filterItem;
      }),
    }
  }, [filterOptions]);

  return {
    createAutocompleteProps,
    filterOptions: filterOptions as F,
    isLoading,
  }
}