import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Theme } from '@material-ui/core';
import { WikusItem } from '../core/spacing';
import { WikusPanel } from '../core/card';
import { useTheme } from '@material-ui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';

const LoadingSkeleton = ({ height, count, spacingDirection }: any) => {
  return (
    <Fragment>
      {
        new Array(count).fill(null).map((_, index) => (
          <WikusItem key={index} spacingDirection={spacingDirection} hasBorder={spacingDirection === 'none'}>
            <Skeleton variant="rect" height={height} />
          </WikusItem>
        ))
      }
    </Fragment>
  )
}

const WikusInfiniteScroll = ({ skeletonHeight, skeletonCount, isLoading, skeletonSpacingDirection = 'down', ...props }: any) => {
  const theme = useTheme() as Theme;
  const loader = <LoadingSkeleton height={skeletonHeight} count={skeletonCount} spacingDirection={skeletonSpacingDirection} />;
  if (isLoading) {
    return loader;
  }

  if (!props.dataLength) {
    return <WikusPanel style={{ textAlign: 'center' }}>
      <FormattedMessage id="nothing-found" />
    </WikusPanel>
  }

  // use 50 here since its the offset from the hero container
  return (
    <InfiniteScroll
      {...props}
      style={{ margin: theme.spacing(2) * -1, padding: theme.spacing(2) }}
      loader={loader}>
    </InfiniteScroll>
  );
}
WikusInfiniteScroll.displayName = 'WikusInfiniteScroll';

export {
  WikusInfiniteScroll
};