import * as EscalationActions from '../../../actions/escalation';
import { WikusFilter, WikusFilterOptionChangeHandler, WikusFilterOptionType } from '../../../components/complex/filter';
import { useFilter } from '../../../hooks/useFilter';
import { useIntl } from 'react-intl';

export interface EscalationFilterProps {
  onChange?: WikusFilterOptionChangeHandler;
}

export const EscalationFilter = ({ onChange }: EscalationFilterProps) => {
  const intl = useIntl();
  const { isLoading, filter } = useFilter<typeof EscalationActions>(EscalationActions, [{
    type: WikusFilterOptionType.text,
    props: {
      name: 'customerNumber',
    },
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'customerName',
    },
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'date',
      type: 'date',
    } as any,
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'number',
    },
  }], 'process');

  const filterTitle = intl.formatMessage({
    id: 'escalation.filter.title',
  });
  const filterDescription = intl.formatMessage({
    id: 'escalation.filter.description',
  });

  return (
    <WikusFilter
      title={filterTitle}
      description={filterDescription}
      isLoading={isLoading}
      options={filter}
      order={['customerName', 'customerNumber', 'date', 'status', 'number', 'customerGroup', 'salesManagerId']}
      onChange={onChange} />
  );
}