import { Customer, Process, ProcessArticle } from '../../model';
import { MouseEventHandler } from 'react';
import { ProcessEmailIcon } from '../core/icons';
import { ReactNode } from 'react';
import { TWikusModelCardProps, WikusModelCard } from './model-card';
import { WikusAvatar } from '../core/avatar';
import { WikusCurrencyLabel } from '../core/formatted-label';
import { WikusDetailRowItem, WikusDetailRowItemVariant } from './detail-block';
import { WikusTag } from '../core/tag';
import { get, map, some } from 'lodash';
import { getInitials } from '../../utils';
import { useIntl } from 'react-intl';

export type WikusProcessCardSize = 'small';

export interface WikusProcessCardProps extends Omit<TWikusModelCardProps, 'avatar' | 'title' | 'details' | 'detailsVariant' | 'buttons' | 'tags' | 'size'> {
  process: Process,
  isEscalation?: boolean;
  size: WikusProcessCardSize,
  onSelect?: MouseEventHandler
}

export type ProcessDetailBlockItemsType = {
  [key in WikusProcessCardSize]: {
    variant?: WikusDetailRowItemVariant;
    items: {
      field: (keyof Process),
      label: string,
      component: (value: any) => ReactNode
    }[]
  }
}

export const ProcessDetailBlockItems: ProcessDetailBlockItemsType = {
  small: {
    variant: undefined,
    items: [{
      field: 'articles',
      label: 'article-families',
      component: (articles: ProcessArticle[]) => (
        <>
          {articles.map(a => a.article.itemGroupName).join(', ')}
        </>
      ),
    }, {
      field: 'amount',
      label: 'amount',
      component: (amount: number) => <WikusCurrencyLabel amount={amount} />,
    }, {
      field: 'customer',
      label: 'salesManagerId',
      component: (customer: Customer) => customer.salesManagerName,
    }
    ],
  },
  // large: {
  //   variant: 'equal',
  //   items: ['itemNumber', 'status', 'description', 'shortDescription', 'itemGroupName', 'productGroupName', 'businessAreaName', 'length']
  // }
}

const WikusProcessCard = ({ process, onSelect, isEscalation, size = 'small', ...props }: WikusProcessCardProps) => {
  const intl = useIntl();
  const { variant: detailRowVariant, items } = ProcessDetailBlockItems[size];
  const detailRowItems: WikusDetailRowItem[] = map(items, p => ({
    label: intl.formatMessage({
      id: `process.${p.label}.label`,
    }),
    value: p.component(get(process, p.field)),
  })) as WikusDetailRowItem[];
  const avatarShort = getInitials(process.customer?.name);

  const tags = [];
  if (some(process.recipients, r => r.type === 'eMail' && r.recipientUsers?.length)) {
    tags.push(<WikusTag color="primary" icon={<ProcessEmailIcon fontSize="small" />} label={intl.formatMessage({
      id: 'process.is-email',
    })}></WikusTag>)
  }

  return (
    <WikusModelCard
      {...props}
      title={`${process.customer.name} | ${process.name}`}
      avatar={<WikusAvatar status={(!!isEscalation && 'warning') || undefined} size={size} src={process.customer?.imageUrl}>{avatarShort}</WikusAvatar>}
      details={detailRowItems}
      detailsVariant={detailRowVariant}
      tags={tags}
      size={size} />
  )
};
WikusProcessCard.displayName = 'WikusProcessCard';

export { WikusProcessCard };