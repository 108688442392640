import { AppSettings } from './settings';
import { ErrorBoundary, Provider } from '@rollbar/react';

const rollbarConfig = {
  accessToken: AppSettings.rollbarToken,
  environment: AppSettings.env,
  enabled: !AppSettings.isLocal,
  captureUncaught: true,
  captureUnhandledRejections: true,
  verbose: true,
  autoInstrument: {
    log: false,
  },
};

const RollbarProvider = (props: any) => {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </Provider>
  );
}

export default RollbarProvider;