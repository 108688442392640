import { InputAction, InputActions, InputDefaultGroup, RemovePayload, SetGroupPayload, SetSubmittedPayload, StageChangePayload } from '../model/input';
import { ProcessAction, ProcessActions } from '../model/process';
import { every, omit } from 'lodash';
import createReducer from './createReducer';

export const inputGroup = createReducer<any>({}, {
  [InputActions.STATE_CHANGE](state: any, action: InputAction) {
    const { name, group, value, isValid } = action.payload as StageChangePayload;
    const key = group || InputDefaultGroup;
    const oldFields = omit(state[key]?.validationState, name);

    const newState = {
      ...state,
      [key]: {
        submitted: state[key]?.submitted || false,
        value: {
          ...(state[key]?.value || {}),
          [name]: value,
        },
        validationState: {
          ...(state[key]?.validationState || {}),
          [name]: isValid,
        },
        isValid: every(oldFields, (val) => val !== false) && isValid,
      },
    }
    console.log(newState);
    return newState;
  },
  [InputActions.REMOVE_FIELD](state: any, action: InputAction) {
    const { name, group } = action.payload as RemovePayload;
    const key = group || InputDefaultGroup;

    return {
      ...state,
      [key]: {
        value: state[key]?.value && omit(state[key]?.value, name),
        validationState: state[key]?.validationState && omit(state[key]?.validationState, name),
        isValid: state[key]?.isValid,
      },
    };
  },
  [InputActions.REMOVE_GROUP](state: any, action: InputAction) {
    const key = action.payload as string;

    return state && omit(state, key);
  },
  [InputActions.SET_GROUP](state: any, action: InputAction) {
    const { group, value } = action.payload as SetGroupPayload;

    return {
      ...state,
      [group]: value,
    }
  },
  [InputActions.SET_SUBMITTED](state: any, action: InputAction) {
    const { group, submitted } = action.payload as SetSubmittedPayload;

    return {
      ...state,
      [group]: {
        ...state[group],
        submitted,
      },
    }
  },
  [ProcessActions.CLEAR_NEW_PROCESS](state: any, action: ProcessAction) {
    return {

    };
  },
});