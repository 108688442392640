import { Process } from '../../../model';
import { ProcessPageContext } from '../context';
import { WikusArticlesSection, WikusCustomerSection, WikusEscalationSection, WikusProcessSection } from '../../../components/complex/process-content';
import { WikusTextField } from '../../../components/core/input';
import { last } from 'lodash';
import { useActions } from '../../../actions';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useProcessInputGroup } from '../../../hooks/useInputGroup';

export interface ProcessContentProps {
  process: Process;
}

export const ProcessContent = ({ process }: ProcessContentProps) => {
  const intl = useIntl();
  const { actions } = useContext(ProcessPageContext);
  const processActions = useActions(actions);
  const escalation = last(process.escalations);
  const { createInputProps, value } = useProcessInputGroup(process);

  return (
    <>
      <WikusCustomerSection customer={process.customer}>
        {!process.customer.customerNumber && !!processActions.patchProcess &&
          <WikusTextField
            {...createInputProps('customerNumber')}
            value={value?.customerNumber || ''}
            name="customerNumber"
            label={intl.formatMessage({
              id: 'customer.customerNumber.label',
            })} />
        }
      </WikusCustomerSection>

      <WikusProcessSection process={process} />

      <WikusArticlesSection articles={process.articles} />

      {!!escalation && <WikusEscalationSection escalation={escalation} />}
    </>
  );
}