import { ArticleSummaryCaption, WikusBoxedContent } from './common';
import {
  Box,
  Grid,
  Popper,
  Slider,
  Theme,
  makeStyles
} from '@material-ui/core';
import { ChangeEvent, Fragment, KeyboardEventHandler, ReactNode, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NewProcessArticle, NewProcessPriceState } from '../../../../model';
import { NumberFormatValues } from 'react-number-format';
import {
  PriceSliderCheckIcon,
  PriceSliderErrorIcon
} from '../../../../components/core/icons';
import { WikusCurrencyLabel } from '../../../../components/core/formatted-label';
import { WikusMaskedCurrencyTextField } from '../../../../components/core/masked-input';
import { WikusSection } from '../../../../components/core/spacing';
import { WikusTypography } from '../../../../components/core/typography';
import { findIndex, isUndefined, map } from 'lodash';
import { useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { withTheme } from '@material-ui/styles';

const usePriceSliderStyles = makeStyles((theme: Theme) => ({
  root: ({ value }: { value: number }) => {
    const progressInPercent = value / 6 * 100;

    return {
      height: 44,

      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        height: 44,
        borderRadius: 6,
        width: '100%',
        background: `linear-gradient(90deg, ${theme.palette.error.main} 0%, ${theme.palette.warning.main} 30%, ${theme.palette.success.light} 50%, ${theme.palette.success.main} 100%)`,
        zIndex: 1,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        left: `${progressInPercent}%`,
        height: 44,
        width: `calc(100% - ${progressInPercent}%)`,
        zIndex: 2,
        background: theme.palette.grey[300],
      },
    }
  },
  track: {
    height: 44,
    borderRadius: 6,
  },
  rail: {
    background: theme.palette.grey[300],
    height: 44,
  },
  markLabel: {
    top: 85,
    fontSize: 14,

    // this works if our threshold indexes are always the same
    '&[data-index="1"],&[data-index="5"]': {
      fontSize: 18,
      fontWeight: 700,
      top: 81.5,
    },
  },
  mark: {
    display: 'none',
  },
  thumb: {
    zIndex: 3,
    width: 30,
    height: 30,
    marginLeft: -15,
    background: 'none',
    paddingTop: 5,
    marginTop: 8,

    '& svg': {
      width: 35,
      height: 35,
      backgroundColor: '#fff',
      borderRadius: '50%',
      padding: 2.5,
    },
  },
}));

const usePopperStyles = makeStyles((theme: Theme) => ({
  arrow: {
    display: 'inline-block',
    width: '30px',
    height: '30px',
    bottom: '80px',
    position: 'absolute',
    '&::after': {
      top: '140%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderWidth: 20,
      marginLeft: -20,
      borderTopColor: theme.palette.success.main,
    },
  },
  arrowEscalation: {
    '&::after': {
      borderTopColor: theme.palette.error.main,
    },
  },
  arrowBelowGoal: {
    '&::after': {
      borderTopColor: theme.palette.warning.main,
    },
  },
  arrowbox: {
    position: 'relative',
    display: 'block',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    marginBottom: 30,
    borderRadius: '0 0 5px 5px',
    textAlign: 'center',
    width: 265,

    '& p': {
      ...theme.typography.h3,
      marginBottom: `${theme.spacing(1)}px !important`,
    },

    '& span': {
      ...theme.typography.caption,
      lineHeight: 1.2,
    },
  },
  arrowBoxEscalation: {
    backgroundColor: theme.palette.error.main,
  },
  arrowBoxBelowGoal: {
    backgroundColor: theme.palette.warning.main,
  },
  inputLabel: {
    color: theme.palette.text.primary,
  },
}));

type TWikusPriceSliderTooltip = {
  value: number;
  goalDiff: number;
  escalationDiff: number;
  belowGoalDiff: number;
  onInputChange: any;
  children: any;
  priceState: NewProcessPriceState;
  maxPrice: number;
  isOpen?: boolean;
  isFocused: boolean;
  isTargetPrice?: boolean;
  isLastPrice?: boolean;
  setPrice: (price: number) => void;
};

const WikusPriceSliderToolTip = memo(({
  value,
  goalDiff,
  escalationDiff,
  belowGoalDiff,
  children,
  maxPrice,
  priceState,
  isOpen,
  isFocused,
  isTargetPrice,
  isLastPrice,
  onInputChange,
  setPrice,
}: TWikusPriceSliderTooltip) => {
  const [tmpValue, setTmpValue] = useState<null | number>(null);
  const [helperText, setHelperText] = useState<ReactNode>();

  const intl = useIntl();
  const classes = usePopperStyles({ priceState });
  let arrowboxClasses = classes.arrowbox;
  let arrowClasses = classes.arrow;

  if (priceState === 'escalation') {
    arrowboxClasses += ` ${classes.arrowBoxEscalation}`;
    arrowClasses += ` ${classes.arrowEscalation}`;
  }

  if (priceState === 'below_goal') {
    arrowboxClasses += ` ${classes.arrowBoxBelowGoal}`;
    arrowClasses += ` ${classes.arrowBelowGoal}`;
  }

  const [referenceElement, setReferenceElement] = useState(null) as any;
  const [arrowElement, setArrowElement] = useState(null) as any;

  let title = 'article.priceslider.price.title';
  if (isTargetPrice) {
    title = 'article.priceslider.price.target-title';
  } else if (isLastPrice) {
    title = 'article.priceslider.price.last-title';
  }

  const onPlaceholderResult = () => {
    if (tmpValue) {
      setPrice(tmpValue as number);
    }
  }

  useEffect(() => {
    if (isFocused) {
      return setTmpValue(value);
    }

    setTmpValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, setTmpValue]);

  const onValueChange = (values: NumberFormatValues) => {
    if (values.floatValue === undefined) {
      setHelperText(
        <>
          {intl.formatMessage({ id: 'article.priceslider.tooltip.old-price' })}: <WikusCurrencyLabel amount={tmpValue as number} />
        </>
      );
    } else {
      setHelperText(null);
    }
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.code === 'Escape') {
      onPlaceholderResult();
    }
  }

  return (
    <>
      <span ref={setReferenceElement}>{children}</span>

      <div ref={setArrowElement} className={arrowClasses}></div>

      <Popper
        open={!!isOpen}
        placement="top"
        anchorEl={referenceElement}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            altAxis: false,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: false,
            element: arrowElement,
          },
        }}
      >
        <WikusBoxedContent
          width={265}
          borderRadius="5px 5px 0 0"
          label={
            <WikusTypography
              isBold
              align="center"
              classes={{ root: classes.inputLabel }}
            >
              <FormattedMessage id={title} />
            </WikusTypography>
          }
        >
          <WikusMaskedCurrencyTextField
            name="price"
            runWithValue={onInputChange}
            onValueChange={onValueChange}
            onKeyDown={onKeyDown}
            value={value}
            maxValue={maxPrice}
            onPlaceholderResult={onPlaceholderResult}
            helperText={helperText}
          />
        </WikusBoxedContent>

        <ArticleSummaryCaption
          classes={{ root: arrowboxClasses }}
          state={priceState}
          diff={{
            escalation: escalationDiff,
            goal: goalDiff,
            below_goal: belowGoalDiff,
          }}
        ></ArticleSummaryCaption>
      </Popper>
    </>
  );
});

const WikusPriceSliderThumb = withTheme((props: any) => {
  return (
    <Box {...props} display="flex" flexDirection="column">
      <WikusPriceSliderToolTip
        value={props.netPerUnit as number}
        isTargetPrice={props.netPerUnit === props.targetNetPerUnit}
        isLastPrice={props.netPerUnit === props.lastNetPrice}
        goalDiff={props.goalDiff as number}
        escalationDiff={props.escalationDiff as number}
        belowGoalDiff={props.targetNetPerUnit - props.netPerUnit}
        maxPrice={props.maxPrice}
        priceState={props.priceState}
        isOpen={props.isOpen}
        isFocused={props.isFocused}
        onInputChange={props.onInputChange}
        setPrice={props.setPrice}
      >
        {props.priceState !== 'escalation' && props.priceState !== 'below_goal' ? (
          <PriceSliderCheckIcon color={props.theme.palette.success.main} />
        ) : (
          <PriceSliderErrorIcon color={props.priceState === 'escalation' ? props.theme.palette.error.main : props.theme.palette.warning.main} />
        )}
      </WikusPriceSliderToolTip>
    </Box>
  );
});

const usePriceLabelStyles = makeStyles((theme: Theme) => ({
  grid: {
    position: 'relative',
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  inTargetGrid: {
    flex: 4,
  },
  belowTargetGrid: {
  },
  border: {
    width: '100%',
    height: '4px',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  escalation: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.error.main} 0%, ${theme.palette.warning.main} 100%)`,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  },
  belowTarget: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.success.light} 100%)`,
  },
  inTarget: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.success.light} 50%, ${theme.palette.success.main} 100%)`,
  },
  aboveTarget: {
    background: theme.palette.success.main,
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
}));

const WikusPriceSliderDescriptionLabels = () => {
  const { grid, inTargetGrid, border, escalation, inTarget, aboveTarget } =
    usePriceLabelStyles();

  return (
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid
        classes={{
          root: grid,
        }}
        item
        sm
      >
        <div className={`${border} ${escalation}`}></div>
        <WikusTypography variant="caption">
          <FormattedMessage id="article.priceslider.labels.escalation" />
        </WikusTypography>
      </Grid>
      <Grid
        classes={{
          root: `${grid} ${inTargetGrid}`,
        }}
        item
        sm
      >
        <div className={`${border} ${inTarget}`}></div>
        <WikusTypography variant="caption">
          <FormattedMessage id="article.priceslider.labels.goal" />
        </WikusTypography>
      </Grid>
      <Grid
        classes={{
          root: grid,
        }}
        item
        sm
      >
        <div className={`${border} ${aboveTarget}`}></div>
        <WikusTypography variant="caption">
          <FormattedMessage id="article.priceslider.labels.aboveTarget" />
        </WikusTypography>
      </Grid>
    </Grid>
  );
};

export interface WikusPriceSliderProps {
  article: NewProcessArticle;
  isOpen?: boolean;
  onChange: (price: number) => void;
}

export const WikusPriceSlider = ({
  article,
  onChange,
  isOpen,
}: WikusPriceSliderProps) => {
  const [value, setValue] = useState(3);
  const [sliderUsed, setSliderUsed] = useState(false);
  const [valueDebounced] = useDebounce(value, 300);
  const [marks, setMarks] = useState([]) as any;
  const [markValues, setMarkValues] = useState<any>([]);
  const [inputFocused, setInputFocused] = useState(false);

  const classes = usePriceSliderStyles({ value });

  const calculatePriceFromValue = useCallback(
    (value: number) => {
      const [integer, decimal] = [
        value > 0 ? Math.floor(value) : Math.ceil(value),
        value % 1
      ];

      if (integer === markValues.length - 1) {
        return markValues[integer];
      }

      const baseValue = markValues[integer];
      const stepValue = (markValues[integer + 1] - baseValue) * decimal;
      return baseValue + stepValue;
    },
    [markValues]
  );

  const onSliderChange: any = (e: ChangeEvent, value: any) => {
    let isInTooltip = false;
    let currentElement: HTMLElement | null = e.target as HTMLElement;
    while (currentElement && !isInTooltip) {
      isInTooltip = currentElement.getAttribute('role') === 'tooltip';
      currentElement = currentElement.parentElement;
    }

    if (isInTooltip) {
      if (e.target.tagName === 'INPUT') {
        setInputFocused(true);
        const inputEl = e.target as HTMLInputElement;
        inputEl.focus();
        inputEl.select();
      }

      return;
    }

    setInputFocused(false);
    setSliderUsed(true);
    setValue(value);
  };

  useEffect(() => {
    if (isUndefined(valueDebounced) || isNaN(valueDebounced) || !sliderUsed) {
      return;
    }
    const price = calculatePriceFromValue(valueDebounced);
    onChange && onChange(price);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatePriceFromValue, valueDebounced]);

  const onInputChange: any = useCallback(
    (price: number) => {
      onChange && onChange(price);
      setInputFocused(false);
    },
    [onChange]
  );

  const calculateValueFromPrice = useCallback(
    (price: number) => {
      const baseValueIndex = findIndex(
        markValues,
        (_, i) => markValues[i + 1] >= price
      );
      if (baseValueIndex === markValues.length - 1) {
        return baseValueIndex;
      }
      const stepPrice = price - markValues[baseValueIndex];
      const stepSpan =
        markValues[baseValueIndex + 1] - markValues[baseValueIndex];
      const stepValue = stepPrice / stepSpan;
      return baseValueIndex + stepValue;
    },
    [markValues]
  );

  const ThumbComponent = useMemo(() => {
    return (tProps: any) => (
      <WikusPriceSliderThumb
        {...tProps}
        priceState={article.state}
        netPerUnit={article.netPerUnit}
        targetNetPerUnit={article.targetNetPerUnit}
        lastNetPrice={article.lastNetPrice}
        goalDiff={article.aboveTargetThresholdInCent - article.netPerUnit}
        escalationDiff={article.escalationThresholdInCent - article.netPerUnit}
        maxPrice={article.grossPerUnit}
        isOpen={isOpen}
        isFocused={inputFocused}
        onInputChange={onInputChange}
        setPrice={onChange}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    article.state,
    article.netPerUnit,
    article.aboveTargetThresholdInCent,
    article.escalationThresholdInCent,
    article.grossPerUnit,
    onInputChange,
    isOpen,
    inputFocused
  ]);

  useEffect(() => {
    const markValues = [
      0,
      article.escalationThresholdInCent,
      article.escalationThresholdInCent +
      (article.targetNetPerUnit - article.escalationThresholdInCent) / 2,
      article.targetNetPerUnit,
      article.targetNetPerUnit +
      (article.targetNetPerUnit - article.escalationThresholdInCent) / 2,
      article.aboveTargetThresholdInCent,
      article.grossPerUnit
    ];
    const marks = map(markValues, (value, index) => ({
      value: index,
      label: <WikusCurrencyLabel amount={value} />,
    }));
    setMarkValues(markValues);
    setMarks(marks);
  }, [
    article.targetNetPerUnit,
    article.escalationThresholdInCent,
    article.aboveTargetThresholdInCent,
    article.grossPerUnit
  ]);

  useEffect(() => {
    const value = calculateValueFromPrice(article.netPerUnit);
    setValue(value);
  }, [calculateValueFromPrice, article.netPerUnit]);

  return (
    <WikusSection spacingDirection="vertical" position="relative">
      <Slider
        classes={{ ...classes }}
        value={value}
        marks={marks}
        step={0.025}
        min={0}
        max={marks.length - 1}
        valueLabelDisplay="auto"
        onChange={onSliderChange}
        ThumbComponent={
          ThumbComponent}
      />
      <WikusPriceSliderDescriptionLabels />
    </WikusSection>
  );
};
