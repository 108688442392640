import { IWikusAutocompleteItem } from '../components/core/autocomlete';
import { WikusFilterOption, WikusFilterOptionProps, WikusFilterOptionType } from '../components/complex/filter';
import { WikusTextFieldProps } from '../components/core/input';
import { isString, map } from 'lodash';
import { useActions } from '../actions';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export interface UseFilterActions {
  fetchFilter: () => any;
}

export interface FilterResult {
  [key: string]: IWikusAutocompleteItem[];
}

export interface AdditionalFilterOptionProps extends Omit<WikusFilterOptionProps, 'label'> {
  validator?: WikusTextFieldProps['validator'];
};

export interface AdditionalFilterOption extends Omit<WikusFilterOption, 'props'> {
  props: AdditionalFilterOptionProps;
};

export const useFilter = <T extends UseFilterActions>(actions: T, additionalOptions: AdditionalFilterOption[] = [], translationKey: string) => {
  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState<WikusFilterOption[]>([]);
  const filterActions: T = useActions(actions);
  const intl = useIntl();

  useEffect(() => {
    setLoading(true);
    async function fetchFilter() {
      const { payload } = await filterActions.fetchFilter();
      const data = payload?.data;
      setLoading(false);

      const options: WikusFilterOption[] = map(data, (val, key) => ({
        type: WikusFilterOptionType.option,
        props: {
          name: key,
          label: intl.formatMessage({
            id: `${translationKey}.${key}.label`,
          }),
          options: map(val, filterItem => {
            if (isString(filterItem)) {
              return {
                id: filterItem,
                name: intl.formatMessage({
                  id: `${translationKey}.${key}.item.${filterItem}`,
                }),
              } as IWikusAutocompleteItem;
            }

            return filterItem as IWikusAutocompleteItem;
          }),
        },
      }));
      const additional = map(additionalOptions, (val) => ({
        ...val,
        props: {
          ...val.props,
          label: intl.formatMessage({
            id: `${translationKey}.${val.props.name}.label`,
          }),
        },
      }));

      setFilter([
        ...additional,
        ...options
      ].sort((a, b) => {
        if (a.props.label < b.props.label) { return -1; }
        if (a.props.label > b.props.label) { return 1; }
        return 0;
      }));
    }

    fetchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, filter };
}