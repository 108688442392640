import * as ProcessActions from '../../../../actions/process';
import { Box, Grid, Theme, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { WikusDetailBlock } from '../../../../components/complex/detail-block';
import { WikusPercentLabel } from '../../../../components/core/formatted-label';

import {
  WikusHeroTypography,
  WikusTypography
} from '../../../../components/core/typography';
import {
  WikusMaskedPercentageTextField
} from '../../../../components/core/masked-input';
import { WikusSection } from '../../../../components/core/spacing';

import { TArticleContentProps } from './';
import { WikusAlert } from '../../../../components/core/alert';
import { WikusBoxedContent } from './common';
import { WikusModal, WikusModalProps } from '../../../../components/core/modal';
import { WikusPanel } from '../../../../components/core/card';
import { WikusPriceSlider } from './price-slider';
import { WikusTextField } from '../../../../components/core/input';
import { useActions } from '../../../../actions';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';

const usePriceInfoNoteStyles = makeStyles(() => ({
  message: {
    width: 750,
    maxWidth: '100%',
  },
}));

export interface ArticleContentPriceInfoProps extends Omit<WikusModalProps, 'title'> { }

const ArticleContentPriceInfoModal = ({ classes, ...props }: ArticleContentPriceInfoProps) => {
  const intl = useIntl();
  return (
    <WikusModal
      {...props}
      title={intl.formatMessage({ id: 'article.priceinfo.howto.title' })}>
      <WikusPanel
        title={intl.formatMessage({ id: 'article.priceinfo.howto.1.title' })}>
        <WikusTypography>
          {intl.formatMessage({ id: 'article.priceinfo.howto.1.description' })}
        </WikusTypography>
      </WikusPanel>
    </WikusModal>
  );
};

type TPriceInfoNoteProps = {
  article: TArticleContentProps['article'];
}

const PriceInfoNote = ({ article }: TPriceInfoNoteProps) => {
  const classes = usePriceInfoNoteStyles();
  const intl = useIntl();
  const processActions: typeof ProcessActions = useActions(ProcessActions);

  const setValue = useCallback(
    ({ target: { value } }) => {
      processActions[article.isEscalation ? 'setHint' : 'setNote'](article.article.id, value as string);
    },
    [processActions, article.isEscalation, article.article.id]
  );

  const type = article.isEscalation ? 'escalation' : 'process-note';
  const name = article.isEscalation ? 'hint' : 'message';
  const value = article.isEscalation ? article.hint : article.note;
  const severity = article.isEscalation ? 'warning' : 'info';

  return (
    <WikusAlert
      title={<FormattedMessage id={`article.priceinfo.${type}.title`} />}
      description={<FormattedMessage id={`article.priceinfo.${type}.description`} />}
      severity={severity}
      classes={{
        message: classes.message,
      }}
    >
      <WikusTextField
        label={intl.formatMessage({ id: `article.priceinfo.${type}.label` })}
        onChange={setValue}
        value={value}
        name={name}
        multiline={true}
        minRows={4}
        maxRows={4}
      />
    </WikusAlert>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    border: 'none',
    fontFamily: 'Roboto Condensed, Arial, Helvetica, sans-serif',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '3.5rem',
  },
  link: {
    textDecoration: 'underline',
    display: 'inline-block',
    cursor: 'pointer',
  },
}));

export const ArticleContentPriceInfo = ({
  article,
  inputGroupName,
  isOpen,
}: TArticleContentProps) => {
  const [isHowToOpen, setIsHowToOpen] = useState(false);
  const [isPriceSliderOpen, setIsPriceSliderOpen] = useState(false);
  const processActions: typeof ProcessActions = useActions(ProcessActions);
  const classes = useStyles();

  const intl = useIntl();
  const title = intl.formatMessage({ id: 'article.priceInformation.label' });

  const setDeviation = useCallback(
    (formattedValue: any) => {
      processActions!.setDeviation(article.article.id, formattedValue);
    },
    [processActions, article.article.id]
  );

  const setNetPerUnit = useCallback(
    (formattedValue: any) => {
      processActions!.setPrice(article.article.id, formattedValue);
    },
    [processActions, article.article.id]
  );

  // delay a bite to prevent from jumping
  useEffect(() => {
    setTimeout(() => {
      setIsPriceSliderOpen(!!isOpen);
    }, isOpen ? 500 : 0)
  }, [isOpen])

  return (
    <>
      <WikusSection spacingDirection="down" textAlign="center">
        <WikusDetailBlock title={title} />
        <WikusSection spacingDirection="up">
          <Grid container alignItems="center" spacing={5}>
            <Grid item sm style={{ height: '100%' }}>
              <WikusBoxedContent
                label={
                  <WikusTypography isBold align="center">
                    {intl.formatMessage({
                      id: 'article.priceinfo.discount.label',
                    })}
                  </WikusTypography>
                }
              >
                <WikusHeroTypography>
                  <WikusPercentLabel percentInDecimals={article.discount} />
                </WikusHeroTypography>
              </WikusBoxedContent>
            </Grid>
            <Grid item sm>
              <WikusBoxedContent
                label={
                  <WikusTypography isBold align="center">
                    {intl.formatMessage({
                      id: 'article.priceinfo.deviation.label',
                    })}
                  </WikusTypography>
                }
              >
                <WikusMaskedPercentageTextField
                  name="deviation"
                  value={article.deviation}
                  group={inputGroupName!}
                  runWithValue={setDeviation}
                  InputProps={{
                    classes: {
                      // Warum darf ich laut TS die Klasse nicht vergeben?
                      // @ts-ignore
                      notchedOutline: classes.input,
                      root: classes.input,
                    },
                  }}
                />
              </WikusBoxedContent>
            </Grid>
          </Grid>
          <WikusSection spacingDirection="vertical">
            <WikusTypography
              color="primary"
              variant="caption"
              onClick={() => setIsHowToOpen(true)}
              classes={{
                root: classes.link,
              }}
            >
              {intl.formatMessage({ id: 'article.priceinfo.howto.label' })}
            </WikusTypography>
          </WikusSection>

          <WikusSection spacingDirection="up">
            <Box mt="190px">
              <WikusPriceSlider article={article} isOpen={isPriceSliderOpen} onChange={setNetPerUnit} />
            </Box>
          </WikusSection>

          <WikusSection spacingDirection="up">
            <PriceInfoNote key={`${article.article.id}${article.isEscalation}`} article={article} />
          </WikusSection>
        </WikusSection>
      </WikusSection>

      <ArticleContentPriceInfoModal open={isHowToOpen} onClose={() => setIsHowToOpen(false)} />
    </>
  );
};
