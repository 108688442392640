import { formatCurrency, formatDate, formatPercentage } from '../../utils';
import { useIntl } from 'react-intl';

export type TWikusCurrencyLabelProps = {
  amount: number;
};

export const WikusCurrencyLabel = ({ amount }: TWikusCurrencyLabelProps) => {
  const intl = useIntl();
  const label = formatCurrency(intl, amount ? amount / 100 : 0);
  return <span>{label}</span>;
};

export type TWikusPercentLabelProps = {
  percentInDecimals: number;
};

export const WikusPercentLabel = ({
  percentInDecimals,
}: TWikusPercentLabelProps) => {
  const intl = useIntl();

  return <span>{formatPercentage(intl, percentInDecimals)}</span>;
};

export type TWikusDateLabelProps = {
  date: string;
};

export const WikusDateLabel = ({
  date,
}: TWikusDateLabelProps) => {
  const intl = useIntl();

  return <span>{formatDate(intl, date)}</span>;
};

export type TWikusTranslateLabelProps = {
  value: any;
};

export const WikusTranslateLabel = ({
  value,
}: TWikusTranslateLabelProps) => {
  const intl = useIntl();

  return <span>{intl.formatMessage({ id: value.toString() })}</span>;
};
