import { FetchSuccessProcessesPayload, Process } from '../model';
import { ProcessDraftAction, ProcessDraftActions } from '../model/process-draft';
import { ResponsePayload } from '../model/request';
import createReducer from './createReducer';

export const processDrafts = createReducer<Process[] | null>([], {
  [ProcessDraftActions.FETCH_DRAFTS_SUCCESS](state: Process[], action: ProcessDraftAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.items;
  },
  [ProcessDraftActions.FETCH_MORE_DRAFTS_SUCCESS](state: Process[] | null, action: ProcessDraftAction) {
    return [
      ...(state || []),
      ...(action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.items
    ];
  },
});

export const processDraftsCount = createReducer<number | undefined>(0, {
  [ProcessDraftActions.FETCH_DRAFTS_SUCCESS](state: number | undefined, action: ProcessDraftAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.count;
  },
  [ProcessDraftActions.FETCH_MORE_DRAFTS_SUCCESS](state: number | undefined, action: ProcessDraftAction) {
    return (action.payload as ResponsePayload<FetchSuccessProcessesPayload>).data.count;
  },
});