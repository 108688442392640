import * as SnackbarEventAction from '../../actions/snackbarEvent';
import * as UserActions from '../../actions/user';
import { BonusRecipientAutocomplete } from './bonus-recipient-autocomplete';
import { FormattedMessage, useIntl } from 'react-intl';
import { OnSubmitHandler, useInputGroup } from '../../hooks/useInputGroup';
import { ParamasterUserAutocomplete } from './paramaster-user-autocomplete';
import { Password } from '../utils/validators';
import { RoleAutocomplete } from './role-autocomplete';
import { RootState } from '../../reducers';
import { UserActions as UserActionsModel } from '../../model/user';
import { UsersFilter } from '../../model';
import { WikusButton, WikusButtonRow } from '../core/button';
import { WikusConfirmation } from '../core/confirmation';
import { WikusInputRow, WikusTextField } from '../core/input';
import { WikusModal, WikusModalProps } from '../core/modal';
import { WikusPanel } from '../core/card';
import { WikusSection } from '../core/spacing';
import { filter, includes, omit } from 'lodash';
import { useActions } from '../../actions';
import { useAutocomplete } from '../../hooks/useAutocomplete';
import { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export type TUserDetailsSectionProps = Omit<WikusModalProps, 'title' | 'buttons' | 'classes'> & {
  userId?: number;
};

export const UserDetailsSection = ({ userId, ...props }: TUserDetailsSectionProps) => {
  const UserGroup = `user-details-${userId || 'my'}`;
  const UserPasswordGroup = `user-details-password-${userId || 'my'}`;
  const intl = useIntl();
  const user = useSelector((rootState: RootState) => userId ? rootState.users.find(u => u.id === userId) : rootState.user);
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isDeleteBusy, setIsDeleteBusy] = useState(false);

  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid || !user) {
      return;
    }

    const { type } = await userActions.updateUser({
      ...omit(value, ['bonusRecipient', 'paramasterUsers']),
      paramasterUserIds: value.paramasterUsers?.map((u: any) => u.id),
      bonusRecipientId: value.bonusRecipient?.id,
      role: value.role.id,
    }, userId);
    const success = type === UserActionsModel.UPDATE_USER_SUCCESS;
    if (success) {
      props.onClose?.({}, 'backdropClick');
    }

    return success;
  }
  const _onPasswordSubmit: OnSubmitHandler = async () => {
    if (!isPasswordValid || !user) {
      return;
    }

    const { type } = await userActions.updateUserPassword(passwordValue.password, userId);
    return type === UserActionsModel.UPDATE_USER_PASSWORD_SUCCESS;
  }
  const _onUserDelete = async () => {
    if (!userId) {
      return;
    }

    setIsDeleteConfirmationOpen(false);
    setIsDeleteBusy(true);
    const { type } = await userActions.deleteUser(userId);
    const success = type === UserActionsModel.DELETE_USER_SUCCESS;
    setIsDeleteBusy(false);

    if (success) {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'success.user-details.delete',
        }),
        severity: 'success',
      });
      props.onClose?.({}, 'backdropClick');
    } else {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'errors.user-details.delete',
        }),
        severity: 'error',
      });
    }
  }

  const {
    value,
    setGroup,
    isValid,
    onSubmit,
    createInputProps,
    isSubmitting,
  } = useInputGroup(UserGroup, _onSubmit, 'user-details');
  const {
    value: passwordValue,
    isValid: isPasswordValid,
    onSubmit: onPasswordSubmit,
    createInputProps: createPasswordInputProps,
    isSubmitting: isPasswordSubmitting,
  } = useInputGroup(UserPasswordGroup, _onPasswordSubmit, 'user-details');
  const userActions: typeof UserActions = useActions(
    UserActions
  );
  const { isLoading, filterOptions, createAutocompleteProps } = useAutocomplete<typeof UserActions, UsersFilter>(UserActions, 'user-details');

  const createTitle = (section: string) => intl.formatMessage({ id: `user-details.${section}.title` });

  useEffect(() => {
    setGroup({
      value: {
        ...user,
        role: {
          id: user?.role,
          name: user?.role,
        },
      },
    });
  }, [user])

  useEffect(() => {
    if (!filterOptions || !user) {
      return;
    }

    const paramasterUsers = filter(filterOptions.paramasterUsers, u => includes(user.paramasterUserIds, u.id.toString()));
    setGroup({
      value: {
        ...user,
        role: {
          id: user.role,
          name: user?.role,
        },
        paramasterUsers,
      },
    });
  }, [filterOptions]);

  const comparePassword = useCallback((confirmPassword) => {
    const password = passwordValue?.password;

    if (password && password !== confirmPassword) {
      return 'errors.confirm-password';
    }
  }, [passwordValue?.password]);
  const passwordValidator = useCallback((password: unknown) => {
    return Password([value?.firstName, value?.lastName])(password);
  }, [value?.firstName, value?.lastName]);

  const modalButtons = useMemo(() => {
    const confirmButton = (<WikusButton
      color="primary"
      loading={isSubmitting}
      onClick={onSubmit}>
      <FormattedMessage id="save" />
    </WikusButton>);
    if (userId) {
      return [
        <WikusButton
          color="primary"
          variant="outlined"
          type="button"
          loading={isDeleteBusy}
          onClick={() => setIsDeleteConfirmationOpen(true)}>
          <FormattedMessage id="delete" />
        </WikusButton>,
        confirmButton
      ];
    }

    return [confirmButton];
  }, [userId, isDeleteBusy, isSubmitting, onSubmit, setIsDeleteConfirmationOpen])

  return (
    <>
      <WikusModal
        {...props}
        title={user?.name || '??? ???'}
        buttons={modalButtons}
      >
        <WikusSection spacingDirection="down">
          <WikusPanel title={createTitle('paramaster')}>
            <WikusInputRow>
              <ParamasterUserAutocomplete
                xs={6}
                {...createInputProps('paramasterUsers')}
                validator={undefined} />
            </WikusInputRow>
          </WikusPanel>
        </WikusSection>
        <WikusSection spacingDirection="down">
          <WikusPanel title={createTitle('details')}>
            <WikusInputRow>
              <WikusTextField {...createInputProps('firstName')} xs={6} />
              <WikusTextField {...createInputProps('lastName')} xs={6} />
            </WikusInputRow>
            {!!userId
              ? (<WikusInputRow>
                <RoleAutocomplete {...createInputProps('role')} xs={6} />
                <BonusRecipientAutocomplete {...createInputProps('bonusRecipient')} validator={undefined} xs={6} />
              </WikusInputRow>)
              : undefined}
          </WikusPanel>
        </WikusSection>
        <WikusSection spacingDirection="down">
          <WikusPanel title={createTitle('password')}>
            <WikusInputRow>
              <WikusTextField {...createPasswordInputProps('password')} validator={passwordValidator} type="password" xs={6} />
              <WikusTextField {...createPasswordInputProps('confirmPassword')} validator={comparePassword} type="password" xs={6} />
            </WikusInputRow>
            <WikusButtonRow>
              <WikusButton
                color="primary"
                variant="outlined"
                loading={isPasswordSubmitting}
                onClick={onPasswordSubmit}>
                <FormattedMessage id="user-details.password.cta" />
              </WikusButton>
            </WikusButtonRow>
          </WikusPanel>
        </WikusSection>
      </WikusModal>

      <WikusConfirmation
        title={intl.formatMessage({ id: 'user-details.confirmation.delete' })}
        open={isDeleteConfirmationOpen}
        onAbort={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={_onUserDelete}>
        <FormattedMessage id="user-details.confirmation.delete.question" />
      </WikusConfirmation>
    </>
  );
};