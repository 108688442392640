import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/polyfill';

import messagesDe from './de.json';
export const DefaultLocale = 'de';

export interface ILocaleConfig {
  messages: any;
  formats: any;
  locale: string;
}

export default {
  'de': {
    locale: 'de',
    messages: messagesDe,
    formats: {
      number: {
        percentWith2Decimals: { style: 'percent', maximumFractionDigits: 2 },
      },
    },
  },
} as { [key: string]: ILocaleConfig };