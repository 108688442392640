import * as SnackbarEventAction from '../../actions/snackbarEvent';
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid } from '@material-ui/core';
import { NewProcessPageType } from '../new-process';
import { RootState } from '../../reducers';
import { Routes } from '../../routes';
import { WikusButton, WikusButtonRow } from '../../components/core/button';
import { WikusItem, WikusSection } from '../../components/core/spacing';
import { WikusModalProps } from '../../components/core/modal';
import { WikusPanel } from '../../components/core/card';
import { WikusTag } from '../../components/core/tag';
import { useActions } from '../../actions';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import WikusPageLayout from '../../components/PageLayout';

export type TCompletedProps = Omit<WikusModalProps, 'classes' | 'title'> & {
  type: NewProcessPageType,
};

export const Completed = ({ type, ...props }: TCompletedProps) => {
  const history = useHistory();
  const intl = useIntl();

  const completedProcess = useSelector((rootState: RootState) => rootState.completedProcess);
  const snackBarEventActions: typeof SnackbarEventAction = useActions(SnackbarEventAction);

  const title = intl.formatMessage({ id: 'completed.title' });

  const leave = () => {
    history.replace(Routes.dashboard);
    if (type === 'edit') {
      snackBarEventActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'success.update-process',
        }),
        severity: 'success',
      });
    } else {
      snackBarEventActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'success.create-process',
        }),
        severity: 'success',
      });
    }
  }

  return (
    <WikusPageLayout
      {...props}
      title={title}>
      {!!completedProcess && (
        completedProcess.recipients.length ? (
          <WikusPanel
            title={intl.formatMessage({
              id: `completed.shipment.${type}.title`,
            })}
            description={intl.formatMessage({
              id: `completed.shipment.${type}.description`,
            })}>
            <WikusItem spacingDirection="down">
              <Grid container wrap="nowrap" spacing={2} style={{ overflowX: 'auto' }}>
                {completedProcess.recipients.map((type) =>
                  type?.recipientUsers?.map((r) => (
                    <Grid item key={r.email}>
                      <WikusTag
                        color="primary"
                        label={`${r.email} | ${intl.formatMessage({ id: type.type === 'eMail' ? 'pdf' : type.type })}`}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </WikusItem>
          </WikusPanel>
        ) : (
          <WikusPanel
            title={intl.formatMessage({
              id: `completed.no-shipment.${type}.title`,
            })}
            description={intl.formatMessage({
              id: `completed.no-shipment.${type}.description`,
            })}>
          </WikusPanel>
        )
      )}
      <WikusSection spacingDirection="up">
        <WikusButtonRow>
          <WikusButton
            color="secondary"
            onClick={leave}>
            <FormattedMessage id="continue" />
          </WikusButton>
        </WikusButtonRow>
      </WikusSection>
    </WikusPageLayout>
  )
}