import { CreateUserPanel } from './components/create-user';
import { UserTablePanel } from './components/user-table';
import { WikusSection } from '../../components/core/spacing';
import { useIntl } from 'react-intl'
import WikusPageLayout from '../../components/PageLayout';

export const UserAdminPage = () => {
  const intl = useIntl();

  return (
    <WikusPageLayout
      title={intl.formatMessage({ id: 'user-admin.title' })}>
      <WikusSection spacingDirection="down">
        <CreateUserPanel />
      </WikusSection>
      <UserTablePanel />
    </WikusPageLayout>
  )
}