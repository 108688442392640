import * as UserActions from '../../actions/user';
import { RootState } from '../../reducers';
import { Skeleton } from '@material-ui/lab';
import { WikusAutocomplete, WikusAutocompleteProps } from '../core/autocomlete';
import { useActions } from '../../actions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const BonusRecipientAutocomplete = (props: Omit<WikusAutocompleteProps, 'options'>) => {
  const bonusRecipients = useSelector((rootState: RootState) => rootState.bonusRecipients);

  const userActions: typeof UserActions = useActions(
    UserActions
  );

  useEffect(() => {
    userActions.fetchBonusRecipients();
  }, []);

  if (!bonusRecipients) {
    return (
      <Skeleton variant="rect" height={48} width="100%" />
    );
  }

  return (
    <WikusAutocomplete {...props} options={bonusRecipients} />
  )
}