import * as CustomerActions from '../../../actions/customer';
import * as SnackbarEventAction from '../../../actions/snackbarEvent';
import { CustomersFilter, NewCustomer } from '../../../model/customer';
import { Email, Required } from '../../../components/utils/validators';
import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Skeleton } from '@material-ui/lab';
import { WikusAutocomplete } from '../../../components/core/autocomlete';
import { WikusButton, WikusButtonRow } from '../../../components/core/button';
import { WikusInputRow, WikusTextField } from '../../../components/core/input';
import { WikusItem, WikusSection } from '../../../components/core/spacing';
import { WikusLocationInputGroup } from '../../../components/complex/location-input-group';
import { WikusPanel } from '../../../components/core/card';
import { WikusPricingInputGroup } from '../../../components/complex/pricing-input-group';
import { WikusSelect } from '../../../components/core/select';
import { WikusSwitch } from '../../../components/core/switch';
import { omit, pick } from 'lodash';
import { useActions } from '../../../actions';
import { useAutocomplete } from '../../../hooks/useAutocomplete';
import { useInputGroup } from '../../../hooks/useInputGroup';

const DemoData = {
  addressCity: 'Stullendorf',
  addressCountry: { id: 'DE', name: 'Deutschland' },
  addressLanguage: { id: 'DE', name: 'Deutsch' },
  addressStreet: 'Käsestraße',
  addressArea: { id: '04', name: 'Bremen', group: 'DE' },
  addressZip: '34125',
  phone: '01612456789',
  contactName: 'Horst Haferschleim',
  category: {
    'id': 'V',
    'name': 'Verbraucher/Direct customer',
    'group': null,
    'decimal': null,
    'itemName': 'category',
    'itemFilterType': 'customer',
  },
  classification: {
    'id': 'B',
    'name': 'B-Kunde',
    'group': null,
    'decimal': null,
    'itemName': 'classification',
    'itemFilterType': 'customer',
  },
  company: 'Käse AG',
  discount: 0.06,
  email: 'karl@stulle.de',
  name: 'Karl Käsestulle',
  paymentInformation: 'Zahlt schnell wie sau!',
  priceRegion: {
    name: 'Preisregion 2',
    decimal: 0.06,
    group: null,
    id: '02',
    itemFilterType: 'customer',
    itemName: 'priceRegion',
  },
  priority: '2',
  responsibleId: {
    'id': 'BAUERNFEIN',
    'name': 'Harald Bauernfeind Österreich',
    'group': null,
    'decimal': null,
    'itemName': 'responsibleId',
    'itemFilterType': 'customer',
  },
  salesManagerId: {
    'id': '----',
    'name': 'Kolageraufträge',
    'group': null,
    'decimal': null,
    'itemName': 'salesManagerId',
    'itemFilterType': 'customer',
  },
  invoiceEmail: 'karl@stulle.de',
  shippingInformation: 'Kranke Lieferbindingungen',
  supplierNumber: '12345',
  ustId: '12345-123',
  furtherExtras: ['roll-label'],
  branding: 'neutral',
  creditLimit: 1000,
}

export interface NewCustomerSelectionProps {
  onCustomerCreated?: (customer: NewCustomer) => void;
}

const NewCustomerSelection = ({ onCustomerCreated }: NewCustomerSelectionProps) => {
  const NewCustomerSelectionGroup = 'new-customer';
  const intl = useIntl();
  const { value, setGroup, getValue, isValid } = useInputGroup(NewCustomerSelectionGroup);
  const [hasDifferentInvoiceAddress, setHasDifferentInvoiceAddress] = useState(false);
  const [hasDifferentShippingAddress, setHasDifferentShippingAddress] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { isLoading, createAutocompleteProps } = useAutocomplete<typeof CustomerActions, CustomersFilter>(CustomerActions, 'customer');

  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );
  const customerActions: typeof CustomerActions = useActions(
    CustomerActions
  );

  const createTitle = (section: string) => intl.formatMessage({ id: `new-customer.${section}.title` });
  const createInputProps = useCallback((name: string, validator = Required) => {
    return {
      group: NewCustomerSelectionGroup,
      label: intl.formatMessage({ id: `new-customer.${name}.label` }),
      name,
      submitted,
      validator,
      value: getValue(name),
    }
  }, [submitted, intl, getValue]);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (!isValid) {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'errors.new-customer',
        }),
        severity: 'error',
      });
      return;
    }

    const newCustomer: NewCustomer = {
      ...pick({
        ...value,
        invoiceAddressName: value.differentInvoiceAddress ? value.name : undefined,
        addressName: value.name,
      }, [
        'name',
        'addressName',
        'addressNameAdditional',
        'addressStreet',
        'addressCity',
        'addressZip',
        'phone',
        'email',
        'invoiceEmail',
        'homepage',
        'contactName',
        'invoiceAddressName',
        'invoiceAddressNameAdditional',
        'invoiceAddressStreet',
        'invoiceAddressArea',
        'invoiceAddressCity',
        'invoiceAddressZip',
        'invoiceAddressCountry',
        'shippingAddressName',
        'shippingAddressNameAdditional',
        'shippingAddressStreet',
        'shippingAddressArea',
        'shippingAddressCity',
        'shippingAddressZip',
        'shippingAddressCountry',
        'supplierNumber',
        'company',
        'priority',
        'shippingInformation',
        'paymentInformation',
        'ustId',
        'singlePackaging',
        'discount',
        'remarks',
        'branding',
        'furtherExtras'
      ]),
      ccEmail: 'presales@wikus.de',
      address: `${value.name}\n${value.addressStreet}\n${value.addressZip} ${value.addressCity}\n${value.addressCountry.name}`,
      addressCountry: value.addressCountry.name,
      addressLanguage: value.addressLanguage.name,
      addressArea: value.addressArea.name,
      category: value.category.id,
      categoryName: value.category.name,
      classification: value.classification.id,
      classificationName: value.classification.name,
      priceRegion: value.priceRegion.id,
      priceRegionName: value.priceRegion.name,
      responsibleId: value.responsibleId.id,
      responsibleName: value.responsibleId.name,
      salesManagerId: value.salesManagerId.id,
      salesManagerName: value.salesManagerId.name,
      areaId: value.addressArea.id,
      areaName: value.addressArea.name,
    };
    customerActions.selectCustomer(newCustomer);
    onCustomerCreated && onCustomerCreated(newCustomer);
    snackbarActions.addSnackbarEvent({
      message: intl.formatMessage({
        id: 'success.new-customer',
      }),
      severity: 'success',
    });
  }

  useEffect(() => {
    setHasDifferentInvoiceAddress(value?.differentInvoiceAddress);
  }, [value?.differentInvoiceAddress]);

  useEffect(() => {
    setHasDifferentShippingAddress(value?.differentShippingAddress);
  }, [value?.differentShippingAddress]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  const demo = () => {
    setGroup({
      value: DemoData,
      isValid: true,
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <WikusSection spacingDirection="down">
        <WikusPanel title={createTitle('address')}>
          <WikusInputRow>
            <WikusTextField {...createInputProps('name')} />
          </WikusInputRow>

          <WikusLocationInputGroup group={NewCustomerSelectionGroup} submitted={submitted} prefix="address" />

          <WikusInputRow>
            <WikusTextField {...createInputProps('phone')} xs={6} />
            <WikusTextField {...createInputProps('contactName')} xs={6} />
          </WikusInputRow>

          <WikusInputRow>
            <WikusSwitch {...omit(createInputProps('differentInvoiceAddress', null), 'validator')} color="primary" />
          </WikusInputRow>

          {hasDifferentInvoiceAddress && <WikusLocationInputGroup group={NewCustomerSelectionGroup} submitted={submitted} prefix="invoiceAddress" withoutLanguage={true} />}

          <WikusInputRow>
            <WikusSwitch {...omit(createInputProps('differentShippingAddress', null), 'validator')} color="primary" />
          </WikusInputRow>

          {hasDifferentShippingAddress && <WikusLocationInputGroup group={NewCustomerSelectionGroup} submitted={submitted} prefix="shippingAddress" withoutLanguage={true} />}

          <WikusInputRow>
            <WikusTextField {...createInputProps('homepage', null)} />
          </WikusInputRow>

          <WikusInputRow>
            <WikusTextField {...createInputProps('email')} validator={Email} xs={6} />
            <WikusTextField {...createInputProps('invoiceEmail')} validator={Email} xs={6} />
          </WikusInputRow>

          <WikusInputRow>
            <WikusTextField {...createInputProps('supplierNumber')} validator={undefined} xs={6} />
            <WikusTextField {...createInputProps('company')} xs={6} />
          </WikusInputRow>
        </WikusPanel>
      </WikusSection>

      <WikusSection spacingDirection="down">
        <WikusPanel title={createTitle('wikus')}>
          <WikusPricingInputGroup
            group={NewCustomerSelectionGroup}
            submitted={submitted}
            autofill={true}
            countryCode={value?.addressCountry?.id} />

          {
            isLoading
              ? <WikusItem spacingDirection="down">
                <Skeleton variant="rect" height={48} width="100%" />
              </WikusItem>
              : <>
                <WikusInputRow>
                  <WikusAutocomplete
                    xs={6}
                    {...createInputProps('salesManagerId')}
                    {...createAutocompleteProps('salesManagerId')} />
                </WikusInputRow>
                <WikusInputRow>
                  <WikusAutocomplete
                    xs={8}
                    {...createInputProps('responsibleId')}
                    {...createAutocompleteProps('responsibleId')} />
                  <WikusSelect
                    {...createInputProps('priority')}
                    options={['1', '2', '3']}
                    xs={4} />
                </WikusInputRow>
              </>
          }

        </WikusPanel>
      </WikusSection>

      <WikusSection spacingDirection="down">
        <WikusPanel title={createTitle('shipping')}>
          <WikusInputRow>
            <WikusTextField
              {...createInputProps('shippingInformation')}
              multiline={true}
              minRows={4}
              maxRows={4} />
          </WikusInputRow>
        </WikusPanel>
      </WikusSection>

      <WikusSection spacingDirection="down">
        <WikusPanel title={createTitle('payment')}>
          <WikusInputRow>
            <WikusTextField
              {...createInputProps('paymentInformation')}
              multiline={true}
              minRows={4}
              maxRows={4} />
          </WikusInputRow>
          <WikusInputRow>
            <WikusTextField {...createInputProps('creditLimit')} type="number" />
          </WikusInputRow>
          <WikusInputRow>
            <WikusTextField {...createInputProps('ustId')} />
          </WikusInputRow>
        </WikusPanel>
      </WikusSection>

      <WikusSection spacingDirection="down">
        <WikusPanel title={createTitle('special')}>
          <WikusInputRow>
            <WikusSelect
              {...createInputProps('branding')}
              options={[{
                id: 'neutral',
                name: intl.formatMessage({ id: 'new-customer.special.branding.neutral' }),
              }, {
                id: 'with-logo',
                name: intl.formatMessage({ id: 'new-customer.special.branding.with-logo' }),
              }, {
                id: 'wikus',
                name: intl.formatMessage({ id: 'new-customer.special.branding.wikus' }),
              }]} />
          </WikusInputRow>
          <WikusInputRow>
            <WikusSelect
              {...createInputProps('furtherExtras')}
              options={[{
                id: 'roll-label',
                name: intl.formatMessage({ id: 'new-customer.special.further.roll-label' }),
              }, {
                id: 'protection',
                name: intl.formatMessage({ id: 'new-customer.special.further.protection' }),
              }]}
              validator={undefined}
              multiple={true} />
          </WikusInputRow>
        </WikusPanel>
      </WikusSection>

      <WikusSection spacingDirection="down">
        <WikusPanel title={createTitle('misc')}>
          <WikusInputRow>
            <WikusTextField
              {...createInputProps('remarks')}
              validator={undefined}
              multiline={true}
              minRows={4}
              maxRows={4} />
          </WikusInputRow>
        </WikusPanel>
      </WikusSection>

      <WikusSection spacingDirection="down">
        <WikusButtonRow>
          <WikusButton onClick={() => demo()} color="primary" variant="outlined">
            demo
          </WikusButton>
          <WikusButton type="submit" color="primary">
            <FormattedMessage id="new-customer.submit" />
          </WikusButton>
        </WikusButtonRow>
      </WikusSection>
    </form>
  )
};

export {
  NewCustomerSelection
};