import { DeleteSuccessFeedbackPayload, Feedback, FeedbackAction, FeedbackActions, FetchSuccessFeedbackPayload } from '../model';
import { ResponsePayload } from '../model/request';
import { filter, findIndex, take, takeRight } from 'lodash';
import createReducer from './createReducer';

function replaceFeedback(state: Feedback[], action: FeedbackAction) {
  const feedback = (action.payload as ResponsePayload<Feedback>).data;
  const index = findIndex(state, f => f.id === feedback.id);
  if (index < 0 || !state) {
    return state;
  }

  return [
    ...take(state, index),
    feedback,
    ...takeRight(state, state.length - (index + 1))
  ]
}

export const feedback = createReducer<Feedback[]>([], {
  [FeedbackActions.FETCH_FEEDBACK_SUCCESS](state: Feedback[], action: FeedbackAction) {
    return (action.payload as ResponsePayload<FetchSuccessFeedbackPayload>).data.items;
  },
  [FeedbackActions.FETCH_MORE_FEEDBACK_SUCCESS](state: Feedback[], action: FeedbackAction) {
    return [
      ...(state || []),
      ...(action.payload as ResponsePayload<FetchSuccessFeedbackPayload>).data.items
    ];
  },
  [FeedbackActions.CREATE_FEEDBACK_SUCCESS](state: Feedback[], action: FeedbackAction) {
    const feedback = (action.payload as ResponsePayload<Feedback>).data;
    return [
      feedback,
      ...(state || [])
    ];
  },
  [FeedbackActions.TOGGLE_UPVOTE_SUCCESS]: replaceFeedback,
  [FeedbackActions.PATCH_FEEDBACK_SUCCESS]: replaceFeedback,
  [FeedbackActions.DELETE_FEEDBACK_SUCCESS](state: Feedback[], action: FeedbackAction) {
    const { id } = (action.payload as ResponsePayload<DeleteSuccessFeedbackPayload>).data;
    return filter(state, f => f.id !== id);
  },
});

export const feedbackCount = createReducer<number>(0, {
  [FeedbackActions.FETCH_FEEDBACK_SUCCESS](state: number, action: FeedbackAction) {
    return (action.payload as ResponsePayload<FetchSuccessFeedbackPayload>).data.count;
  },
  [FeedbackActions.FETCH_MORE_FEEDBACK_SUCCESS](state: number, action: FeedbackAction) {
    return (action.payload as ResponsePayload<FetchSuccessFeedbackPayload>).data.count;
  },
  [FeedbackActions.DELETE_FEEDBACK_SUCCESS](state: number, action: FeedbackAction) {
    return state - 1;
  },
});