import { NewProcess, Process, ProcessActions, ProcessQueryParams } from '../model/process';
import { ProcessDraftAction, ProcessDraftActions } from '../model/process-draft';
import { map, omit } from 'lodash';

export function createOrUpdateDraft(process: Partial<NewProcess | Process>, skipRecipients = false): ProcessDraftAction {
  return {
    type: ProcessDraftActions.CREATE_OR_UPDATE_DRAFT,
    meta: {
      debounce: {
        time: 1000,
      },
    },
    payload: {
      request: {
        url: '/drafts',
        method: 'POST',
        data: {
          ...omit(process, ['customer', 'recipients']),
          recipients: skipRecipients ? [] : process.recipients,
          articles: map(process.articles, a => ({
            ...omit(a, 'article'),
            articleNumber: a.article.itemNumber,
          })),
          newCustomer: process.customer?.customerNumber
            ? null
            : process.customer,
          customerNumber: process.customer?.customerNumber,
        },
      },
    },
  }
}

export function fetchInitial(params?: ProcessQueryParams): ProcessDraftAction {
  return {
    type: ProcessDraftActions.FETCH_DRAFTS,
    payload: {
      request: {
        url: '/drafts',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMore(params?: ProcessQueryParams): ProcessDraftAction {
  return {
    type: ProcessDraftActions.FETCH_MORE_DRAFTS,
    payload: {
      request: {
        url: '/drafts',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchFilter() {
  return {
    type: ProcessDraftActions.FETCH_FILTER,
    payload: {
      request: {
        url: '/drafts/filter',
        method: 'GET',
      },
    },
  }
}

export function editProcess(process: Process) {
  return {
    type: ProcessActions.EDIT_PROCESS,
    payload: {
      ...omit(process, 'id'),
      draftId: process.id,
    },
  }
}