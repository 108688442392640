import { BonusRecipient, FetchUsersResponsePayload, Role, User, UserAction, UserActions, UserLoginPayload, UsersFilter } from '../model/user';
import { ResponsePayload } from '../model/request';
import { filter, findIndex, take, takeRight } from 'lodash';
import createReducer from './createReducer';
import jwtDecode from 'jwt-decode';

export const refreshToken = createReducer<string | null>(null, {
  [UserActions.LOGIN_SUCCESS](state: string, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data.refreshToken;
  },
  [UserActions.LOGOUT](state: string, action: UserAction) {
    return null;
  },
});

export const accessToken = createReducer<string | null>(null, {
  [UserActions.LOGIN_SUCCESS](state: string, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data.accessToken;
  },
  [UserActions.LOGIN_FAIL](state: string, action: UserAction) {
    return null;
  },
  [UserActions.REFRESH_TOKEN_SUCCESS](state: string, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data.accessToken;
  },
  [UserActions.LOGOUT](state: string, action: UserAction) {
    return null;
  },
  [UserActions.UPDATE_USER_SUCCESS](state: string, action: UserAction) {
    const updatedUser = (action.payload as ResponsePayload<User>).data as User;
    if (!state) {
      return null;
    }

    const decoded: User = jwtDecode(state);
    if (updatedUser.id !== decoded.id) {
      return state;
    }

    return 'invalidate';
  },
});

export const userFilter = createReducer<UsersFilter | null>(null, {
  [UserActions.FETCH_FILTER_SUCCESS](
    state: UsersFilter | null,
    action: UserAction
  ) {
    return (action.payload as ResponsePayload<UsersFilter>).data;
  },
});

export const user = createReducer<User | null>(null, {
  [UserActions.LOGIN_SUCCESS](state: User, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data.user as User;
  },
  [UserActions.LOGOUT](state: User, action: UserAction) {
    return null;
  },
  [UserActions.UPDATE_USER_SUCCESS](state: User, action: UserAction) {
    const updatedUser = (action.payload as ResponsePayload<User>).data as User;

    if (state.id === updatedUser.id) {
      return updatedUser;
    }

    return state;
  },
});

export const users = createReducer<User[]>([], {
  [UserActions.FETCH_USERS_SUCCESS](state: User[], action: UserAction) {
    return (action.payload as ResponsePayload<FetchUsersResponsePayload>).data.items;
  },
  [UserActions.CREATE_USER_SUCCESS](state: User[], action: UserAction) {
    const user = (action.payload as ResponsePayload<User>).data;
    return [
      user,
      ...state
    ]
  },
  [UserActions.TOGGLE_USER_ACTIVE_SUCCESS](state: User[], action: UserAction) {
    const user = (action.payload as ResponsePayload<User>).data;
    const index = findIndex(state, u => u.id === user.id);
    if (index < 0 || !state) {
      return state;
    }

    return [
      ...take(state, index),
      user,
      ...takeRight(state, state.length - (index + 1))
    ]
  },
  [UserActions.UPDATE_USER_SUCCESS](state: User[], action: UserAction) {
    const user = (action.payload as ResponsePayload<User>).data;
    const index = findIndex(state, u => u.id === user.id);
    if (index < 0 || !state) {
      return state;
    }

    return [
      ...take(state, index),
      user,
      ...takeRight(state, state.length - (index + 1))
    ]
  },
  [UserActions.DELETE_USER_SUCCESS](state: User[], action: UserAction) {
    const { id } = (action.payload as ResponsePayload<{ id: string }>).data;

    return [
      ...filter(state, u => u.id.toString() !== id)
    ]
  },
});

export const roles = createReducer<Role[]>([], {
  [UserActions.FETCH_ROLES_SUCCESS](state: Role[], action: UserAction) {
    return (action.payload as ResponsePayload<Role[]>).data;
  },
});

export const bonusRecipients = createReducer<BonusRecipient[]>([], {
  [UserActions.FETCH_BONUS_RECIPIENTS_SUCCESS](state: BonusRecipient[], action: UserAction) {
    return (action.payload as ResponsePayload<BonusRecipient[]>).data;
  },
});