import { Customer, CustomerAction, CustomerActions, CustomerQueryParams } from '../model';

export function fetchInitial(params?: CustomerQueryParams): CustomerAction {
  return {
    type: CustomerActions.FETCH_CUSTOMERS,
    payload: {
      request: {
        url: '/customers',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMore(params?: CustomerQueryParams): CustomerAction {
  return {
    type: CustomerActions.FETCH_MORE_CUSTOMERS,
    payload: {
      request: {
        url: '/customers',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchFilter() {
  return {
    type: CustomerActions.FETCH_FILTER,
    meta: {
      debounce: {
        time: 1000,
      },
    },
    payload: {
      request: {
        url: '/customers/filter',
        method: 'GET',
      },
    },
  }
}

export function fetchDetails(customerNumber: string) {
  return {
    type: CustomerActions.FETCH_DETAILS,
    payload: {
      request: {
        url: `/customers/${customerNumber}`,
        method: 'GET',
      },
    },
  }
}

export function selectCustomer(payload: Customer) {
  return {
    type: CustomerActions.SELECT_CUSTOMER,
    payload,
  }
}

export function findPriceRegionByCountry(countryCode: string) {
  return {
    type: CustomerActions.FIND_PRICE_REGION_BY_COUNTRY,
    payload: {
      request: {
        url: `/customers/filter/priceRegion/${countryCode}`,
        method: 'GET',
      },
    },
  }
}

export function findClassificationsByCategory(category: string) {
  return {
    type: CustomerActions.FIND_PRICE_REGION_BY_COUNTRY,
    payload: {
      request: {
        url: `/customers/filter/classifications/${category}`,
        method: 'GET',
      },
    },
  }
}