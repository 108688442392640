import { FilterItem, FilterItemObject } from '../../model/misc';
import { FormControl, FormHelperText, GridSize, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core';
import { InputValidator } from './input';
import { ReactNode, useCallback, useState } from 'react';
import { filter, map } from 'lodash';
import { languages } from '../utils/i18n';
import { useInputGroup } from '../../hooks/useInputGroup';
import { useIntl } from 'react-intl';

export interface WikusSelectProps extends Omit<SelectProps, 'name'> {
  label?: ReactNode;
  name: string;
  group?: string;
  submitted?: boolean;
  validator?: InputValidator;
  xs?: GridSize;
  options: FilterItem[];
}

const WikusSelect = ({ xs, options, label, group, name, validator, submitted, ...props }: WikusSelectProps) => {
  const intl = useIntl();
  const [validationMessage, setValidationMessage] = useState<string | undefined>();
  const { value, valueChanged } = useInputGroup(group);
  const hasError = !!(submitted && validationMessage);

  const update = useCallback((value: unknown) => {
    const message = valueChanged(name, value, validator);
    const validationMessage = message
      ? intl.formatMessage({
        id: message,
      })
      : undefined;
    setValidationMessage(validationMessage);
  }, [valueChanged, name, intl, validator]);

  const onChange = useCallback((event, child) => {
    update(event.target.value);
    props.onChange && props.onChange(event, child);
  }, [props.onChange]);

  return (
    <FormControl variant="outlined" fullWidth error={hasError}>
      <InputLabel variant="outlined" id={name}>{label}</InputLabel>
      <Select
        {...props}
        labelId={name}
        label={label}
        name={name}
        value={value?.[name] || (props.multiple ? [] : '')}
        onChange={onChange}
        variant="outlined"
      // inputProps={{
      //   className: classes?.cssOutlinedInput,
      // }}
      // classes={{
      //   root: classes?.cssOutlinedInput,
      //   focused: classes?.cssFocused,
      //   notchedOutline: classes?.notchedOutline,
      // }}
      >
        {options.map(option => (
          <MenuItem
            key={(option as FilterItemObject).id || (option as string)}
            value={(option as FilterItemObject).id || (option as string)}>
            {(option as FilterItemObject).name || (option as string)}
          </MenuItem>
        ))}
      </Select>
      {(hasError) ? <FormHelperText variant="outlined">{validationMessage}</FormHelperText> : undefined}
    </FormControl>
  )
};

export interface WikusIsoLanguageSelectProps extends Omit<WikusSelectProps, 'options'> {
  only?: string[];
}

export const WikusIsoLanguageSelect = ({ only, ...props }: WikusIsoLanguageSelectProps) => {
  const intl = useIntl();
  const translatedLanguages = languages.getNames(intl.locale);
  let options = map(translatedLanguages, (name, id) => ({
    id,
    name,
  }));

  if (only) {
    options = filter(options, o => only.includes(o.id));
  }

  return (
    <WikusSelect options={options} {...props} />
  )
}

WikusSelect.displayName = 'WikusSelect';

export {
  WikusSelect
};