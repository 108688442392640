import { WikusFilterValue } from '../components/complex/filter';
import { useActions } from '../actions';
import { useEffect, useState } from 'react';

type TUsePaginateProps = {
  itemsPerPage?: number;
  actions: any;
  filters?: WikusFilterValue;
};

type TUsePaginate = {
  fetchMore: () => Promise<void>;
  isLoading: boolean;
};

export interface UsePaginateActions {
  fetchInitial: (params?: any) => any;
  fetchMore: (params?: any) => any;
}

export const usePaginate = <T extends UsePaginateActions>({
  itemsPerPage = 10,
  actions,
  filters = {},
}: TUsePaginateProps): TUsePaginate => {
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(itemsPerPage);
  const paginateActions: T = useActions(actions);

  const fetchMore = async () => {
    await paginateActions.fetchMore({
      take: itemsPerPage,
      skip,
      ...filters,
    });
    setSkip(skip + itemsPerPage);
  };

  useEffect(() => {
    async function loadInitial() {
      setIsLoading(true);
      await paginateActions.fetchInitial({
        take: itemsPerPage,
        ...filters,
      });
      setIsLoading(false);
    }

    loadInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return { fetchMore, isLoading };
};
