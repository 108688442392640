import * as SnackbarEventAction from '../../actions/snackbarEvent';
import * as UserActions from '../../actions/user';
import { Email, Required } from '../../components/utils/validators';
import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootState } from '../../reducers';
import { Routes } from '../../routes';
import { UserActions as UserActionsModel } from '../../model/user';
import { WikusButton } from '../../components/core/button';
import { WikusItem } from '../../components/core/spacing';
import { WikusPanel } from '../../components/core/card';
import { WikusTextField } from '../../components/core/input';
import { WikusTypography } from '../../components/core/typography';
import { useActions } from '../../actions';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import WikusPageLayout from '../../components/PageLayout';

const styles: any = (theme: any) => {
  return {};
}

const LoginGroup = 'login';

export const LoginPage = withStyles(styles)(({ classes, ...props }: any) => {
  const intl = useIntl();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputGroup: any = useSelector(
    (state: RootState) => state.inputGroup
  );
  const userActions: typeof UserActions = useActions(
    UserActions
  );
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );

  useEffect(() => {
    userActions.logout();
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
    const { isValid, value: { email, password } } = inputGroup[LoginGroup];
    if (isValid) {
      setLoading(true);
      const { type } = await userActions.login(email, password);
      setLoading(false);

      if (type === UserActionsModel.LOGIN_FAIL) {
        snackbarActions.addSnackbarEvent({
          message: intl.formatMessage({
            id: 'errors.login',
          }),
          severity: 'error',
        });
      } else {
        if (props.location?.state?.from) {
          history.push(props.location?.state?.from);
        } else {
          history.push(Routes.dashboard);
        }
        snackbarActions.addSnackbarEvent({
          message: intl.formatMessage({
            id: 'success.login',
          }),
          severity: 'success',
        });
      }
    }
  }

  return (
    <WikusPageLayout title={intl.formatMessage({ id: 'login.title' })} heroWithImage isCentered>
      <WikusPanel boxPanel>
        <form onSubmit={onSubmit}>
          <WikusTypography gutterBottom variant="h1" color="primary">
            <FormattedMessage
              id="login.title"
            />
          </WikusTypography>
          <WikusTypography gutterBottom>
            <FormattedMessage
              id="login.body"
            />
          </WikusTypography>
          <WikusItem spacingDirection='down'>
            <WikusTextField
              group={LoginGroup}
              name="email"
              type="email"
              validator={Email}
              submitted={submitted}
              label={
                <FormattedMessage
                  id="login.email"
                />
              }>
            </WikusTextField>
          </WikusItem>
          <WikusItem spacingDirection='down'>
            <WikusTextField
              group={LoginGroup}
              name="password"
              type="password"
              validator={Required}
              submitted={submitted}
              label={
                <FormattedMessage
                  id="login.password"
                />
              }>
            </WikusTextField>
          </WikusItem>
          <WikusButton type="submit" loading={loading} color="secondary">
            <FormattedMessage
              id="login.button"
            />
          </WikusButton>
        </form>
      </WikusPanel>
    </WikusPageLayout>
  );
});
