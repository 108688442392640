import { IntlShape } from 'react-intl';

// https://stackoverflow.com/a/2901298
export const numberWithDots = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const formatCurrency = (intl: IntlShape, amount: number) =>
  intl.formatNumber(amount, {
    style: 'currency',
    currency: intl.formatMessage({ id: 'currency.name' }),
    currencyDisplay: 'symbol',
  });

export const formatPercentage = (intl: IntlShape, numInDecimals: number) =>
  intl.formatNumber(numInDecimals, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatDate = (intl: IntlShape, date: string) =>
  intl.formatDate(date)
