import { Badge, BadgeProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export interface WikusBadgeProps extends Omit<BadgeProps, 'color'> {
  color?: BadgeProps['color'] | 'grey';
}

export const useStyles = makeStyles((theme: Theme) => ({
  grey: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.grey[500],
      color: '#fff',
    },
  },
}));

export const WikusBadge = ({ color, ...props }: WikusBadgeProps) => {
  const styles = useStyles();
  let className = undefined;
  if (color && (color === 'grey')) {
    className = styles[color];
    color = 'default';
  }

  return (
    <Badge {...props} className={className} color={color} />
  )
}