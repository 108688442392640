import { Box } from '@material-ui/core';
import { BoxProps, Theme } from '@material-ui/core';
import { NewProcessPriceState } from '../../../../model';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { WikusCurrencyLabel } from '../../../../components/core/formatted-label';
import { WikusItem } from '../../../../components/core/spacing';
import { WikusTypography } from '../../../../components/core/typography';
import { createStyles, withStyles, withTheme } from '@material-ui/styles';
import { useIntl } from 'react-intl';

export type TWikusBoxedContentProps = BoxProps & {
  theme?: any;
  label: React.ReactNode;
  children: React.ReactNode;
};

export const WikusBoxedContent = withTheme(
  ({ theme, label, children, ...props }: TWikusBoxedContentProps) => {
    return (
      <Box
        bgcolor={theme?.palette.grey[200]}
        border={`1px solid ${theme?.palette.grey[400]}`}
        borderRadius={5}
        padding={`${theme?.spacing(4)}px ${theme?.spacing(5)}px`}
        {...props}
      >
        {label}
        <WikusItem spacingDirection="up">{children}</WikusItem>
      </Box>
    );
  }
);

const styles = (theme: Theme) =>
  createStyles({
    goal: {
      color: theme.palette.success.main,
      display: 'block',
    },
    above_goal: {
      color: theme.palette.success.main,
      display: 'block',
    },
    below_goal: {
      color: theme.palette.warning.main,
      display: 'block',
    },
    escalation: {
      color: theme.palette.error.main,
      display: 'block',
    },
    root: {},
  });

export interface ArticleSummaryCaptionProps extends TypographyOptions {
  state: NewProcessPriceState;
  diff: {
    [key in NewProcessPriceState]?: number
  };
  classes: any;
}

export const ArticleSummaryCaption = withStyles(styles)(({ classes, state, diff, ...props }: ArticleSummaryCaptionProps) => {
  const intl = useIntl();

  const hiddenStyle: any = diff?.[state]
    ? {}
    : { visibility: 'hidden' };

  return (
    <WikusTypography
      {...props}
      variant="caption"
      classes={{
        root: `${classes?.[state]} ${classes?.root}`,
      }}>
      <p style={{ margin: 0 }}>{
        intl.formatMessage({
          id: `article.priceslider.labels.${state}`,
        })
      }</p>
      <span style={hiddenStyle}>
        {!!diff?.[state] && <WikusCurrencyLabel amount={diff[state] as number} />}
        {intl.formatMessage({ id: diff?.[state] ? `article.priceslider.descriptions.${state}` : `article.priceslider.descriptions.${state}.no-value` })}
      </span>
    </WikusTypography>
  )
});
