import { AccordionClose, AccordionOpen } from '../../../components/core/icons';
import { Customer, NewProcessArticle } from '../../../model';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import { WikusAccordion } from '../../../components/complex/accordion';
import { WikusArticleCard } from '../../../components/complex/article-card';
import { WikusButton } from '../../../components/core/button';
import { WikusItem } from '../../../components/core/spacing';
import { useCallback } from 'react';

import * as ArticleActions from '../../../actions/article';
import { ArticleContent } from './article-content';
import { IconButton } from '@material-ui/core';
import { WikusAlert } from '../../../components/core/alert';
import { filter, map } from 'lodash';
import { useAccordion } from '../../../hooks/useAccordion';
import { useActions } from '../../../actions';

export interface ArticleAccordionProps {
  articles: NewProcessArticle[];
  customer?: Customer;
  canShowContent?: boolean;
}

const ArticleAccordion = ({ articles, customer, canShowContent }: ArticleAccordionProps) => {
  const articleActions: typeof ArticleActions = useActions(ArticleActions);
  const idFn = useCallback((article: NewProcessArticle) => article.article.id, []);
  const {
    toggle,
    next,
    isSelected,
    createItemContainer,
  } = useAccordion<NewProcessArticle>(articles, idFn, {
    canScroll: canShowContent,
    openFirst: !!articles?.length,
  });

  const removeArticle = useCallback(
    (article: NewProcessArticle) => {
      const leftoverArticles = filter(
        map(articles, (a) => a.article),
        (a) => a.id !== article.article.id
      );
      toggle(article);
      setTimeout(() => {
        articleActions.selectArticles(leftoverArticles);
      }, 500);
    },
    [articleActions, articles, toggle]
  );

  return (
    <Fragment>
      {articles?.map((article: NewProcessArticle) => (
        <WikusItem {...createItemContainer(article)} spacingDirection="down">
          <WikusAccordion
            expanded={isSelected(article)}
            onChange={() => toggle(article)}
            buttons={[
              <WikusButton
                onClick={() => removeArticle(article)}
                color="primary"
                variant="outlined"
              >
                <FormattedMessage id="delete" />
              </WikusButton>,
              <WikusButton disabled={!article.isValid} onClick={() => next()} color="secondary">
                <FormattedMessage id="continue" />
              </WikusButton>
            ]}
            summary={
              <WikusArticleCard
                article={article.article}
                customer={customer}
                isEscalation={article.isEscalation}
                status={
                  article.isValid
                    ? 'success'
                    : undefined
                }
                actionItem={
                  <IconButton>
                    {isSelected(article) ? (
                      <AccordionClose />
                    ) : (
                      <AccordionOpen />
                    )}
                  </IconButton>
                }
                size="small"
                elevation={0}
              />
            }
          >
            {canShowContent && <ArticleContent isOpen={isSelected(article)} article={article} />}
            {!canShowContent && <WikusAlert severity="info" title={<FormattedMessage id="article-accordion.please-select-customer" />}></WikusAlert>}
          </WikusAccordion>
        </WikusItem>
      ))}
    </Fragment>
  );
};

export { ArticleAccordion };
