import { AvatarWarningIcon } from './icons';
import { ClassNameMap } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Avatar, { AvatarClassKey, AvatarProps } from '@material-ui/core/Avatar';

const styles = (theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.background.default,
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  icon: {
    // position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255,255,255,0.7)',
  },
});

export interface WikusAvatarClasses extends Partial<ClassNameMap<AvatarClassKey>> {
  small: string;
  large: string;
  icon: string;
}
export interface WikusAvatarProps extends AvatarProps {
  size?: keyof Pick<WikusAvatarClasses, 'small' | 'large'>;
  status?: 'warning';
  classes: WikusAvatarClasses;
}

const WikusAvatar = withStyles(styles)(({ classes, status, size = 'small', ...props }: WikusAvatarProps) => {
  if (!props.children && !props.src) {
    props.children = '?';
  }

  let rootClass = classes?.root;
  if (size) {
    rootClass += ` ${classes?.[size]}`;
  }

  if (status === 'warning') {
    props.children = (
      <>
        {props.children}
        {props.src && <img src={props.src} alt="" {...props.imgProps} />}
        <div style={{ position: 'absolute' }} className={classes?.icon}>
          <AvatarWarningIcon color="error" />
        </div>
      </>
    );
    delete props['imgProps'];
    delete props['src'];
  }

  return (
    <Avatar
      classes={{
        root: rootClass,
      }}
      {...props}>

    </Avatar>
  );
});
WikusAvatar.displayName = 'WikusAvatar';

export {
  WikusAvatar
};