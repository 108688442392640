import { Email } from '../../../components/utils/validators';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { NewProcessRecipient, ProcessActions as ProcessActionsModel, ProcessRecipientType } from '../../../model';
import { RootState } from '../../../reducers';
import { WikusButton, WikusButtonWithIcon } from '../../../components/core/button';
import { WikusItem, WikusSection } from '../../../components/core/spacing';
import { WikusModal, WikusModalProps } from '../../../components/core/modal';
import { WikusPanel } from '../../../components/core/card';
import { WikusTag } from '../../../components/core/tag';
import { WikusTextField } from '../../../components/core/input';
import { filter, find, findIndex, take, takeRight } from 'lodash';
import { useActions } from '../../../actions';
import { useSelector } from 'react-redux';

import * as InputActions from '../../../actions/input';
import * as ProcessActions from '../../../actions/process';
import * as SnackbarEventAction from '../../../actions/snackbarEvent';
import { NewProcessPageType } from '..';
import { useState } from 'react';

import { InputUnstyled } from '../../../components/core/unstyled';
import { Routes } from '../../../routes';
import { SupportedOfferLanguages, languages } from '../../../components/utils/i18n';
import { WikusIsoLanguageSelect } from '../../../components/core/select';
import { useHistory } from 'react-router';
import AddIcon from '@material-ui/icons/ControlPoint';

export interface ConfirmationProps extends Omit<WikusModalProps, 'classes' | 'title'> {
  type: NewProcessPageType
};

export const ConfirmationContentPanel = ({ type }: any) => {
  const intl = useIntl();
  const processActions: typeof ProcessActions = useActions(ProcessActions);
  const inputActions: typeof InputActions = useActions(InputActions);
  const inputGroup: any = useSelector(
    (state: RootState) => state.inputGroup
  );
  const newProcess = useSelector(
    (state: RootState) => state.newProcess
  );
  const recipients = find<NewProcessRecipient>(newProcess.recipients, r => r.type === type);

  const removeRecipient = (recipient: string) => {
    processActions.setRecipients([
      ...filter(newProcess.recipients, r => r.type !== type),
      {
        type,
        recipients: filter(recipients?.recipients, r => r.email !== recipient),
      }
    ]);
  }

  const addRecipient = () => {
    processActions.setRecipients([
      ...filter(newProcess.recipients, r => r.type !== type),
      {
        type,
        recipients: [
          ...(recipients?.recipients || []),
          {
            email: inputGroup[type].value.email,
          }
        ],
      }
    ]);
    inputActions.stateChange('email', '', false, type);
  }

  const changeLanguage = (email: string, language: unknown) => {
    const index = findIndex(recipients?.recipients, r => r.email === email);
    processActions.setRecipients([
      ...filter(newProcess.recipients, r => r.type !== type),
      {
        type,
        recipients: [
          ...take(recipients?.recipients, index),
          {
            email,
            language: (language as string),
          },
          ...takeRight(recipients?.recipients, recipients?.recipients.length ? recipients?.recipients.length - (index + 1) : 0)
        ],
      }
    ]);
  }

  return (
    <WikusPanel
      title={intl.formatMessage({
        id: `confirmation.${type}.title`,
      })}
      description={intl.formatMessage({
        id: `confirmation.${type}.description`,
      })}>
      <WikusItem spacingDirection="down">
        <Grid container>
          <Grid item xs>
            <WikusTextField
              label={intl.formatMessage({
                id: 'email',
              })}
              group={type}
              name="email"
              validator={Email}
              value={inputGroup[type]?.value.email} />
          </Grid>
          <Grid item>
            <WikusButton
              onClick={() => addRecipient()}
              disabled={!inputGroup[type]?.isValid}
              variant="outlined"
              color="primary">
              <FormattedMessage id={'confirm'} />
            </WikusButton>
          </Grid>
        </Grid>
      </WikusItem>
      {!!recipients?.recipients?.length && <Grid container wrap="nowrap" spacing={2} style={{ overflowX: 'auto' }}>
        {recipients.recipients.map((r) => (
          <Grid item key={r.email}>
            <WikusTag
              color="primary"
              label={<WikusIsoLanguageSelect
                name="language"
                input={<InputUnstyled />}
                IconComponent={() => null}
                onChange={(event) => changeLanguage(r.email, event.target.value)}
                only={SupportedOfferLanguages}
                value={r.language}
                defaultValue={r.language}
                displayEmpty={true}
                renderValue={(value) => <>
                  <span>{r.email}</span>
                  <span> | </span>
                  {r.language ? <span>{languages.getName(r.language, intl.locale)}</span> : undefined}
                </>}
              />}
              onDelete={() => removeRecipient(r.email)}
            />
            {/* <WikusTag
              color="primary"
              label={<>
                <span>{r.email}</span>
                <span> | </span>
                {r.language ? <span>{languages.getName(r.language, intl.locale)}</span> : undefined}
              </>}
              onDelete={() => removeRecipient(r.email)}
            /> */}
          </Grid>
        ))}
      </Grid>}
    </WikusPanel>
  );
}

type TConfirmationAddShipmentPanelProps = {
  extraTypes: ProcessRecipientType[];
  addType: (type: string) => void;
}

export const ConfirmationAddShipmentPanel = ({ extraTypes, addType }: TConfirmationAddShipmentPanelProps) => {
  const intl = useIntl();

  return (
    <WikusPanel
      title={intl.formatMessage({
        id: 'confirmation.add-shipment.title',
      })}
      description={intl.formatMessage({
        id: 'confirmation.add-shipment.description',
      })}>
      <WikusItem spacingDirection="down">
        <Grid container justifyContent="center" spacing={2}>
          {extraTypes.map((t) => (
            <Grid item>
              <WikusButtonWithIcon
                icon={AddIcon}
                color="primary"
                onClick={() => addType(t)}>
                <FormattedMessage id={t} />
              </WikusButtonWithIcon>
            </Grid>
          ))}
        </Grid>
      </WikusItem>
    </WikusPanel>
  )
};

export const ConfirmationTextPanel = ({ type }: any) => {
  const intl = useIntl();
  const processActions: typeof ProcessActions = useActions(ProcessActions);
  const newProcess = useSelector(
    (state: RootState) => state.newProcess
  );

  const setMessage = (message: string) => {
    processActions.setMessage(message);
  }

  return (
    <WikusPanel
      title={intl.formatMessage({
        id: 'confirmation.message.title',
      })}
      description={intl.formatMessage({
        id: 'confirmation.message.description',
      })}>
      <WikusItem spacingDirection="down">
        <WikusTextField
          label={intl.formatMessage({
            id: 'message.label',
          })}
          placeholder={intl.formatMessage({
            id: 'message.placeholder',
          })}
          name="message"
          multiline={true}
          minRows={4}
          maxRows={4}
          value={newProcess?.message}
          onChange={({ target: { value } }) => setMessage(value as string)} />
      </WikusItem>
    </WikusPanel>
  );
}

export const ConfirmationContent = () => {
  const [types, setTypes] = useState([ProcessRecipientType.eMail]);
  const [extraTypes, setExtraTypes] = useState([/*ProcessRecipientType.bmeCAT, */ProcessRecipientType.excel]);

  const addType = (type: any) => {
    setTypes((types) => [...types, type]);
    setExtraTypes((types) => types.filter((t) => t !== type));
  }

  return (
    <>
      {types.map(t => (
        <WikusSection spacingDirection="down">
          <ConfirmationContentPanel type={t} />
        </WikusSection>
      ))}
      {!!extraTypes.length && (
        <WikusSection spacingDirection="down">
          <ConfirmationAddShipmentPanel extraTypes={extraTypes} addType={addType} />
        </WikusSection>
      )}
      <WikusSection spacingDirection="down">
        <ConfirmationTextPanel />
      </WikusSection>
    </>
  );
}

export const Confirmation = ({
  type,
  ...props
}: ConfirmationProps) => {
  const history = useHistory();
  const processActions: typeof ProcessActions = useActions(ProcessActions);
  const snackBarEventActions: typeof SnackbarEventAction = useActions(SnackbarEventAction);
  const newProcess = useSelector((state: RootState) => state.newProcess);
  const [skipLoading, setSkipLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'confirmation.title',
  });

  const skipProcess = async () => {
    setSkipLoading(true);
    if (type === 'edit') {
      await updateProcess(true);
    } else if (type === 'new') {
      await createProcess(true);
    }
    setSkipLoading(false);
  }

  const sendProcess = async () => {
    setSendLoading(true);
    if (type === 'edit') {
      await updateProcess();
    } else if (type === 'new') {
      await createProcess();
    }
    setSendLoading(false);
  }

  const updateProcess = async (skipRecipients = false) => {
    const { type } = await processActions.updateProcess(newProcess.id as number, newProcess, skipRecipients);
    if (type !== ProcessActionsModel.UPDATE_PROCESS_SUCCESS) {
      snackBarEventActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'errors.update-process',
        }),
        severity: 'error',
      });
    } else {
      history.replace(Routes.editCompleted);
    }
  }

  const createProcess = async (skipRecipients = false) => {
    const { type } = await processActions.createProcess(newProcess, skipRecipients);
    if (type !== ProcessActionsModel.CREATE_PROCESS_SUCCESS) {
      snackBarEventActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: 'errors.create-process',
        }),
        severity: 'error',
      });
    } else {
      history.replace(Routes.newCompleted);
    }
  }

  return (
    <WikusModal
      {...props}
      title={title}
      buttons={[
        <WikusButton
          color="primary"
          variant="outlined"
          loading={skipLoading}
          onClick={skipProcess}>
          <FormattedMessage id="skip" />
        </WikusButton>,
        <WikusButton
          color="secondary"
          loading={sendLoading}
          onClick={sendProcess}>
          <FormattedMessage id="send" />
        </WikusButton>
      ]}>
      <ConfirmationContent />
    </WikusModal>
  );
};
