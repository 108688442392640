import * as InputActions from '../../actions/input';
import { FormControlLabel, GridSize, Switch, SwitchProps, makeStyles } from '@material-ui/core';
import { ReactNode, useCallback } from 'react';
import { useActions } from '../../actions';

export interface WikusSwitchProps extends Omit<SwitchProps, 'name'> {
  label?: ReactNode;
  name: string;
  group?: string;
  submitted?: boolean;
  xs?: GridSize;
}

const useStyles = makeStyles((theme) => ({
  withoutLabel: {
    marginRight: theme.spacing(2) * -1,
  },
}))

const WikusSwitch = ({ label, submitted, ...props }: WikusSwitchProps) => {
  const classes = useStyles();
  const inputActions: typeof InputActions = useActions(InputActions);

  const onChange = useCallback((event, value) => {
    const isValid = props.required
      ? value === true
      : true;
    inputActions.stateChange(props.name, value, isValid, props.group);
    props.onChange && props.onChange(event, value);
  }, [props.onChange]);

  return (
    <FormControlLabel
      label={label}
      classes={{
        root: label ? '' : classes.withoutLabel,
      }}
      control={<Switch {...props}
        onChange={onChange} />}
    />
  )
}
WikusSwitch.displayName = 'WikusSwitch';

export {
  WikusSwitch
};