import { PatchEscalationRequestBody, ProcessAction, ProcessActions, ProcessQueryParams, SetEscalation } from '../model';

export function fetchInitial(params?: ProcessQueryParams): ProcessAction {
  return {
    type: ProcessActions.FETCH_ESCALATIONS,
    payload: {
      request: {
        url: '/escalations',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMore(params?: ProcessQueryParams): ProcessAction {
  return {
    type: ProcessActions.FETCH_MORE_ESCALATIONS,
    payload: {
      request: {
        url: '/escalations',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchFilter() {
  return {
    type: ProcessActions.FETCH_ESCALATION_FILTER,
    payload: {
      request: {
        url: '/escalations/filter',
        method: 'GET',
      },
    },
  }
}

export function patchEscalation(id: number, patchBody: PatchEscalationRequestBody) {
  return {
    type: ProcessActions.PATCH_ESCALATION,
    payload: {
      request: {
        url: `/escalations/${id}`,
        method: 'PATCH',
        data: patchBody,
      },
    },
  }
}

export function setEscalation(payload: SetEscalation) {
  return {
    type: ProcessActions.SET_ESCALATION,
    payload,
  }
}