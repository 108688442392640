import * as CustomerActions from '../../../actions/customer';
import * as SnackbarEventAction from '../../../actions/snackbarEvent';
import { Customer } from '../../../model';
import { Fragment, useState } from 'react';
import { RootState } from '../../../reducers';
import { WikusCustomerCard } from '../../../components/complex/customer-card';
import { WikusFilter, WikusFilterOptionChangeHandler, WikusFilterOptionType } from '../../../components/complex/filter';
import { WikusInfiniteScroll } from '../../../components/complex/infinite-scroll';
import { WikusItem, WikusSection } from '../../../components/core/spacing';
import {
  WikusModalScrollContainerId
} from '../../../components/core/modal';
import { filter as _filter, includes } from 'lodash';
import { useActions } from '../../../actions';
import { useFilter } from '../../../hooks/useFilter';
import { useIntl } from 'react-intl';
import { usePaginate } from '../../../hooks/usePaginate';
import { useSelector } from 'react-redux';

interface CustomerSelectionFilterProps {
  onChange?: WikusFilterOptionChangeHandler
}

const CustomerSelectionFilter = ({ onChange }: CustomerSelectionFilterProps) => {
  const intl = useIntl();
  const { isLoading, filter } = useFilter<typeof CustomerActions>(CustomerActions, [{
    type: WikusFilterOptionType.text,
    props: {
      name: 'customerNumber',
    },
  }, {
    type: WikusFilterOptionType.text,
    props: {
      name: 'name',
    },
  }], 'customer');

  const filterTitle = intl.formatMessage({
    id: 'customer-selection.filter.title',
  });
  const filterDescription = intl.formatMessage({
    id: 'customer-selection.filter.description',
  });

  return (
    <WikusFilter
      title={filterTitle}
      description={filterDescription}
      isLoading={isLoading}
      options={_filter(filter, f => !includes(['categoryClassification'], f.props.name))}
      onChange={onChange}
      order={['name', 'customerNumber', 'group', 'salesManagerId', 'responsibleId']} />
  );
}

interface ExistingCustomerSelectionProps {
  onCustomerSelected?: (customer: Customer) => void;
}

const ExistingCustomerSelection = ({ onCustomerSelected }: ExistingCustomerSelectionProps) => {
  const intl = useIntl();
  const [filters, setFilters] = useState({});
  const { fetchMore, isLoading } = usePaginate<typeof CustomerActions>({
    actions: CustomerActions,
    filters,
  });
  const customerActions: typeof CustomerActions = useActions(CustomerActions);
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );

  const customers: Customer[] = useSelector(
    (state: RootState) => state.customers
  );
  const customersCount: number = useSelector(
    (state: RootState) => state.customersCount
  );

  const selectCustomer = (customer: Customer) => {
    customerActions.selectCustomer(customer);
    onCustomerSelected && onCustomerSelected(customer);
    snackbarActions.addSnackbarEvent({
      message: intl.formatMessage({
        id: 'success.select-customer',
      }),
      severity: 'success',
    });
  };

  return (
    <Fragment>
      <WikusSection spacingDirection="down">
        <CustomerSelectionFilter onChange={setFilters} />
      </WikusSection>
      <WikusInfiniteScroll
        scrollableTarget={WikusModalScrollContainerId}
        skeletonHeight={200}
        skeletonCount={10}
        dataLength={customers?.length}
        next={fetchMore}
        hasMore={customersCount > customers?.length}
        isLoading={isLoading}
      >
        {customers?.map((customer) => (
          <WikusItem spacingDirection="down" key={customer.id}>
            <WikusCustomerCard
              customer={customer}
              size="large"
              onSelect={() => selectCustomer(customer)} />
          </WikusItem>
        ))}
      </WikusInfiniteScroll>
    </Fragment>
  )
}

export {
  ExistingCustomerSelection
};