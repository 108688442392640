import { ArticleQueryParams } from './article';
import { FilterItem } from './misc';
import { RequestPayload, ResponsePayload } from './request';

export enum CustomerActions {
  FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
  FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS',
  FETCH_MORE_CUSTOMERS = 'FETCH_MORE_CUSTOMERS',
  FETCH_MORE_CUSTOMERS_SUCCESS = 'FETCH_MORE_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL',
  FETCH_FILTER = 'CUSTOMER:FETCH_FILTER',
  FETCH_FILTER_SUCCESS = 'CUSTOMER:FETCH_FILTER_SUCCESS',
  FETCH_DETAILS = 'FETCH_DETAILS',
  FETCH_DETAILS_SUCCESS = 'FETCH_DETAILS_SUCCESS',
  SELECT_CUSTOMER = 'SELECT_CUSTOMER',
  FIND_PRICE_REGION_BY_COUNTRY = 'FIND_PRICE_REGION_BY_COUNTRY',
  FIND_CLASSIFICATIONS_BY_CATEGORY = 'FIND_CLASSIFICATIONS_BY_CATEGORY'
}

export interface CustomerQueryParams extends ArticleQueryParams { }

interface CustomerActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export enum DiscountType {
  BasicDiscount = '1',
  SpecialDiscount = '2'
}

export interface Discount {
  customerNumber: string;
  articleNumberOrGroup: string;
  discountType: DiscountType;
  discount: number;
}

export interface DiscountItem {
  item: Discount;
  additionals: Discount[];
}

export interface DiscountGroup {
  group: DiscountItem;
  items: DiscountItem[];
}

export interface Customer {
  id?: number;
  customerNumber?: string;
  name: string;
  group?: string;
  groupName?: string;

  address?: string;
  addressName?: string;
  addressNameAdditional?: string;
  addressStreet?: string;
  addressCity?: string;
  addressZip?: string;
  addressCountry?: string;
  addressLanguage?: string;
  languageId?: string;

  salesManagerId: string;
  salesManagerName?: string;
  responsibleName?: string;
  responsibleId?: string;
  priceRegionName?: string;

  categoryName?: string;
  classificationName?: string;

  paymentInformation?: string;
  discountInformation?: string;

  priceRegionDiscount?: number;
  discount: number;
  escalationThreshold?: number;

  imageUrl?: string;

  discountGroups?: DiscountGroup[];
}
export interface NewCustomer extends Customer {
  name: string;
  addressName: string;
  addressNameAdditional?: string;
  addressStreet: string;
  addressCity: string;
  addressArea: string;
  addressZip: string;
  addressCountry: string;
  addressLanguage: string;
  phone: string;
  email: string;
  invoiceEmail: string;
  homepage: string;
  contactName: string;
  invoiceAddressName: string;
  invoiceAddressNameAdditional?: string;
  invoiceAddressStreet: string;
  invoiceAddressCity: string;
  invoiceAddressZip: string;
  invoiceAddressCountry: string;
  shippingAddressName: string;
  shippingAddressNameAdditional?: string;
  shippingAddressStreet: string;
  shippingAddressCity: string;
  shippingAddressZip: string;
  shippingAddressCountry: string;
  supplierNumber: string;
  company: string;

  // wikus data 
  areaId?: string;
  areaName?: string;
  priceRegion: string;
  priceRegionName: string;
  responsibleId: string;
  responsibleName: string;
  salesManagerId: string;
  salesManagerName: string;
  category: string;
  categoryName: string;
  classification: string;
  classificationName: string;
  priority: string;

  // shipment
  shippingInformation: string;
  paymentInformation: string;
  ustId: string;
  singlePackaging: boolean;
  discount: number;

  ccEmail?: string;
}

export interface CustomersFilter {
  group: FilterItem[],
  salesManagerId: FilterItem[],
  category: FilterItem[],
  classification: FilterItem[],
  categoryClassification: FilterItem[],
  priceRegion: FilterItem[],
  responsibleId: FilterItem[],
  areaId: FilterItem[],
  languageId: FilterItem[],
  country: FilterItem[],
}

export interface FetchSuccessCustomerPayload {
  items: Customer[];
  count: number;
}

export type CustomerAction =
  | CustomerActionType<typeof CustomerActions.FETCH_CUSTOMERS, RequestPayload>
  | CustomerActionType<typeof CustomerActions.FETCH_CUSTOMERS_SUCCESS, ResponsePayload<FetchSuccessCustomerPayload>>
  | CustomerActionType<typeof CustomerActions.FETCH_MORE_CUSTOMERS, RequestPayload>
  | CustomerActionType<typeof CustomerActions.FETCH_MORE_CUSTOMERS_SUCCESS, ResponsePayload<FetchSuccessCustomerPayload>>
  | CustomerActionType<typeof CustomerActions.FETCH_CUSTOMERS_FAIL, null>
  | CustomerActionType<typeof CustomerActions.FETCH_FILTER, RequestPayload>
  | CustomerActionType<typeof CustomerActions.FETCH_FILTER_SUCCESS, ResponsePayload<CustomersFilter>>
  | CustomerActionType<typeof CustomerActions.FETCH_DETAILS, RequestPayload>
  | CustomerActionType<typeof CustomerActions.FETCH_DETAILS_SUCCESS, ResponsePayload<Customer>>
  | CustomerActionType<typeof CustomerActions.SELECT_CUSTOMER, Customer>
  | CustomerActionType<typeof CustomerActions.FIND_PRICE_REGION_BY_COUNTRY, RequestPayload>
  | CustomerActionType<typeof CustomerActions.FIND_CLASSIFICATIONS_BY_CATEGORY, RequestPayload>;
