import { ClassNameMap } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { GridSize, InputAdornment, Theme } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { WikusItem } from './spacing';
import { useCallback } from 'react';
import { useInputGroup } from '../../hooks/useInputGroup';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import TextField, {
  StandardTextFieldProps,
  TextFieldClassKey
} from '@material-ui/core/TextField';

const styles = (theme: Theme) => {
  const borderColor = theme.palette.grey[400];
  const border = {
    borderColor,
    ...(theme.overrides?.MuiOutlinedInput?.notchedOutline || {}),
  };

  return {
    root: {
      background: theme.palette.background.paper,
      '& .MuiFormHelperText-root': {
        lineHeight: 1,
      },
    },
    cssLabel: {
      '&$cssFocused': {
        color: theme.palette.grey[500],
      },
    },
    cssFocused: {
      '&:hover': {
        border,
      },
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': border,
      '&:hover $notchedOutline': border,
    },
    notchedOutline: {
      borderColor,
      '&:hover': border,
    },
  };
};

export interface WikusTextFieldClasses
  extends Partial<ClassNameMap<TextFieldClassKey>> {
  cssLabel?: string;
  cssFocused?: string;
  cssOutlinedInput?: string;
  notchedOutline?: any;
}

export interface IOnValidatedPayload {
  name: string;
  isValid: boolean;
}

export type InputValidator = (value?: unknown) => string | undefined;

export type InputOnValidatedHandler = (payload: IOnValidatedPayload) => void;

export interface WikusTextFieldProps extends StandardTextFieldProps {
  xs?: boolean | GridSize | undefined;
  classes?: WikusTextFieldClasses;
  intl?: any;
  validator?: InputValidator;
  onValidated?: InputOnValidatedHandler;
  submitted?: boolean;
  noStateChange?: boolean;
  name: string;
  group?: string;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
}

type TWikusInputAdornmentProps = {
  position: 'start' | 'end';
  icon: React.ReactNode;
};

const WikusInputAdornment = ({ position, icon }: TWikusInputAdornmentProps) => {
  return <InputAdornment position={position}>{icon}</InputAdornment>;
};

const WikusTextField = withStyles(styles)(
  ({
    classes,
    type,
    submitted,
    validator,
    onValidated,
    noStateChange,
    ...props
  }: WikusTextFieldProps) => {
    const { valueChanged, value } = useInputGroup(props.group);
    const [validationMessage, setValidationMessage] = useState('');
    const intl = useIntl();

    const onChange = useCallback((event) => {
      const value = event.target.value;

      update(value);

      if (props.onChange) {
        props.onChange(event);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const update = useCallback((value: any) => {
      if (noStateChange) {
        return;
      }

      const message = valueChanged(props.name, value, validator);
      setValidationMessage(message || '');

      if (validator) {
        onValidated && onValidated({ name: props.name, isValid: !message });
      }
    }, [validator, valueChanged, props.name]);

    useEffect(() => {
      update(props.value);
    }, [props.value, update]);

    useEffect(() => {
      update(props.value || '');
    }, []);

    const hasError = !!(submitted && validationMessage);
    const helperText =
      hasError &&
      intl.formatMessage({
        id: validationMessage,
      });

    if (type === 'date') {
      delete props['label'];
    }

    return (
      <TextField
        {...props}
        error={hasError || props.error}
        helperText={helperText
          ? <p style={{ marginTop: 5, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: helperText }}></p>
          : props.helperText}
        style={{ width: '100%' }}
        classes={{
          root: classes?.root,
        }}
        InputLabelProps={{
          classes: {
            root: classes?.cssLabel,
            focused: classes?.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes?.cssOutlinedInput,
            focused: classes?.cssFocused,
            notchedOutline: classes?.notchedOutline,
          },
          autoComplete: 'new-password',
          ...(props.InputProps || {}),
          ...(props.icon && {
            ...(props.iconPosition === 'start' && {
              startAdornment: (
                <WikusInputAdornment
                  position={props.iconPosition}
                  icon={props.icon}
                />
              ),
            }),
            ...(props.iconPosition === 'end' && {
              endAdornment: (
                <WikusInputAdornment
                  position={props.iconPosition}
                  icon={props.icon}
                />
              ),
            }),
          }),
        }}
        type={type ? type : 'text'}
        value={value?.[props.name] || ''}
        onChange={onChange}
        variant="outlined"
      />
    );
  }
);
// set for storybook to render html preview correctly
WikusTextField.displayName = 'WikusTextField';

export interface WikusInputRowNode extends ReactElement<WikusTextFieldProps> { }

export interface WikusInputRowProps {
  children?: (WikusInputRowNode | undefined)[] | WikusInputRowNode;
}

const WikusInputRow = ({ children }: WikusInputRowProps) => (
  <WikusItem spacingDirection="down">
    <Grid container spacing={2}>
      {Array.isArray(children)
        ? children.filter(child => child).map((child, index) => {
          return (
            <Grid key={index} item xs={(child as WikusInputRowNode).props.xs}>
              {child}
            </Grid>
          );
        })
        : children && <Grid item xs>
          {children}
        </Grid>
      }
    </Grid>
  </WikusItem>
);
WikusInputRow.displayName = 'WikusInputRow';

export { WikusTextField, WikusInputRow };
