export const Routes = {
  dashboard: '/',
  login: '/login',
  newProcess: '/new-process',
  editProcess: '/edit-process',
  simulation: '/simulation',
  myProcesses: '/my-processes',
  myDrafts: '/my-drafts',
  escalations: '/escalations',
  newCompleted: '/new-completed',
  editCompleted: '/edit-completed',
  feedback: '/feedback',
  userAdmin: '/user-admin',
}