import { findIndex } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export interface UseAccordionOptions {
  openFirst?: boolean;
  canScroll?: boolean;
}

export const useAccordion = <T>(items: T[], idFn: (item: T) => number, { openFirst, canScroll }: UseAccordionOptions = { openFirst: true, canScroll: true }) => {
  const [expanded, setExpanded] = useState<number | null>(openFirst ? idFn(items[0]) : null);
  const [scroll, setScroll] = useState<number | null | undefined>(openFirst ? idFn(items[0]) : null);

  const delayedScroll = useCallback((id: number) => {
    setTimeout(() => {
      setScroll(id);
    }, 1000);
  }, [setScroll])

  const toggle = useCallback((item: any) => {
    const currentId = idFn(item);
    if (expanded === currentId) {
      delayedScroll(idFn(items[0]));
      setExpanded(null);
      return;
    }

    delayedScroll(currentId);
    setExpanded(currentId);
  }, [expanded, delayedScroll, items, idFn]);

  const next = useCallback(() => {
    const index = findIndex(items, i => idFn(i) === expanded);
    if (index === items?.length - 1) {
      delayedScroll(idFn(items[0]));
      setExpanded(null);
      return;
    }

    if (index !== -1) {
      setScroll(idFn(items[index]));
      toggle(items[index + 1]);
    }
  }, [toggle, delayedScroll, expanded, items, idFn]);

  const isSelected = useCallback((item: any) => {
    return expanded === idFn(item);
  }, [expanded, idFn]);

  const createItemContainer = useCallback((item: any) => {
    return {
      id: `item-${idFn(item)}`,
      key: idFn(item),
    }
  }, [idFn]);

  useEffect(() => {
    if (scroll && canScroll) {
      const id = `#item-${scroll}`;
      const node = document.querySelector(id);
      if (node) {
        const behavior = scroll !== expanded
          ? 'auto'
          : 'smooth';
        node.scrollIntoView({ behavior, block: 'start' });
        setScroll(null);
      }
    }
  }, [scroll, canScroll, idFn]);

  return {
    toggle,
    next,
    isSelected,
    createItemContainer,
  };
}