import { Customer } from '../../model/customer';
import { FormattedMessage, useIntl } from 'react-intl';
import { MouseEventHandler } from 'react';
import { TWikusModelCardProps, WikusModelCard } from './model-card';
import { WikusAvatar } from '../core/avatar';
import { WikusButton } from '../core/button';
import { WikusDetailRowItem, WikusDetailRowItemVariant } from './detail-block';
import { getInitials } from '../../utils';
import { map } from 'lodash';

export type WikusCustomerCardSize = 'small' | 'large';

export interface WikusCustomerCardProps
  extends Omit<
  TWikusModelCardProps,
  'avatar' | 'title' | 'details' | 'detailsVariant' | 'tags' | 'size'
  > {
  customer: Customer;
  size: WikusCustomerCardSize;
  onSelect?: MouseEventHandler;
  onChange?: MouseEventHandler;
  showButtons?: boolean;
}

type DetailItemType = {
  [key in WikusCustomerCardSize]: {
    variant?: WikusDetailRowItemVariant;
    items: (keyof Customer)[];
  };
};

const DetailItems: DetailItemType = {
  small: {
    variant: undefined,
    items: ['categoryName', 'classificationName', 'salesManagerName'],
  },
  large: {
    variant: 'equal',
    items: [
      'customerNumber',
      'categoryName',
      'salesManagerName',
      'responsibleName',
      'classificationName',
      'address'
    ],
  },
};

const WikusCustomerCard = ({
  customer,
  size,
  onSelect,
  onChange,
  showButtons = true,
  ...props
}: WikusCustomerCardProps) => {
  const intl = useIntl();
  const { variant: detailRowVariant, items } = DetailItems[size];
  const detailRowItems: WikusDetailRowItem[] = map(items, (p) => ({
    label: intl.formatMessage({
      id: `customer.${p}.label`,
    }),
    value: customer[p],
  })) as WikusDetailRowItem[];
  const avatarShort = getInitials(customer.name);
  const buttons =
    size === 'small'
      ? props.buttons
      : [
        <WikusButton onClick={onSelect} color="secondary">
          <FormattedMessage id="choose"></FormattedMessage>
        </WikusButton>
      ];
  const actionItem =
    size === 'large' ? (
      props.actionItem
    ) : props.actionItem || (
      <WikusButton onClick={onChange} color="primary" variant="outlined">
        <FormattedMessage id="change"></FormattedMessage>
      </WikusButton>
    );

  return (
    <WikusModelCard
      {...props}
      title={customer.name}
      avatar={
        <WikusAvatar size={size} src={customer.imageUrl}>
          {avatarShort}
        </WikusAvatar>
      }
      details={detailRowItems}
      detailsVariant={detailRowVariant}
      actionItem={actionItem}
      size={size}
      {...(showButtons && {
        buttons,
      })}
    />
  );
};
WikusCustomerCard.displayName = 'WikusCustomerCard';

export { WikusCustomerCard };
