import { Article, ArticleAction, ArticleActions, ArticlesFilter, FetchSuccessArticlesPayload } from '../model/article';
import { Process, ProcessAction, ProcessActions } from '../model';
import { ResponsePayload } from '../model/request';
import { map, uniqBy } from 'lodash';
import createReducer from './createReducer';

export const articles = createReducer<Article[]>([], {
  [ArticleActions.FETCH_ARTICLES_SUCCESS](state: Article[], action: ArticleAction) {
    return (action.payload as ResponsePayload<FetchSuccessArticlesPayload>).data.items;
  },
  [ArticleActions.FETCH_ARTICLES](state: Article[], action: ArticleAction) {
    return null;
  },
  [ArticleActions.FETCH_MORE_ARTICLES_SUCCESS](state: Article[], action: ArticleAction) {
    return uniqBy([
      ...(state || []),
      ...(action.payload as ResponsePayload<FetchSuccessArticlesPayload>).data.items
    ], a => a.id);
  },
});

export const articlesCount = createReducer<number>(0, {
  [ArticleActions.FETCH_ARTICLES_SUCCESS](state: string, action: ArticleAction) {
    return (action.payload as ResponsePayload<FetchSuccessArticlesPayload>).data.count;
  },
});

export const articlesFilter = createReducer<ArticlesFilter | null>(null, {
  [ArticleActions.FETCH_FILTER_SUCCESS](
    state: ArticlesFilter | null,
    action: ArticleAction
  ) {
    return (action.payload as ResponsePayload<ArticlesFilter>).data;
  },
})

export const selectedArticles = createReducer<Article[]>([], {
  [ArticleActions.SELECT_ARTICLES](state: Article[], action: ArticleAction) {
    return action.payload as Article[];
  },
  [ProcessActions.EDIT_PROCESS](state: Article[], action: ProcessAction) {
    const process = action.payload as Process;
    return map(process.articles, a => a.article);
  },
  [ProcessActions.CLEAR_NEW_PROCESS](state: Article[], action: ProcessAction) {
    return [];
  },
})