import { Grid } from '@material-ui/core';
import { MouseEventHandler } from 'react';
import { WikusAvatar } from '../../../components/core/avatar';
import { WikusButton } from '../../../components/core/button';
import { WikusItem } from '../../../components/core/spacing';
import { WikusPanel } from '../../../components/core/card';
import { WikusTypography } from '../../../components/core/typography';
import { useIntl } from 'react-intl';

export interface EmptyCardProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  name: string;
}

export const EmptyCard = ({ onClick, name }: EmptyCardProps) => {
  const intl = useIntl();
  const text = intl.formatMessage({
    id: `empty-card.${name}.text`,
  });
  const buttonText = intl.formatMessage({
    id: `empty-card.${name}.button`,
  });

  return (
    <WikusPanel size="small" onClick={onClick}>
      <Grid container alignItems="center">
        <Grid item>
          <WikusAvatar>?</WikusAvatar>
        </Grid>

        <Grid item xs>
          <WikusItem spacingDirection="horizontal">
            <WikusTypography variant="h2" color="primary">
              {text}
            </WikusTypography>
          </WikusItem>
        </Grid>

        <Grid item>
          <WikusButton color="secondary">
            {buttonText}
          </WikusButton>
        </Grid>
      </Grid>
    </WikusPanel>
  );
};