import { InputBase, withStyles } from '@material-ui/core';
import { auto } from '@popperjs/core';

export const InputUnstyled = withStyles((theme) => ({
  root: {
    height: auto,
    color: 'inherit',
    fontSize: 'inherit',
  },
  // cssOutlinedInput: {
  //   '&$cssFocused $notchedOutline': { border: 'none' },
  //   '&:hover $notchedOutline': { border: 'none' },
  // },
  input: {
    padding: 0,
    paddingRight: '0 !important',
    border: 'none',
    backgroundColor: 'transparent !important',
  },
}))(InputBase);