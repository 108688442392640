import { InputValidator, WikusInputRow, WikusTextField } from '../core/input'
import { Required, RequiredAutocomplete } from '../utils/validators';
import { WikusCountryAutocomplete, WikusLanguageAutocomplete, WikusStateAutocomplete } from '../core/autocomlete';
import { postcodeValidator } from 'postcode-validator';
import { useCallback } from 'react';
import { useInputGroup } from '../../hooks/useInputGroup';
import { useIntl } from 'react-intl';

export interface LocationInputGroupProps {
  group?: string;
  submitted?: boolean;
  prefix?: string;
  withoutLanguage?: boolean;
}

const WikusLocationInputGroup = ({ group, submitted, prefix, withoutLanguage }: LocationInputGroupProps) => {
  const intl = useIntl();
  const { value, getValue } = useInputGroup(group);
  const createProps = useCallback((n: string) => {
    const name = prefix
      ? `${prefix}${n[0].toUpperCase()}${n.slice(1)}`
      : n;
    const label = intl.formatMessage({ id: `location-input-group.${name}.label` });
    return {
      name,
      label,
      submitted,
      value: getValue(name),
    }
  }, [prefix, intl, submitted, getValue]);

  const zipValidator: InputValidator = useCallback((crnt: unknown) => {
    const countryValue = value?.[createProps('country').name];
    if (!countryValue?.id || !crnt) {
      return Required(crnt);
    }

    return postcodeValidator(crnt as string, countryValue.id)
      ? undefined
      : 'error.zip'
  }, [value?.[createProps('country').name]]);

  return (
    <>
      <WikusInputRow>
        <WikusTextField
          group={group}
          validator={Required}
          {...createProps('street')} />
      </WikusInputRow>
      <WikusInputRow>
        <WikusTextField
          group={group}
          validator={zipValidator}
          xs={6}
          {...createProps('zip')} />
        <WikusTextField
          group={group}
          validator={Required}
          xs={6}
          {...createProps('city')} />
      </WikusInputRow>
      <WikusInputRow>
        <WikusCountryAutocomplete
          group={group}
          validator={RequiredAutocomplete}
          xs={withoutLanguage ? 6 : 4}
          {...createProps('country')} />
        <WikusStateAutocomplete
          group={group}
          validator={RequiredAutocomplete}
          xs={withoutLanguage ? 6 : 4}
          countryCode={value?.[createProps('country').name]?.id}
          {...createProps('area')} />
        {!withoutLanguage ? <WikusLanguageAutocomplete
          group={group}
          validator={RequiredAutocomplete}
          xs={4}
          {...createProps('language')} /> : undefined}
      </WikusInputRow>
    </>
  )
}

export {
  WikusLocationInputGroup
};