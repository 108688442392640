import * as ProcessActions from '../../../../actions/process';
import { ArticleSummaryCaption, WikusBoxedContent } from './common';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { RequiredStrict } from '../../../../components/utils/validators';
import { TArticleContentProps } from './';
import { Theme, createStyles } from '@material-ui/core';
import { WikusAlert } from '../../../../components/core/alert';
import { WikusCurrencyLabel } from '../../../../components/core/formatted-label';
import { WikusDetailBlock } from '../../../../components/complex/detail-block';
import {
  WikusHeroTypography,
  WikusTypography
} from '../../../../components/core/typography';
import { WikusItem, WikusSection } from '../../../../components/core/spacing';
import { WikusMaskedNumberTextField } from '../../../../components/core/masked-input';
import { useActions } from '../../../../actions';
import { useState } from 'react';
import { withStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    parent: {
      alignItems: 'flex-end',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
      },
    },
    center: {
      alignSelf: 'center',
    },
    quantityInput: {
      border: 'none',
    },
    price: {
      fontWeight: 400,
      fontSize: '2.4rem',
      color: theme.palette.grey[500],
    },
  });

export const ArticleContentSummary = withStyles(styles)(
  ({ article, classes, inputGroupName }: TArticleContentProps) => {
    const processActions: typeof ProcessActions = useActions(ProcessActions);
    const [unitValue, setUnitValue] = useState(article.unitCount);

    const intl = useIntl();
    const title = intl.formatMessage({
      id: 'summary',
    });

    const setUnitCount = (formattedValue: any) => {
      processActions!.setUnitCount(article.article.id, formattedValue);
    };

    return (
      <>
        <WikusDetailBlock title={title} />
        <Grid container classes={{ root: classes.parent }}>
          <Grid item sm>
            <WikusBoxedContent
              label={
                <WikusTypography isBold>
                  <FormattedMessage id={`article.${article.type}.summary.amountLabel`} />
                </WikusTypography>
              }
            >
              <WikusMaskedNumberTextField
                name="quantity"
                group={inputGroupName!}
                value={unitValue}
                validator={RequiredStrict}
                isAllowed={value => value.floatValue === undefined || (value.floatValue >= 0 && value.value !== '-')}
                submitted={true}
                runWithValue={setUnitCount}
                InputProps={{
                  classes: {
                    // Warum darf ich laut TS die Klasse nicht vergeben?
                    // @ts-ignore
                    notchedOutline: classes.quantityInput,
                  },
                }}
              />
            </WikusBoxedContent>
          </Grid>

          <Grid item sm={1} classes={{ root: classes.center }}>
            <WikusItem spacingDirection="horizontal">
              <WikusTypography variant="h2" isBold>
                X
              </WikusTypography>
            </WikusItem>
          </Grid>
          <Grid item sm>
            <WikusTypography isBold>
              {intl.formatMessage({ id: `article.${article.type}.summary.netPricePerPiece` })}
            </WikusTypography>
            <WikusItem spacingDirection="vertical">
              <WikusHeroTypography classes={{ root: classes.price }}>
                <WikusCurrencyLabel amount={article.netPerUnit} />
              </WikusHeroTypography>
            </WikusItem>
            <ArticleSummaryCaption
              state={article.state}
              diff={{
                'escalation': article.escalationThresholdInCent - article.netPerUnit,
                'goal': article.aboveTargetThresholdInCent - article.netPerUnit,
                'below_goal': article.targetNetPerUnit - article.netPerUnit,
              }} />
          </Grid>
          <Grid item sm={1} classes={{ root: classes.center }}>
            <WikusItem spacingDirection="horizontal">
              <WikusTypography variant="h1" isBold>
                =
              </WikusTypography>
            </WikusItem>
          </Grid>
          <Grid item sm>
            <WikusTypography isBold>
              {intl.formatMessage({ id: 'totalPrice' })}
            </WikusTypography>
            <WikusItem spacingDirection="vertical">
              <WikusHeroTypography classes={{ root: classes.price }}>
                {article.unitCount
                  ? <WikusCurrencyLabel amount={article.amount ?? 0} />
                  : <span>-</span>
                }
              </WikusHeroTypography>
            </WikusItem>
            <ArticleSummaryCaption
              state={article.state}
              diff={{
                'escalation': (article.escalationThresholdInCent - article.netPerUnit) * (article.unitCount || 0),
                'goal': (article.aboveTargetThresholdInCent - article.netPerUnit) * (article.unitCount || 0),
                'below_goal': (article.targetNetPerUnit - article.netPerUnit) * (article.unitCount || 0),
              }} />
          </Grid>
        </Grid>
        {
          !article.isValid && <WikusSection spacingDirection="up">
            <WikusAlert
              severity="error"
              title={<FormattedMessage id={`article.invalid.${article.type}`} />}>
            </WikusAlert>
          </WikusSection>
        }
      </>
    );
  }
);
