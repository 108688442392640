import * as EscalationActions from '../../actions/escalation';
import { EscalationAccordion } from './components/escalation-accordion';
import { EscalationFilter } from './components/escalation-filter';
import { RootState } from '../../reducers';
import { WikusInfiniteScroll } from '../../components/complex/infinite-scroll';
import { WikusSection } from '../../components/core/spacing';
import { useIntl } from 'react-intl';
import { usePaginate } from '../../hooks/usePaginate';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import WikusPageLayout from '../../components/PageLayout';

export const EscalationsPage = () => {
  const EscalationsPerPage = 20;
  const intl = useIntl();
  const escalations = useSelector((state: RootState) => state.escalations);
  const escalationsCount = useSelector((state: RootState) => state.escalationsCount);
  const [filters, setFilters] = useState({});

  const { fetchMore, isLoading: isPaginationLoading } = usePaginate<typeof EscalationActions>({
    itemsPerPage: EscalationsPerPage,
    actions: EscalationActions,
    filters,
  });

  return (
    <WikusPageLayout
      title={intl.formatMessage({ id: 'escalations.title' })}>
      <WikusSection spacingDirection="down">
        <EscalationFilter onChange={setFilters} />
      </WikusSection>
      <WikusInfiniteScroll
        skeletonHeight={100}
        skeletonCount={EscalationsPerPage}
        dataLength={escalations?.length}
        next={fetchMore}
        hasMore={!escalationsCount || escalationsCount > escalations?.length}
        isLoading={isPaginationLoading}
      >
        <EscalationAccordion escalations={escalations} />
      </WikusInfiniteScroll>
    </WikusPageLayout>
  );
};
