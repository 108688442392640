import * as FeedbackActions from '../../../actions/feedback';
import { AccordionClose, AccordionOpen, UpvoteIcon } from '../../../components/core/icons';
import { Feedback, FeedbackStatus } from '../../../model'
import { FeedbackAccordionContent } from './feedback-accordion-content';
import { IconButton } from '@material-ui/core';
import { MouseEventHandler, useCallback, useState } from 'react';
import { RootState } from '../../../reducers';
import { TWikusModelCardProps, WikusModelCard } from '../../../components/complex/model-card';
import { WikusAccordion } from '../../../components/complex/accordion';
import { WikusBadge } from '../../../components/core/badge';
import { WikusDateLabel } from '../../../components/core/formatted-label';
import { WikusItem } from '../../../components/core/spacing';
import { WikusTag } from '../../../components/core/tag';
import { some } from 'lodash';
import { useAccordion } from '../../../hooks/useAccordion';
import { useActions } from '../../../actions';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export interface FeedbackItemProps extends Omit<TWikusModelCardProps, 'title' | 'avatar' | 'details'> {
  item: Feedback;
}

export const FeedbackItem = ({ item, ...props }: FeedbackItemProps) => {
  const intl = useIntl();
  const [isUpvoting, setIsUpvoting] = useState(false);
  const user = useSelector((rootState: RootState) => rootState.user);
  const hasUpvoted = some(item.upvotes, u => u.user?.id === user.id);
  const feedbackActions: typeof FeedbackActions = useActions(
    FeedbackActions
  );

  const toggleUpvote: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    if (isUpvoting) {
      return;
    }

    setIsUpvoting(true);
    await feedbackActions.toggleUpvote(item.id);
    setIsUpvoting(false);
  }

  return (
    <WikusModelCard
      {...props}
      title={item.title}
      tags={[
        <WikusTag
          color={item.status === FeedbackStatus.Open ? 'primary' : undefined}
          label={intl.formatMessage({
            id: `feedback.${item.status}`,
          })}
        ></WikusTag>
      ]}
      elevation={0}
      avatar={<IconButton disabled={isUpvoting} onClick={toggleUpvote}>
        <WikusBadge badgeContent={item.upvotes.length} color={hasUpvoted ? 'primary' : 'grey'}>
          <UpvoteIcon isUpvoted={hasUpvoted} />
        </WikusBadge>
      </IconButton>}
      detailsVariant="equal-start"
      details={[{
        label: intl.formatMessage({ id: 'feedback-item.author' }),
        value: item.user?.name || intl.formatMessage({ id: 'feedback.no-user' }),
      }, {
        label: intl.formatMessage({ id: 'feedback-item.date' }),
        value: <WikusDateLabel date={item.createdAt} />,
      }]}>
    </WikusModelCard>
  )
}

export interface FeedbackAccordionProps {
  feedback: Feedback[];
}

export const FeedbackAccordion = ({ feedback }: FeedbackAccordionProps) => {
  const idFn = useCallback((item: Feedback) => item.id, []);
  const {
    toggle,
    isSelected,
    createItemContainer,
  } = useAccordion<Feedback>(feedback, idFn, {
    canScroll: false,
    openFirst: false,
  });

  return (
    <>
      {feedback?.map((item, index) => (
        <WikusItem {...createItemContainer(item)} spacingDirection="down">
          <WikusAccordion
            expanded={isSelected(item)}
            onChange={() => toggle(item)}
            summary={
              <FeedbackItem
                item={item}
                actionItem={
                  <IconButton>
                    {isSelected(item) ? (
                      <AccordionClose />
                    ) : (
                      <AccordionOpen />
                    )}
                  </IconButton>
                } />}>
            <FeedbackAccordionContent item={item} />
          </WikusAccordion>
        </WikusItem>))}
    </>

  )
}