import { NewProcess, NewProcessRecipient, Process, ProcessAction, ProcessActions, ProcessQueryParams } from '../model';
import { map, omit } from 'lodash';

export function setPrice(articleId: number, price: number): ProcessAction {
  return {
    type: ProcessActions.SET_PRICE,
    payload: {
      articleId,
      price,
    },
  };
}

export function setDeviation(articleId: number, deviation: number): ProcessAction {
  return {
    type: ProcessActions.SET_DEVIATION,
    payload: {
      articleId,
      deviation,
    },
  };
}

export function setLengthInMeters(articleId: number, lengthInMeters: number): ProcessAction {
  return {
    type: ProcessActions.SET_LENGTH_IN_METERS,
    payload: {
      articleId,
      lengthInMeters,
    },
  };
}

export function setUnitCount(articleId: number, unitCount: number): ProcessAction {
  return {
    type: ProcessActions.SET_UNIT_COUNT,
    payload: {
      articleId,
      unitCount,
    },
  };
}

export function setWithWeld(articleId: number, withWeld: boolean): ProcessAction {
  return {
    type: ProcessActions.SET_WITH_WELD,
    payload: {
      articleId,
      withWeld,
    },
  };
}

export function setHint(articleId: number, hint?: string): ProcessAction {
  return {
    type: ProcessActions.SET_HINT,
    payload: {
      articleId,
      hint,
    },
  };
}

export function setNote(articleId: number, note?: string): ProcessAction {
  return {
    type: ProcessActions.SET_NOTE,
    payload: {
      articleId,
      note,
    },
  };
}

export function setRecipients(recipients: NewProcessRecipient[]): ProcessAction {
  return {
    type: ProcessActions.SET_RECIPIENTS,
    payload: {
      recipients,
    },
  };
}

export function setMessage(message: string): ProcessAction {
  return {
    type: ProcessActions.SET_MESSAGE,
    payload: message,
  };
}

export function setName(name: string): ProcessAction {
  return {
    type: ProcessActions.SET_NAME,
    payload: name,
  };
}

export function createProcess(process: NewProcess, skipRecipients = false): ProcessAction {
  return {
    type: ProcessActions.CREATE_PROCESS,
    payload: {
      request: {
        url: '/processes',
        method: 'POST',
        data: {
          ...omit(process, ['customer', 'recipients']),
          recipients: skipRecipients ? [] : process.recipients,
          articles: map(process.articles, a => ({
            ...omit(a, 'article'),
            articleNumber: a.article.itemNumber,
          })),
          newCustomer: process.customer?.customerNumber
            ? null
            : process.customer,
          customerNumber: process.customer?.customerNumber,
        },
      },
    },
  }
}

export function fetchInitial(params?: ProcessQueryParams): ProcessAction {
  return {
    type: ProcessActions.FETCH_PROCESSES,
    payload: {
      request: {
        url: '/processes',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMore(params?: ProcessQueryParams): ProcessAction {
  return {
    type: ProcessActions.FETCH_MORE_PROCESSES,
    payload: {
      request: {
        url: '/processes',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchFilter() {
  return {
    type: ProcessActions.FETCH_FILTER,
    payload: {
      request: {
        url: '/processes/filter',
        method: 'GET',
      },
    },
  }
}

export function editProcess(process: Process) {
  return {
    type: ProcessActions.EDIT_PROCESS,
    payload: process,
  }
}

export function updateProcess(id: number, process: NewProcess, skipRecipients = false) {
  return {
    type: ProcessActions.UPDATE_PROCESS,
    payload: {
      request: {
        url: `/processes/${id}`,
        method: 'PUT',
        data: {
          ...omit(process, ['customer', 'recipients']),
          recipients: skipRecipients ? [] : process.recipients,
          articles: map(process.articles, a => ({
            ...omit(a, 'article'),
            articleNumber: a.article.itemNumber,
          })),
          customerNumber: process.customer?.customerNumber,
        },
      },
    },
  }
}

export function patchProcess(id: number, data: any) {
  if (data.customerNumber) {
    data.existingCustomer = {
      customerNumber: data.customerNumber,
    };

    delete data.customerNumber;
  }
  return {
    type: ProcessActions.PATCH_PROCESS,
    payload: {
      request: {
        url: `/processes/${id}`,
        method: 'PATCH',
        data,
      },
    },
  }
}

export function clearNewProcess() {
  return {
    type: ProcessActions.CLEAR_NEW_PROCESS,
  }
}