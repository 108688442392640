import { Article, ArticleAction, ArticleActions, ArticleQueryParams } from '../model/article';

export function fetchInitial(params?: ArticleQueryParams): ArticleAction {
  return {
    type: ArticleActions.FETCH_ARTICLES,
    payload: {
      request: {
        url: '/articles',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMore(params?: ArticleQueryParams): ArticleAction {
  return {
    type: ArticleActions.FETCH_MORE_ARTICLES,
    payload: {
      request: {
        url: '/articles',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchFilter() {
  return {
    type: ArticleActions.FETCH_FILTER,
    payload: {
      request: {
        url: '/articles/filter',
        method: 'GET',
      },
    },
  }
}

export function selectArticles(payload: Article[]) {
  return {
    type: ArticleActions.SELECT_ARTICLES,
    payload,
  };
}